import React, { useEffect } from 'react';
import {
  useLocation,
} from 'react-router-dom';
import { capitalize } from 'lodash';

import PageWrapper from '../../../components/PageWrapper';
import UsersVacationsRequestCard from './UsersVacationsRequestCard';
import UsersAgreedVacationsRequestCard from './UsersAgreedVacationsRequestCard';
import useActorsAPI from '../../../api/useActorsApi';
import VacationInfo from './VacationInfo';
import FormCreateUserVacation from './FormCreateUserVacation';

function UserVacationsPage() {
  const { getMyInfoAndListOfActors } = useActorsAPI();

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const vacation = queryParameters.get('v');
  const vacationRequest = queryParameters.get('vreq');

  useEffect(() => {
    getMyInfoAndListOfActors({
      not_banned: false,
      actor_type: ['classic_user', 'user'],
    });
  }, []);

  return (
    <>
      <PageWrapper title={capitalize('отпуска')}>
        <UsersVacationsRequestCard />
        <UsersAgreedVacationsRequestCard />
        <FormCreateUserVacation />
      </PageWrapper>
      {vacation || vacationRequest
        ? (
          <VacationInfo
            vacationUUID={vacation}
            vacationRequestUUID={vacationRequest}
          />
        )
        : null}
    </>
  );
}

export default UserVacationsPage;
