// THIS IS GLOBAL REACT FILE FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.09.02 || YYYY/MM/DD

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

// Set Monday as 1 day of week
dayjs.updateLocale('en', {
  weekStart: 1,
});

export const FORMAT1 = 'DD-MM-YYYY';
export const FORMAT2 = 'YYYY-MM-DD';
export const FORMAT3 = 'DD';
export const FORMAT4 = 'MMMM';
export const FORMAT5 = 'YYYY';

export const FORMAT10 = 'DD-MM-YYYY | HH:mm';
export const FORMAT11 = 'MMM D YYYY | HH:mm';
export const FORMAT12 = 'MMMM Do YYYY, hh:mm';
export const FORMAT13 = 'DD-MM-YYYY HH:mm:ss';

export const FORMAT21 = 'DD-MMMM';
export const FORMAT22 = 'MMMM-DD';
export const FORMAT23 = 'YYYY-MM';

export const defaultDate = () => dayjs();
export const transformDate = (param) => dayjs(param);
export const transformAndFormatDate = (param, format) => dayjs(param).format(format);
export const formatDate = (format) => defaultDate().format(format);
export const startOfWeek = (param) => transformDate(param).startOf('week');
export const endOfWeek = (param) => transformDate(param).endOf('week');

export const dateFormat1 = () => formatDate(FORMAT1);
export const dateFormat2 = () => formatDate(FORMAT2);
export const dateFormat3 = () => formatDate(FORMAT3);
export const dateFormat4 = () => formatDate(FORMAT4);
export const dateFormat12 = () => formatDate(FORMAT12);
export const dateFormat22 = () => formatDate(FORMAT22);
export const dateFormat23 = () => formatDate(FORMAT23);

export const dateToFormat1 = (param) => transformAndFormatDate(param, FORMAT1);
export const dateToFormat2 = (param) => transformAndFormatDate(param, FORMAT2);
export const dateToFormat3 = (param) => transformAndFormatDate(param, FORMAT3);
export const dateToFormat4 = (param) => transformAndFormatDate(param, FORMAT4);

export const dateToFormat10 = (param) => transformAndFormatDate(param, FORMAT10);
export const dateToFormat11 = (param) => transformAndFormatDate(param, FORMAT11);
export const dateToFormat13 = (param) => transformAndFormatDate(param, FORMAT13);
export const dateToFormat21 = (param) => transformAndFormatDate(param, FORMAT21);

export const dateAdd = (a, b, dateParam) => transformDate(dateParam).add(a, b);
export const dateSubtract = (a, b, dateParam) => transformDate(dateParam).subtract(a, b);
export const dateDiff = (a, b, dateParam) => transformDate(dateParam).diff(a, b);

export const dateGetStartOf = (param) => defaultDate().startOf(param);
export const dateGetStartOfFromNow = (param) => dateGetStartOf(param).fromNow();
export const dateStartOfAndFormat = (param, formatParam) => dateGetStartOf(param).format(formatParam);
export const dateGetEndOfAndFormat = (param, formatParam) => defaultDate().endOf(param).format(formatParam);

export const dateToISO = (param) => transformDate(param).toISOString();
export const dateToISOAndFormat = (param, formatParam) => transformDate(transformDate(param).toISOString()).format(formatParam);
