import React, { useCallback, useEffect, useState } from 'react';
import {
  array,
  bool, func, string,
} from 'prop-types';
import { Input } from 'antd';
import { isNil } from 'ramda';
import Icon from '@mdi/react';
import {
  mdiChevronDown, mdiChevronUp,
} from '@mdi/js';
import { isEmpty } from 'lodash';
import cn from 'classnames';

import AddTimeButtons from './AddTimeButtons';

import {
  sumAllTimeWorked,
  takeTimeAwayFrom,
  timeConvertForMoment,
  timeConvertForView,
  validTime,
} from './utils';

function InputTimeWork({
  inPanel,
  useCircleTime,
  disableControlls,
  disableAddTimeBtnClick,
  isValidFromOutside = true,
  enableAddBtn,
  disableEdit,
  saveNewTimeCallback,
  addTimeClass,
  defaultValueInput,
  customUpdate,
  currentValueInput,
  className,
  iconClass,
  bordered,
  small,
  arrTime = [],
}) {
  const [selectedKey, saveSelectedKeyInput] = useState('');
  const [valueInput, setValueInput] = useState('');
  const [notValidTime, setValidTime] = useState(false);

  const stopPropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const validationTime = (value) => {
    //    /d - это цифра

    if (/^\d$/.test(value) && +value > 2) {
      // если ровно 1 цифрe и value > 2 то 0h0m
      return `0${value}h`;
    }

    if (/^\d\d$/.test(value)) {
      // если ровно двум цифрам то 00h

      return `${value}h`;
    }

    if (/^\d\dh\d\d$/.test(value)) {
      // если ровно 00h00 то 00h00m
      return `${value}m`;
    }

    if (value.length <= 6 && /\d/.test(selectedKey)) {
      // если длина меньше 6 и текущая нажатая клафиша евляется цифрой
      return value;
    }

    if (
      value.length <= 6
      && isNil(valueInput.match(/h/i))
      && (
        /h/i.test(selectedKey)
      || /[\.\-\,\:]/.test(selectedKey)
      )
    ) {
      // если длина меньше 6, и в прошлом value нет 'h', и текущая нажатая клавиша ровно 'h || . || - || , || :'
      return `${value.slice(0, value.length - 1)}h`;
    }

    if (
      value.length <= 6
       && isNil(valueInput.match(/m/i))
      && (
        /m/i.test(selectedKey)
      || /[\.\-\,\:]/.test(selectedKey)
      )
    ) {
      // если длина меньше 6, и в прошлом value нет 'm', и текущая нажатая клавиша ровно 'm || . || - || , || :'
      return `${value.slice(0, value.length - 1)}m`;
    }

    return valueInput;
  };

  const checkValidAndSaveTime = (newTime) => {
    if (!validTime(newTime)) {
      const isValid = false;
      saveNewTimeCallback({ newTime, isValid });
      setValidTime(true);
    } else {
      const isValid = true;
      saveNewTimeCallback({ newTime, isValid });
      setValidTime(false);
    }
  };

  const saveNewTime = (e) => {
    const { value } = e.target;
    let newTime = 0;
    if (selectedKey !== 'Backspace') {
      newTime = validationTime(value);
    } else {
      newTime = value;
    }
    saveSelectedKeyInput('');
    setValueInput(newTime);

    checkValidAndSaveTime(newTime);
  };

  const updateTimeFromArrow = (type) => {
    const currentValue = valueInput || '0';

    if (validTime(currentValue) && !disableEdit) {
      const sum = type === 'down'
        ? takeTimeAwayFrom(timeConvertForMoment(currentValue), '00:01')
        : sumAllTimeWorked(timeConvertForMoment(currentValue), '00:01');
      const newTime = timeConvertForView(sum);
      setValueInput(newTime);
      checkValidAndSaveTime(newTime);
    }
  };

  const saveTimeCallback = (newTime) => {
    setValueInput(newTime);
    checkValidAndSaveTime(newTime);
  };

  const clickInputArrowDown = () => updateTimeFromArrow('down');
  const clickInputArrowUp = () => updateTimeFromArrow('up');

  const onKeyDownInput = (e) => {
    if (e.code === 'ArrowUp') {
      clickInputArrowUp();
    }
    if (e.code === 'ArrowDown') {
      clickInputArrowDown();
    }
    saveSelectedKeyInput(e.key);
  };

  const onClickWrapper = useCallback((e) => {
    if (!disableEdit) {
      stopPropagation(e);
    }
  }, [disableEdit]);

  const initFunc = (time) => {
    setValueInput(time);

    if (!validTime(time) && !disableEdit) {
      setValidTime(true);
    } else {
      setValidTime(false);
    }
  };

  useEffect(() => {
    initFunc(defaultValueInput);
  }, [defaultValueInput]);

  useEffect(() => {
    if (customUpdate && valueInput !== currentValueInput) {
      initFunc(currentValueInput);
    }
  }, [customUpdate]);

  return (
    <div
      onClick={onClickWrapper}
      className={cn('dailyTextarea_window_input', { 'w-100': inPanel })}
    >
      <div className={cn({ dailyWorkTimeInput: disableControlls, custom_input_number: !disableControlls, 'mr-auto': inPanel })}>
        <Input
          value={valueInput}
          disabled={disableEdit}
          className={`custom_input_number__input ${notValidTime || !isValidFromOutside ? 'inputTimeWorkNotValid' : ''} ${className}`}
          onChange={saveNewTime}
          onKeyDown={onKeyDownInput}
          bordered={bordered}
        />
        {!disableControlls && (
          <div
            aria-hidden
            className="custom_input_number__arrow_wrapper"
            onClick={stopPropagation}
          >
            <Icon className={`${iconClass}`} path={mdiChevronUp} size={0.6} onClick={clickInputArrowUp} />
            <Icon className={`${iconClass}`} path={mdiChevronDown} size={0.6} onClick={clickInputArrowDown} />
          </div>
        )}
      </div>

      {!isEmpty(arrTime)
      && (
      <AddTimeButtons
        useCircleTime={useCircleTime}
        times={arrTime}
        enableAddBtn
        small={small}
        disabled={!enableAddBtn && disableEdit}
        disableAddTimeBtnClick={disableAddTimeBtnClick}
        customClass={addTimeClass}
        currentTime={valueInput}
        saveTimeCallback={saveTimeCallback}
      />
      )}

    </div>
  );
}
InputTimeWork.propTypes = {
  inPanel: bool,
  useCircleTime: bool,
  disableControlls: bool,
  isValidFromOutside: bool,
  enableAddBtn: bool,
  bordered: bool,
  iconClass: string,
  addTimeClass: string,
  disableEdit: bool,
  saveNewTimeCallback: func,
  defaultValueInput: string,
  customUpdate: bool,
  currentValueInput: string,
  className: string,
  small: bool,
  arrTime: array,
};
export default React.memo(InputTimeWork);
