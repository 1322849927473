import MasqueradingConstants from './constants';

const initialState = {
  vacationStorage: null,
  monthlyReportsStorage: null,
  isFetching: false,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case MasqueradingConstants.GET_MASQUERADING_VACATION_STORAGE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case MasqueradingConstants.GET_MASQUERADING_VACATION_STORAGE_SUCCESS: {
      return {
        ...state,
        vacationStorage: payload,
        isFetching: false,
      };
    }
    case MasqueradingConstants.GET_MASQUERADING_VACATION_STORAGE_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case MasqueradingConstants.GET_MASQUERADING_MONTHLY_STORAGE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case MasqueradingConstants.GET_MASQUERADING_MONTHLY_STORAGE_SUCCESS: {
      return {
        ...state,
        monthlyReportsStorage: payload,
        isFetching: false,
      };
    }
    case MasqueradingConstants.GET_MASQUERADING_MONTHLY_STORAGE_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};
