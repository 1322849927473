import React, { memo, useEffect, useState } from 'react';
import {
  Card, Col, Row, Spin,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './day-user-report.scss';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import useAPI from '../../../api/useAPI';
import DayUserReportUsersTable from './dayComponent/DayUserReportUsersTable';
import { getFetchingReports54Actors, getReports54Actors } from '../../../redux/reports54Actors/selectors';
import PageWrapper from '../../../components/PageWrapper';
import DayUserReportDaySwitcher from './dayComponent/DayUserReportDaySwitcher';
import DayUserReportUserTableHeader from './dayComponent/DayUserReportUserTableHeader';
import { getBanGroupUUID, getFetchingGroups } from '../../../redux/groups/selectors';

function UserDashboardForAdmin() {
  const {
    getDeliveryUsers,
    getListOfMonthlyReports,
  } = useAPI();

  const rootPartitionUuid = useSelector(getDeliveryPartition);
  const actors = useSelector(getReports54Actors);
  const banGroupUUID = useSelector(getBanGroupUUID);
  const isFetchingGroups = useSelector(getFetchingGroups);

  const isFetchingActors = useSelector(getFetchingReports54Actors);

  const [dataTable, setDataTable] = useState([]);
  const [isFetchingReports, setFetchingReports] = useState(true);
  const [currentDay, setCurrentDay] = useState(dayjs().format('YYYY-MM-DD'));
  const [config, setConfig] = useState({
    limit: 100,
    offset: 0,
    uinfo: {
      groups__not: [banGroupUUID],
      internal_user: true,
    },
    order: 'desc',
    order_by: 'created',
  });

  const getActorsList = async () => {
    await getDeliveryUsers(config);
  };

  const getMonthlyReports = async () => {
    setFetchingReports(true);
    const actorsUUID = actors?.map((el) => el?.actor);
    let reports = [];
    if (actorsUUID.length) {
      reports = await getListOfMonthlyReports(
        rootPartitionUuid,
        {
          actor: actorsUUID,
          params: {
            // actorUuid: actorsUUID,
            date: dayjs(currentDay).format('YYYY-MM'),
          },
          params_fields: {
            actorUuid: 'actorUuid',
            date: 'date',
            days: 'days',
          },
        },
      );
    }
    const actorsWithDay = actors?.map((el) => {
      const newElement = { ...el };
      const report = reports?.data?.find((item) => item?.params?.actorUuid === el?.actor) || [];

      newElement.day = report?.params?.days
        ?.find?.((item) => item?.rangeDates?.[0] === currentDay) || null;
      return newElement;
    });

    setDataTable(actorsWithDay);
    setFetchingReports(false);
  };

  useEffect(() => {
    if (rootPartitionUuid && !isFetchingGroups) {
      getActorsList();
    }
  }, [rootPartitionUuid, config, isFetchingGroups]);

  useEffect(() => {
    if (rootPartitionUuid && !isFetchingActors) {
      getMonthlyReports();
    }
  }, [rootPartitionUuid, currentDay, isFetchingActors]);

  return (
    <PageWrapper title={capitalize('Дневная отчетность')}>
      <Spin spinning={isFetchingReports || isFetchingActors}>
        <Card
          className="primaryCard p-12"
          style={{
            overflow: 'hidden',
            marginLeft: 0,
            marginRight: 15,
          }}
        >
          <DayUserReportDaySwitcher
            currentDay={currentDay}
            setCurrentDay={setCurrentDay}
          />
          <DayUserReportUserTableHeader
            setConfig={setConfig}
          />
          <DayUserReportUsersTable
            defaultDataTable={dataTable}
            setConfig={setConfig}
            config={config}
          />
        </Card>
      </Spin>
    </PageWrapper>
  );
}

export default memo(UserDashboardForAdmin);
