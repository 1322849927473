import { addItem, deleteItem, updateItem } from '../../utils/modifier';
import UserHolidaysConstants from "./constants";

const initState = {
  isFetching: false,
  total: 0,
  list: [],
  prev: [],
  current: [],
  next: [],
  single: null,
  error: false,
  errorMessage: '',
  isLoaded: false,
};

export default (state = initState, action = {}) => {
  const { type, payload, total } = action;

  switch (type) {
    // GET USER VACATIONS
    case UserHolidaysConstants.GET_USER_HOLIDAYS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserHolidaysConstants.GET_USER_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: payload,
        total: total || 0,
        error: false,
        errorMessage: '',
      };
    }

    case UserHolidaysConstants.GET_USER_HOLIDAYS_FAILURE:
      return {
        ...state,
        isFetching: false,
        total: 0,
        error: true,
        errorMessage: payload.error_message,
      };

      // CREATE USER VACATION
    case UserHolidaysConstants.CREATE_USER_HOLIDAYS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserHolidaysConstants.CREATE_USER_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserHolidaysConstants.CREATE_USER_HOLIDAYS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

      // UPDATE USER VACATION
    case UserHolidaysConstants.UPDATE_USER_HOLIDAYS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserHolidaysConstants.UPDATE_USER_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserHolidaysConstants.UPDATE_USER_HOLIDAYS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

      // DELETE USER VACATION
    case UserHolidaysConstants.DELETE_USER_HOLIDAYS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserHolidaysConstants.DELETE_USER_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserHolidaysConstants.DELETE_USER_HOLIDAYS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

    default:
      return state;
  }
};
