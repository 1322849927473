import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Card,
  Flex, Input, Modal, Segmented, Table,
  message, Upload,
  Divider,
  Tag,
  Image,
} from 'antd';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiPencil } from '@mdi/js';
import dayjs from 'dayjs';
import {
  capitalize, concat, get,
} from 'lodash';
import cn from 'classnames';
import { func, string } from 'prop-types';
import { getSingleDepartment } from '../../../redux/departments/actions/departmentsActions';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import PageWrapper from '../../../components/PageWrapper';
import { getAllArtifacts, getSingleArtifact } from '../../../redux/departments/actions/artifactsActions';
import { getArtifacts } from '../../../redux/departments/selectors/artifactsSelector';

import useAPI from '../../../api/useAPI';
import { entityRead } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import AddArtifactButton from './artifacts/AddArtifactButton';
import ArtifactCard from './artifacts/ArtifactCard';
import DepartmentsList from './DepartmentsList';
import DepartmentCard from './DepartmentCard';

export default function DepartmentRoot({
  departments,
  reloadDepartments,
  storageUUID,
}) {
  const dispatch = useDispatch();

  const {
    getDeliveryUsers,
    getListOfMonthlyReports,
  } = useAPI();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeDepartment = query.get('department');
  const activeArtifact = query.get('artifact');
  const history = useHistory();

  const rootPartitionUuid = useSelector(getDeliveryPartition);
  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);
  const artifacts = useSelector(getArtifacts);

  const [actors, setActors] = useState([]);

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeArtifact,
  });

  const getActorsR54 = async () => {
    if (!department?.params?.users?.length) {
      setActors([]);
      return;
    }
    const res = await dispatch(entityRead({
      data: {
        parent: rootPartitionUuid,
        entity_uuids: department?.params?.users?.map((el) => el.value),
        actor_type: ['user', 'classic_user'],
      },
      options: {
        partition: SERVICE_PARTITION_NAME,
      },
      constants: [
        'FETCH_ACTORS_R54_REQUEST',
        'FETCH_ACTORS_R54_SUCCESS',
        'FETCH_ACTORS_R54_FAILURE',
      ],
    }));

    if (res) {
      setActors(res.reduce((acc, current) => {
        const x = acc.find((item) => item.actor === current.actor);
        if (!x) {
          acc.push(current);
        }
        return acc;
      }, []));
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const columns = [
    {
      key: 'type',
      // title: 'Тип',
      dataIndex: ['entity_type'],
      width: '10%',
      render: (cell, row, index) => {
        switch (cell) {
          case 'file': {
            return <Flex className="text-green-500">Файл</Flex>;
          }
          case 'instruction': {
            return <Flex className="text-blue-500">Инструкция</Flex>;
          }
          case 'schedule': {
            return <Flex className="text-blue-500">Расписание</Flex>;
          }
          case 'user': {
            if (department?.params?.leader === row?.actor) {
              return <Flex className="text-orange-500">Руководитель</Flex>;
            }
            if (department?.params?.deputy === row?.actor) {
              return <Flex className="text-orange-500">Заместитель</Flex>;
            }
            return <Flex className="text-orange-500">Сотрудник</Flex>;
          }
          default: return '';
        }
      },
    },
    {
      key: 'title',
      title: <Flex className="ml-0">Название</Flex>,
      dataIndex: ['params', 'title'],
      width: '40%',
      render: (cell, row, index) => (row?.entity_type === 'user'
        ? (
          <Flex>
            <Flex className="ml-0">
              {row?.uinfo?.first_name}
              {' '}
              {row?.uinfo?.last_name}
            </Flex>
          </Flex>
        ) : (
          <Flex>
            <Flex className="ml-0">{cell}</Flex>
          </Flex>
        )),
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: ['params', 'status'],
      width: '20%',
      render: (cell, row, index) => {
        switch (cell) {
          case 'active': {
            return <Tag color="green">Активно</Tag>;
          }
          case 'archive': {
            return (
              <Flex
                align="center"
                justify="center"
                style={{
                  border: '1px solid #A9A9A9',
                }}
                className="w-max border-gray-500 bg-gray-50 px-2 rounded text-sm text-gray-500"
              >
                Архив
              </Flex>
            );
          }
          default:
            return <Tag color="green">Активно</Tag>;
        }
      },
    }, {
      key: 'creater',
      title: 'Создал',
      dataIndex: ['uinfo'],
      width: '10%',
      render: (cell, row, index) => (
        <Flex
          align="center"
          justify="center"
          style={{
            border: '1px solid #C0C0C0',
            width: 27,
            height: 27,
          }}
          className="rounded-full text-gray-500"
        >
          {capitalize(
            cell?.first_name?.[0],
          )}
          {capitalize(cell?.last_name?.[0])}
        </Flex>
      ),
    }, {
      key: 'created',
      title: 'Дата',
      dataIndex: ['created'],
      width: '20%',
      render: (cell, row, index) => dayjs(cell).format('YYYY-MM-DD'),
    }];

  const onRow = (record, rowIndex) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      query.set('artifact', uuid);

      history.push({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
  });

  const allArtifacts = useMemo(
    () => shuffleArray(concat(artifacts, actors)),
    [JSON.stringify(artifacts), JSON.stringify(actors)],
  );

  useEffect(() => {
    if (activeDepartment) {
      dispatch(getSingleDepartment({
        entity_uuid: activeDepartment,
      }));
      dispatch(getAllArtifacts(activeDepartment));
    }
  }, [activeDepartment]);

  useEffect(() => {
    if (activeArtifact) {
      dispatch(getSingleArtifact({
        entity_uuid: activeArtifact,
      }));
    }
  }, [activeArtifact]);

  useEffect(() => {
    if (department) {
      getActorsR54();
    }
  }, [JSON.stringify(department)]);

  const onBackCallback = () => {
    query.delete('artifact');

    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const onCreateArtifactCallback = (uuid) => {
    query.set('artifact', uuid);

    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
    dispatch(getAllArtifacts(departmentUUID));
  };

  const onDeleteArtifactCallback = () => {
    query.delete('artifact');

    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
    dispatch(getAllArtifacts(departmentUUID));
  };

  const onUpdateArtifactCallback = () => {
    dispatch(getAllArtifacts(departmentUUID));
  };

  const onOpenDepartment = () => {
    query.delete('artifact');

    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };
  const onBackDepartmentCallback = () => {
    query.delete('department');

    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Отделы
        </Flex>
      )}
    >
      <Flex
        className="w-full"
      >
        <Flex
          vertical
          className="w-1/2"
        >
          <DepartmentsList
            department={department}
            departments={departments}
            onOpenDepartment={onOpenDepartment}
            storageUUID={storageUUID}
            reloadDepartments={reloadDepartments}
          />
          {activeDepartment ? (
            <Card
              className="w-full mt-4 p-0 h-max blue-base-card"
              title={(
                <Flex
                  align="center"
                >
                  <Flex
                    className="text-xl"
                  >
                    Артефакты
                  </Flex>
                  <AddArtifactButton
                    departmentUUID={departmentUUID}
                    onCreateArtifactCallback={onCreateArtifactCallback}
                  />
                </Flex>
        )}
            >
              <Table
                size="small"
                className="w-full h-full mb-3"
                rowClassName={rowClassName}
                onRow={onRow}
                pagination={false}
                dataSource={allArtifacts}
                columns={columns}
              />
            </Card>
          ) : null}
        </Flex>
        {activeArtifact && (
          <ArtifactCard
            onUpdateArtifactCallback={onUpdateArtifactCallback}
            onDeleteArtifactCallback={onDeleteArtifactCallback}
            onBackCallback={onBackCallback}
          />
        )}
        {(!activeArtifact && activeDepartment) && (
          <DepartmentCard
            department={department}
            onBackDepartmentCallback={onBackDepartmentCallback}
          />
        )}
      </Flex>
    </PageWrapper>
  );
}

DepartmentRoot.propTypes = {
  reloadDepartments: func,
  storageUUID: string,
};
