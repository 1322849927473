import React, { useState } from 'react';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Divider, Flex, Segmented } from 'antd';
import { object } from 'prop-types';
import EditButton from '../common/EditButton';

export default function InstructionArtifactView({
  artifact,
}) {
  const [isEditTitle, setEditTitle] = useState(false);

  const [title, setTitle] = useState('');

  return (
    <Flex
      vertical
    >
      <Flex
        align="center"
      >
        <span
          className="mr-2 text-base"
        >
          Название:
        </span>
        {/* <EditButton
          onClick={() => setEditTitle(true)}
          id="editTitleButton"
          isEditFlag={isEditTitle}
        /> */}
        <Flex
          style={{
            width: 22,
            height: 22,
          }}
          // onClick={() => setEditTitle(true)}
          align="center"
          justify="center"
          size="small"
          className="mr-3 cursor-pointer rounded-full text-blue-500 hover:!bg-blue-500 hover:!text-white"
        >
          <Icon path={mdiPencil} size={0.8} />
        </Flex>
        <span
          className="text-blue-500"
        >
          {artifact?.params?.title}
        </span>
      </Flex>
      <Divider className="my-2" />
      <Flex className="mt-0" justify="space-between" align="center">
        <Flex>
          <span
            className="mt-0.5 mr-2"
          >
            Статус:
          </span>
          <Flex>
            {artifact?.params?.status === 'active' && (
            <Flex
              style={{
                border: '1px solid #b7eb8f',
              }}
              className="px-2 py-0.5 h-max text-base rounded-md text-green-500 border-green-500 bg-green-50"
            >
              Активно
            </Flex>
            )}
            {artifact?.params?.status === 'archive' && (
            <Flex
              style={{
                border: '1px solid #A9A9A9',
              }}
              className="px-2 py-0.5 h-max text-base rounded-md text-gray-500 border-gray-500 bg-gray-100"
            >
              Архив
            </Flex>
            )}
          </Flex>
        </Flex>
        {artifact?.entity_type !== 'user' && (
        <Flex align="center">
          <span className="mr-2">
            Доступ:
          </span>
          <Segmented
            options={[{
              value: 'management',
              label: 'Руководство',
            }, {
              value: 'All employees',
              label: 'Все сотрудники',
            }]}
            value="All employees"
          />

        </Flex>
        )}
      </Flex>

      <Divider className="my-2" />
      <Flex
        vertical
      >
        <Flex>
          <span
            className="mr-2 text-base"
          >
            Инструкция
          </span>
          <Flex
            style={{
              width: 22,
              height: 22,
            }}
            align="center"
            justify="center"
            size="small"
            className="mr-3 cursor-pointer rounded-full text-blue-500 hover:!bg-blue-500 hover:!text-white"
          >
            <Icon path={mdiPencil} size={0.8} />
          </Flex>
        </Flex>
        <Flex
          style={{ whiteSpace: 'pre-wrap' }}
          className="text-gray-500"
        >
          {artifact?.params?.description}
        </Flex>
      </Flex>
    </Flex>
  );
}

InstructionArtifactView.propTypes = {
  artifact: object,
};
