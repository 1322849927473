import React from 'react';
import {
  Button, Divider, Space,
} from 'antd';
import { capitalize } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import { bool, func } from 'prop-types';

import './appHeader.scss';

import AuthHelper from '../../utils/authUtils';
import ProfileMenu from './header/ProfileMenu';
import InterfacesListModal54Origins from '../publicInterface54Origins/InterfacesListModal54Origins';
import CheckStartWorkRecordFocus from './startWorkRecord/CheckStartWorkRecordFocus'

const headerStyle = {
  backgroundColor: '#ffffff',
  transition: 'all 0.2s ease',
  padding: '0',
  zIndex: 999,
  width: '100%',
  height: '50px',
  lineHeight: '50px',
};

const dividerStyle = { height: headerStyle.height };

export default function AppHeader({ isSidebarCollapsed, setSidebarCollapsed }) {
  const history = useHistory();
  const location = useLocation();


  return AuthHelper.isAuthorised() ? (
    <Header
      style={{
        ...headerStyle,
      }}
    >
      <Space
        style={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Space>
          <Button
            type="text"
            icon={isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setSidebarCollapsed(!isSidebarCollapsed)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '16px',
              width: 54,
              height: 54,
            }}
          />
          <Button
            icon={<QuestionOutlined />}
            type={location.pathname === '/help' ? 'primary' : 'default'}
            className="help-button"
            onClick={() => history.push('/help')}
          >
            {capitalize('help')}
          </Button>
        </Space>

        <Space
          align="top"
        >
          <CheckStartWorkRecordFocus />
          <InterfacesListModal54Origins />
          <Divider type="vertical" style={dividerStyle} />
          <ProfileMenu />
          <Divider type="vertical" style={dividerStyle} />
        </Space>
      </Space>
    </Header>
  ) : null;
}

AppHeader.propTypes = {
  isSidebarCollapsed: bool,
  setSidebarCollapsed: func,
};
