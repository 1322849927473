/* eslint-disable import/prefer-default-export */
import { entityCreate, entityRead, getListAndReadEntities } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import {
  GET_DEPARTMENTS_FAILURE,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  GET_DEPARTMENT_FAILURE,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_REQUEST,
} from '../slices/departmentsSlice';

export const getAllDepartments = (options, parent, constants) => (dispatch) => dispatch(
  getListAndReadEntities({
    data: {
      parent,
      entity_type: 'department',
      ...options,
    },
    constants: constants || [
      GET_DEPARTMENTS_REQUEST,
      GET_DEPARTMENTS_SUCCESS,
      GET_DEPARTMENTS_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const getSingleDepartment = (options, constants) => (dispatch) => dispatch(
  entityRead({
    data: {
      entity_type: 'department',
      ...options,
    },
    constants: constants || [
      GET_DEPARTMENT_REQUEST,
      GET_DEPARTMENT_SUCCESS,
      GET_DEPARTMENT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const createDepartment = (parentUUID, data) => (dispatch) => dispatch(
  entityCreate({
    data: {
      entity_type: 'department',
      parent: parentUUID,
      ...data,
    },
    constants: [
      CREATE_DEPARTMENT_REQUEST,
      CREATE_DEPARTMENT_SUCCESS,
      CREATE_DEPARTMENT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);
