import React from 'react';
import { Divider, Flex } from 'antd';
import { object } from 'prop-types';

export default function UserArtifactView({
  artifact,
}) {
  return (
    <Flex
      vertical
      className="w-full h-full"
    >
      <Flex
        align="center"
      >
        <span
          className="mr-2 text-base"
        >
          Имя:
        </span>
        <span
          className="text-blue-500"
        >
          {artifact?.uinfo?.first_name}
          {' '}
          {artifact?.uinfo?.last_name}
        </span>
      </Flex>
      <Divider className="my-2" />
      <Flex className="mt-0" justify="space-between" align="center">
        <Flex>
          <span
            className="mt-0.5 mr-2"
          >
            Статус:
          </span>
          {artifact?.params?.status === 'active' && (
            <Flex
              style={{
                border: '1px solid #b7eb8f',
              }}
              className="px-2 py-0.5 h-max text-base rounded-md text-green-500 border-green-500 bg-green-50"
            >
              Активно
            </Flex>
          )}
          {artifact?.params?.status === 'archive' && (
            <Flex
              style={{
                border: '1px solid #A9A9A9',
              }}
              className="px-2 py-0.5 h-max text-base rounded-md text-gray-500 border-gray-500 bg-gray-100"
            >
              Архив
            </Flex>
          )}
          {artifact?.entity_type === 'user' && (
            <Flex
              style={{
                border: '1px solid #b7eb8f',
              }}
              className="px-2 py-0.5 h-max text-base rounded-md text-green-500 border-green-500 bg-green-50"
            >
              Активно
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex
        vertical
      >
        <span
          className="mr-2 text-base"
        >
          Месячный отчет:
        </span>

      </Flex>
    </Flex>
  );
}

UserArtifactView.propTypes = {
  artifact: object,
};
