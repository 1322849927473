import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Space,
  Tooltip,
} from 'antd';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import { SendOutlined } from '@ant-design/icons';
import { capitalize, get, uniq } from 'lodash';
import dayjs from 'dayjs';

import {
  bool, func, object, string,
} from 'prop-types';
import ru from 'date-fns/locale/ru';
import { mdiCircleSmall } from '@mdi/js';
import Icon from '@mdi/react';
import antNotification from '../../../../utils/antNotification';

import { getProfileInfo } from '../../../../redux/profile/selectors';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';
import { diffDays } from '../../../../utils/dateTimeUtils';
import { getVacationStorageUUID } from '../../../../redux/storages/selectors';
import useAPI from '../../../../api/useAPI';
import InfoCreateVacation from './InfoCreateVacation';
import InfoCommentVacation from './InfoCommentVacation';
import { maxCountOfVacationDays } from '../utils';
import { getDeliveryPartition } from '../../../../redux/config/selectors';

const { TextArea } = Input;

export default function AddVacationForm({
  currentActor,
  initValues,
  form,
  isEditMode,
  uuid,
  isAdminMode,
  onCancelModal,
  saveCallback,
}) {
  const {
    requestCreateUserVacation,
    requestUpdateUserVacation,
    getAgreedVacations,
    getListOfMonthlyReports,
  } = useAPI();

  const isEditDisabled = initValues.status !== 'created' && !isAdminMode;

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const myProfileData = useSelector(getProfileInfo);
  const deliveryPartition = useSelector(getDeliveryPartition);

  const actor = currentActor || myProfileData.uuid;

  const [startDate, setStartDate] = useState(
    initValues.start_date ? new Date(initValues.start_date) : null,
  );
  const [endDate, setEndDate] = useState(
    initValues.start_date ? new Date(initValues.end_date) : null,
  );
  const [isDisabled, setIsDisabled] = useState(false);

  const [vacations, setVacations] = useState({
    thisYear: 0,
    lastTwelveMonths: 0,
  });
  const [sickdays, setSickdays] = useState({
    thisYear: 0,
    lastTwelveMonths: 0,
  });
  const [dayOffs, setDayOffs] = useState({
    thisYear: 0,
    lastTwelveMonths: 0,
  });

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    if (diffDays(start, end) > maxCountOfVacationDays && !isAdminMode) {
      antNotification.error(
        `Отпуск не может быть больше ${maxCountOfVacationDays} дней!`,
      );
      return;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const status = Form.useWatch('status', { form, preserve: true });
  const comment = Form.useWatch('comment', { form, preserve: true });

  const onReset = () => {
    form.resetFields();
    onCancelModal();
  };

  const onFinish = (values) => {
    const config = {
      actor,
      params: {
        ...values,
        actorUuid: actor,
        start_date: startDate,
        end_date: endDate,
        rangeDates: enumerateDaysBetweenDates(startDate, endDate),
      },
    };

    if (!isAdminMode) {
      config.params.status = status;
    }

    if (!isEditMode) {
      requestCreateUserVacation(vacationStorageUUID, config).then(() => {
        antNotification.success(
          'Отпуск запланирован и готов к отправке на рассмотрение.',
        );
        saveCallback();
        onReset();
      });
    } else {
      requestUpdateUserVacation(uuid, config.params).then(() => {
        antNotification.success('Отпуск был успешно изменен.');
        saveCallback();
        onCancelModal();
      });
    }
  };

  const onSaveAndSendRequest = () => {
    setIsDisabled(true);
    form.setFieldValue('status', 'pending');
    form.submit();
  };

  const onSave = () => {
    setIsDisabled(true);
    form.setFieldValue('status', 'created');
    form.submit();
  };

  const getDaysBetweenDates = (reports, start, end) => reports.flatMap((r) => get(r, 'params.days', [])
    .filter((day) => {
      const date = dayjs(day?.rangeDates?.[0]);
      return day?.rangeDates?.[0] && dayjs(date).isBetween(start, end, null, '[]') && dayjs(date).day() >= 1 && dayjs(date).day() <= 5;
    }));

  const getDaysByType = (days = [], types = []) => {
    const objDays = {};
    types.forEach((type) => {
      objDays[type] = [];
    });

    days.forEach((day) => {
      if (types.includes(day.type)) {
        objDays[day.type].push(day);
      }
    });

    return objDays;
  };

  const initVacations = async (start, end, key) => {
    const configRequest = {
      actor,
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          start,
          end,
        ),
      },
    };

    const res = await getAgreedVacations(
      deliveryPartition,
      configRequest,
      [
        'GET_VACATIONS_FOR_HISTORY_REQUEST',
        'GET_VACATIONS_FOR_HISTORY_SUCCESS',
        'GET_VACATIONS_FOR_HISTORY_FAILURE',
      ],
    );

    if (res?.data) {
      setVacations((prev) => ({
        ...prev,
        [key]: uniq(res.data.flatMap((el) => el?.params?.rangeDates))?.length,
      }));
    }
  };

  const initDayOffsAndSickdays = async (start, end, key) => {
    const startOfMonth = dayjs(start).startOf('month');
    const endOfMonth = dayjs(end).endOf('month');
    let currentMonth = startOfMonth;
    const monthsInRange = [];

    while (currentMonth.isBefore(endOfMonth) || currentMonth.isSame(endOfMonth)) {
      monthsInRange.push(currentMonth.format('YYYY-MM'));
      currentMonth = currentMonth.add(1, 'month').startOf('month');
    }

    const configRequest = {
      actor,
      params: {
        date: monthsInRange,
      },
    };

    const res = await getListOfMonthlyReports(deliveryPartition, configRequest);
    const filteredDays = getDaysBetweenDates(res.data, start, end);
    const { sickday, dayoff } = getDaysByType(filteredDays, ['sickday', 'dayoff']);

    setSickdays((prev) => ({
      ...prev,
      [key]: sickday.length,
    }));
    setDayOffs((prev) => ({
      ...prev,
      [key]: dayoff.length,
    }));
  };

  useEffect(() => {
    if (actor) {
      initVacations(dayjs().startOf('year'), dayjs(), 'thisYear');
      initVacations(dayjs().subtract(1, 'year'), dayjs(), 'lastTwelveMonths');
      initDayOffsAndSickdays(dayjs().startOf('year'), dayjs(), 'thisYear');
      initDayOffsAndSickdays(dayjs().subtract(1, 'year'), dayjs(), 'lastTwelveMonths');
    }
  }, [actor]);

  return (
    <Form
      form={form}
      name="add-timeline-status-form"
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      initialValues={{
        start_date: null,
        end_date: null,
        status: 'created',
        ...(initValues || {}),
      }}
      disabled={isEditDisabled}
    >
      <Row>
        <Col span={24}>
          <InfoCreateVacation showApprovalAdmin={false} />
        </Col>

        <label
          htmlFor="add-timeline-status-form_dates"
          className="ant-form-item-required text-xs text-[#777] mt-3"
          title="Тип"
        >
          {/* TODO: Create custom label component */}
          <span style={{ color: '#ff4d4f', paddingRight: 4 }}>
            *
          </span>
          Дата начала / Дата окончания
        </label>
        <Flex className="mt-2 p-2 !w-full border border-dashed border-stone-500 rounded">
          <Flex
            className="w-4/7"
          >
            <DatePicker
              selected={startDate}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              inline
              monthsShown={3}
              selectsRange
              locale={ru}
              minDate={!isAdminMode ? new Date().setDate(new Date().getDate()
              + maxCountOfVacationDays) : undefined}
              maxDate={isEditDisabled ? new Date() : undefined}
            />
          </Flex>
          <Flex
            className="ml-4"
            style={{ width: 489 }}
          >
            <Alert
              message="Статистика отпусков, отгулов и больничных"
              className="w-full h-full"
              description={(
                <Flex
                  vertical
                >
                  <Flex className="font-bold">
                    <Icon path={mdiCircleSmall} size={1} />
                    За последние 12 месяцев (всего пропущенных:
                    {' '}
                    {vacations.lastTwelveMonths + dayOffs.lastTwelveMonths
                    + sickdays.lastTwelveMonths}
                    )
                  </Flex>
                  <span className="ml-10">
                    дней отпуска:
                    {' '}
                    {vacations.lastTwelveMonths}
                  </span>
                  <span className="ml-10">
                    дней отгулов:
                    {' '}
                    {dayOffs.lastTwelveMonths}
                  </span>
                  <span className="ml-10">
                    дней больничных:
                    {' '}
                    {sickdays.lastTwelveMonths}
                  </span>
                  <Flex className="font-bold">
                    <Icon path={mdiCircleSmall} size={1} />
                    За
                    {' '}
                    {dayjs().format('YYYY')}
                    {' '}
                    год (всего пропущенных:
                    {' '}
                    {vacations.thisYear + dayOffs.thisYear
                    + sickdays.thisYear}
                    )
                  </Flex>
                  <span className="ml-10">
                    дней отпуска:
                    {' '}
                    {vacations.thisYear}
                  </span>
                  <span className="ml-10">
                    дней отгулов:
                    {' '}
                    {dayOffs.thisYear}
                  </span>
                  <span className="ml-10">
                    дней больничных:
                    {' '}
                    {sickdays.thisYear}
                  </span>
                </Flex>
              )}
              type="info"
              showIcon
            />
          </Flex>
        </Flex>

        <label
          htmlFor="add-timeline-status-form_dates"
          className="w-full ant-form-item-required text-xs text-[#777] mt-3"
          title="Тип"
        >
          {/* TODO: Create custom label component */}
          <span style={{ color: '#ff4d4f', paddingRight: 4 }}>
            *
          </span>
          Комментарий
        </label>
        <Flex className="mt-2 p-2 w-full border border-dashed border-stone-500 rounded">
          <Form.Item
            className="w-1/2 !h-full !m-0"
            name="comment"
          >
            <TextArea
              placeholder="Введите комментарий"
              rows={7}
            />
          </Form.Item>
          <Flex
            className="w-1/2 ml-4"
          >
            <InfoCommentVacation />
          </Flex>
        </Flex>
      </Row>
      <Space style={{ width: '100%', justifyContent: 'flex-end' }} className="mt-3">
        <Button disabled={false} onClick={() => onCancelModal()}>
          Отмена
        </Button>
        <Tooltip
          color="red"
          title={!startDate || !endDate || !comment?.trim() ? 'Комментарий и период отпуска являются обязательными полями!' : null}
        >
          <Button
            type="primary"
            onClick={onSave}
            disabled={!startDate
                || !endDate || isEditDisabled
                || isDisabled || !comment?.trim()}
          >
            {isEditMode ? 'Изменить' : 'Создать'}
            {' '}
            заявку
          </Button>
        </Tooltip>
        {!isAdminMode ? (
          <Tooltip
            color="red"
            title={!startDate || !endDate || !comment?.trim() ? 'Комментарий и период отпуска являются обязательными полями!' : null}
          >
            <Button
              type="primary"
              onClick={onSaveAndSendRequest}
              disabled={!startDate
                  || !endDate || isEditDisabled
                  || isDisabled || !comment?.trim()}
              icon={<SendOutlined />}
            >
              {capitalize(`${isEditMode ? 'Изменить' : 'Создать'} и отправить на рассмотрение`)}
            </Button>
          </Tooltip>
        ) : null}
      </Space>
    </Form>
  );
}

AddVacationForm.propTypes = {
  initValues: object,
  form: object,
  isEditMode: bool,
  uuid: string,
  isAdminMode: bool,
  onCancelModal: func,
  saveCallback: func,
  currentActor: string,
};
