import React, { useState } from 'react';

import {
  Button, Form, Modal,
} from 'antd';
import { capitalize } from 'lodash';

import { EditOutlined } from '@ant-design/icons';
import {
  bool, func, object, string,
} from 'prop-types';
import { formatDate } from '../../../../utils/dateTimeUtils';
import AddVacationForm from './AddVacationForm';
import AddHolidaysForm from './AddHolidaysForm';

export default function EditVacation({
  saveCallback, data, buttonProps, type, isAdminMode, currentActor,
}) {
  const {
    status, start_date, end_date, comment,
  } = data.params;

  const [isModalOpen, setModalOpen] = useState(false);

  const onOpenStatusModal = () => setModalOpen(true);

  const onCancelStatusModal = () => {
    setModalOpen(false);
  };

  const [form] = Form.useForm();

  const initValues = {
    status,
    type,
    start_date,
    end_date,
    comment,
  };

  return (
    <>
      <Button
        onClick={onOpenStatusModal}
        icon={<EditOutlined />}
        {...buttonProps}
      >
        Редактировать
      </Button>

      <Modal
        title={`${type === 'vacation' ? 'Отпуск' : 'Каникулы'}: ${capitalize(status)} (${formatDate(start_date)} - ${formatDate(end_date)})`}
        open={isModalOpen}
        footer={null}
        centered
        onCancel={() => onCancelStatusModal()}
        width={1200}
        destroyOnClose
      >
        {type === 'vacation' ? (
          <AddVacationForm
            currentActor={currentActor}
            initValues={initValues}
            uuid={data.uuid}
            form={form}
            isEditMode
            isAdminMode={isAdminMode}
            onCancelModal={onCancelStatusModal}
            saveCallback={saveCallback}
          />
        ) : (
          <AddHolidaysForm
            initValues={initValues}
            uuid={data.uuid}
            isAdminMode={isAdminMode}
            form={form}
            isEditMode
            onCancelModal={onCancelStatusModal}
            saveCallback={saveCallback}
          />
        )}
      </Modal>
    </>
  );
}
EditVacation.propTypes = {
  saveCallback: func,
  currentActor: string,
  data: object,
  buttonProps: object,
  isAdminMode: bool,
  type: string,
};
