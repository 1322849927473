/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { head } from 'lodash';

const initialState = {
  fetching: false,
  departments: [],
  fetchingSingle: false,
  department: {},
};

const departmentsSlice = createSlice({
  name: 'departmentsSlice',
  initialState,
  reducers: {
    CREATE_DEPARTMENT_REQUEST(state) {
      state.fetching = true;
    },
    CREATE_DEPARTMENT_SUCCESS(state, action) {
      state.fetching = false;
      state.departments.push(head(action.payload));
    },
    CREATE_DEPARTMENT_FAILURE(state) {
      state.fetching = false;
    },
    GET_DEPARTMENTS_REQUEST(state) {
      state.fetching = true;
    },
    GET_DEPARTMENTS_SUCCESS(state, action) {
      state.fetching = false;
      state.departments = action.payload;
    },
    GET_DEPARTMENTS_FAILURE(state) {
      state.fetching = false;
    },
    GET_DEPARTMENT_REQUEST(state) {
      state.fetching = true;
    },
    GET_DEPARTMENT_SUCCESS(state, action) {
      state.fetching = false;
      state.department = head(action.payload);
    },
    GET_DEPARTMENT_FAILURE(state) {
      state.fetching = false;
    },
  },
});

export const {
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAILURE,
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAILURE,
} = departmentsSlice.actions;

export default departmentsSlice.reducer;
