import React from 'react';

import { Button } from 'antd';
import PropTypes from 'prop-types';

import SVG from '../assets/images/no-data.svg';

function ErrorBoundary({ children }) {
  const refreshPage = () => {
    window.location.reload();
  };

  try {
    return children;
  } catch (e) {
    return (
      <div className="container error-container">
        <div className="row justify-content-center">
          <div className="col align-self-center text-center">
            <div className="row">
              <div className="col-md-6 error-first-section">
                <p>
                  <h1>No data :(</h1>
                </p>
                <p>
                  <h3>
                    Something went wrong. Caught by error
                    boundary.
                  </h3>
                </p>
                <div className="d-flex justify-content-center">
                  <Button
                    className="button-primary"
                    onClick={refreshPage}
                  >
                    Reload page
                  </Button>
                </div>
              </div>
              <div className="col-md-6">
                <img src={SVG} alt="error" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
