import React, { useState } from 'react';

import { Button, Form, Modal } from 'antd';
import { capitalize } from 'lodash';

import { ExclamationCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { func, string } from 'prop-types';
import AddVacationForm from './AddVacationForm';

const { confirm } = Modal;

export default function AddNewVacation({
  saveCallback,
  currentActor,
  ...buttonProps
}) {
  const initValues = {
    start_date: null,
    end_date: null,
    status: 'created',
    type: 'vacation',
    comment: '',
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const [form] = Form.useForm();

  const onOpenModal = () => setModalOpen(true);

  const showConfirm = () => {
    confirm({
      title: 'Вы уверены в этом?',
      icon: <ExclamationCircleFilled />,
      content: 'Изменения не будут сохранены',
      centered: true,
      onOk() {
        setModalOpen(false);
        form.resetFields();
      },
      onCancel() {
        setModalOpen(true);
      },
    });
  };

  const onCancelModal = () => {
    if (form.isFieldsTouched()) {
      showConfirm();
      return;
    }
    form.resetFields();
    setModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={onOpenModal}
        icon={<PlusCircleFilled />}
        {...buttonProps}
      >
        {capitalize('добавить заявку на отпуск')}
      </Button>
      <Modal
        title={capitalize('заявка на отпуск')}
        open={isModalOpen}
        footer={null}
        centered
        onCancel={() => onCancelModal()}
        width={1200}
        destroyOnClose
      >
        <AddVacationForm
          currentActor={currentActor}
          initValues={initValues}
          form={form}
          onCancelModal={onCancelModal}
          saveCallback={saveCallback}
        />
      </Modal>
    </>
  );
}
AddNewVacation.propTypes = {
  saveCallback: func,
  currentActor: string,
};
