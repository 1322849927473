import { Avatar } from 'antd';

import { mdiEmoticonCool, mdiEmoticonNeutral, mdiEmoticonSad } from '@mdi/js';
import Icon from '@mdi/react';

import android from '../../../../assets/icons/technologies/android-vertical.svg';
import ios from '../../../../assets/icons/technologies/apple.svg';
import html5 from '../../../../assets/icons/technologies/html-5.svg';
import devOps from '../../../../assets/icons/technologies/microsoft-azure.svg';
import python from '../../../../assets/icons/technologies/python.svg';
import reactjs from '../../../../assets/icons/technologies/react.svg';
import qa from '../../../../assets/icons/technologies/testing-library.svg';

const getTechnologyLabel = (iconPath, label) => (
  <div
    style={{
			  padding: 4,
    }}
		>
    <Avatar shape="square" src={iconPath} />
    <div>{label}</div>
  </div>
);

const getSeniorityLabel = (iconPath, label, color) => (
  <div
    style={{
			  padding: 4,
    }}
		>
    <Avatar
      shape="square"
      icon={<Icon path={iconPath} size={1} color={color} />}
    />
    <div>{label}</div>
  </div>
);

export const technologies = [
  {
    label: getTechnologyLabel(python, 'Python'),
    value: 'python',
  },
  {
    label: getTechnologyLabel(html5, 'HTML/CSS'),
    value: 'html_css',
  },
  {
    label: getTechnologyLabel(reactjs, 'ReactJS'),
    value: 'reactjs',
  },
  {
    label: getTechnologyLabel(devOps, 'DevOps'),
    value: 'devops',
  },
  {
    label: getTechnologyLabel(qa, 'QA'),
    value: 'qa',
  },
  {
    label: getTechnologyLabel(qa, 'QA Automation'),
    value: 'auto_qa',
  },
  {
    label: getTechnologyLabel(android, 'Android'),
    value: 'android',
  },
  {
    label: getTechnologyLabel(ios, 'iOS'),
    value: 'ios',
  },
];

export const seniorityList = [
  {
    label: getSeniorityLabel(mdiEmoticonSad, 'Junior', ''),
    value: 'junior',
  },
  {
    label: getSeniorityLabel(mdiEmoticonNeutral, 'Middle', ''),
    value: 'middle',
  },
  {
    label: getSeniorityLabel(mdiEmoticonCool, 'Senior', ''),
    value: 'senior',
  },
];
