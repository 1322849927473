import {
  Button,
  Card, Divider, Flex, Segmented, Table, Tag,
  Upload,
} from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiPencil } from '@mdi/js';
import { func } from 'prop-types';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';
import { getArtifact, getArtifactUUID } from '../../../../redux/departments/selectors/artifactsSelector';
import { getDepartment } from '../../../../redux/departments/selectors/departmentsSelector';
import UserArtifactView from './UserArtifactView';
import InstructionArtifactView from './InstructionArtifactView';
import ScheduleArtifactView from './ScheduleArtifactView';
import FileArtifactView from './FileArtifactView';
import { deleteSingleArtifact, updateSingleArtifact } from '../../../../redux/departments/actions/artifactsActions';

export default function ArtifactCard({
  onBackCallback,
  onDeleteArtifactCallback,
  onUpdateArtifactCallback,
}) {
  const dispatch = useDispatch();

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);

  const department = useSelector(getDepartment);

  const getCardName = (type) => {
    switch (type) {
      case 'file': {
        return 'Файл';
      }
      case 'instruction': {
        return 'Инструкция';
      }
      case 'schedule': {
        return 'Расписание';
      }
      case 'user': {
        return 'Сотрудник';
      }
      default: return '';
    }
  };

  const onDeleteArtifact = async () => {
    if (artifactUUID) {
      await dispatch(deleteSingleArtifact(artifactUUID));
      if (onDeleteArtifactCallback) {
        onDeleteArtifactCallback();
      }
    }
  };

  const onUpdateArtifact = async (data = {}) => {
    if (artifactUUID) {
      await dispatch(updateSingleArtifact({
        entity_uuid: artifactUUID,
        params: {
          ...data,
        },
      }));
      if (onUpdateArtifactCallback) {
        onUpdateArtifactCallback();
      }
    }
  };

  return (
    <Card
      title={(
        <Flex
          className="w-full"
          justify="space-between"
        >
          <Flex
            align="center"
            className="cursor-pointer"
            onClick={onBackCallback}
          >
            <Icon path={mdiArrowLeft} size={0.9} />
            <span className="ml-3 text-xl">{getCardName(artifact?.entity_type)}</span>
          </Flex>
          <Flex>
            {artifact?.entity_type !== 'user' && (
            <Button
              size="small"
              onClick={onDeleteArtifact}
              className="!text-red-500 border !border-red-500 hover:bg-red-100"
            >
              Удалить
            </Button>
            )}
          </Flex>
        </Flex>
          )}
      className={cn('w-1/2 h-max ml-6 p-0', {
        'orange-base-card': artifact?.entity_type === 'user',
        'blue-base-card': artifact?.entity_type === 'schedule' || artifact?.entity_type === 'instruction',
        'green-base-card': artifact?.entity_type === 'file',
      })}
    >
      <Flex
        vertical
        className="p-12 ml-4"
      >
        {artifact?.entity_type === 'user' && (
        <UserArtifactView
          onUpdateArtifactCallback={onUpdateArtifact}
          artifact={artifact}
        />
        )}
        {artifact?.entity_type === 'instruction' && (
        <InstructionArtifactView
          onUpdateArtifactCallback={onUpdateArtifact}
          artifact={artifact}
        />
        )}
        {artifact?.entity_type === 'schedule' && (
        <ScheduleArtifactView
          onUpdateArtifactCallback={onUpdateArtifact}
          artifact={artifact}
        />
        )}
        {artifact?.entity_type === 'file' && (
        <FileArtifactView
          onUpdateArtifactCallback={onUpdateArtifact}
          artifact={artifact}
        />
        )}
      </Flex>
    </Card>
  );
}

ArtifactCard.propTypes = {
  onBackCallback: func,
  onDeleteArtifactCallback: func,
  onUpdateArtifactCallback: func,
};
