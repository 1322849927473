export const iconSize = {
  extraSmall: 0.6,
  small: 0.7,
  medium: 0.8,
};

export const prefixSize = {
  extraSmall: 'isExtraSmall',
  small: 'isSmall',
  medium: 'isMedium',
};
