import _ from 'lodash';

import ConfigConstants from './constants';

const initialState = {
  isFetching: false,
  entity_domain: '',
  header_partitions: '',
  partitions: null,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
	console.log(type)
  switch (type) {
    case ConfigConstants.GET_PUBLIC_INTERFACES_REQUEST:
    case ConfigConstants.GET_PARTITIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ConfigConstants.GET_PUBLIC_INTERFACES_SUCCESS: {
      const domain = _.get(
        payload,
        'public_interface[0].params.backend_domain',
        '',
      );

      return {
        ...state,
        isFetching: false,
        entity_domain: domain,
      };
    }

    case ConfigConstants.GET_PARTITIONS_SUCCESS: {
      const headerPartitions = payload.reduce(
        (acc, item) => ({
          ...acc,
          [_.get(item, 'params.name')]: _.get(item, 'header_uuid'),
        }),
        {},
      );

      const partitions = payload.reduce(
        (acc, item) => ({
          ...acc,
          [_.get(item, 'params.name')]: _.get(item, 'uuid'),
        }),
        {},
      );

      return {
        ...state,
        isFetching: false,
        header_partitions: headerPartitions,
        partitions,
      };
    }

    case ConfigConstants.GET_PUBLIC_INTERFACES_FAILURE:
    case ConfigConstants.GET_PARTITIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
