import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import {
  Alert, Flex,
} from 'antd';
import dayjs from 'dayjs';

import { getProfileInfo } from '../../../../redux/profile/selectors';
import useAPI from '../../../../api/useAPI';
import { getVacationStorageUUID } from '../../../../redux/storages/selectors';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';
import { getNameForActor } from '../../../../54origins/utils54origins';

export default function AgreedUserVacations({ customActor }) {
  const myProfileData = useSelector(getProfileInfo);
  const vacationStorageUUID = useSelector(getVacationStorageUUID);

  const { getUserVacationsRequests } = useAPI();

  const [approvedVacations, setApprovedVacations] = useState([]);

  const currentActor = customActor || myProfileData?.uuid;

  const getAgreedVacationsRequests = async () => {
    const configRequest = {
      entity_type: 'vacation',
      params: {
        actorUuid: currentActor,
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs().format('YYYY-MM-DD'),
          dayjs().add(40, 'day').format('YYYY-MM-DD'),
        ),
      },
    };

    const res = await getUserVacationsRequests(
      vacationStorageUUID,
      configRequest,
      [
        'GET_APPROVED_USER_VACATIONS_REQUEST',
        'GET_APPROVED_USER_VACATIONS_SUCCESS',
        'GET_APPROVED_USER_VACATIONS_FAILURE',
      ],
    );
    setApprovedVacations(res?.data ?? []);
  };

  useEffect(() => {
    if (vacationStorageUUID && currentActor) {
      getAgreedVacationsRequests();
    }
  }, [vacationStorageUUID, currentActor]);

  return (
    <Flex gap={16}>
      {approvedVacations.map((el) => (
        <Alert
          className="mb-3"
          key={el?.uuid}
          message={(
            <>
              <p className="mt-0">
                Ваш отпуск в период с
              </p>
              <p>
                {' '}
                {dayjs(el?.params?.start_date).format('DD-MM-YYYY')}
                {' '}
                по
                {' '}
                {dayjs(el?.params?.end_date).format('DD-MM-YYYY')}
                {' '}
                утвержден
              </p>
              <p className="mb-0">
                (утвердил:
                {' '}
                <b>{getNameForActor({ uinfo: el?.params?.reviewed?.uinfo }).slice(0, 60)}</b>
                ).
              </p>
            </>
          )}
          type="success"
          showIcon
          description={(<div />)}
        />
      ))}
    </Flex>
  );
}

AgreedUserVacations.propTypes = {
  customActor: string,
};
