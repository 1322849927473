import React, { useEffect, useState } from 'react';
import {
  Flex, Table, Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { array } from 'prop-types';
import { omit } from 'lodash';
import Icon from '@mdi/react';
import { mdiCommentOutline } from '@mdi/js';
import { colorByDayType, getLabelByType } from '../dayOff/utils';

export default function DaysTable({ days = [] }) {
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: 'Дата изменения',
      dataIndex: ['created'],
      render: (date) => dayjs(date).format('DD MMM YYYY'),
      sorter: (a, b) => dayjs(a?.created).unix() - dayjs(b?.created).unix(),
    },
    {
      title: 'Число',
      dataIndex: ['rangeDates'],
      render: (dates) => dayjs(dates?.[0]).format('DD MMMM'),
      sorter: (a, b) => dayjs(a?.rangeDates?.[0]).unix() - dayjs(b?.rangeDates?.[0]).unix(),
    },
    {
      title: 'Тип дня',
      dataIndex: ['type'],
      render: (type, row) => (
        <Flex
          align="center"
        >
          {getLabelByType(type, row)}
          {row?.comment?.length ? (
            <Tooltip
              title={row?.comment}
              color={colorByDayType?.[type]}
            >
              <Icon
                color={colorByDayType?.[type]}
                path={mdiCommentOutline}
                size={1}
              />
            </Tooltip>
          ) : null}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    const data = days?.reduce((acc, day) => {
      acc.push(omit(day, ['logs']));
      if (day?.logs?.length) {
        day?.logs.forEach((log) => acc.push(log));
      }
      return acc;
    }, [])
      ?.sort((a, b) => dayjs(b?.created).unix() - dayjs(a?.created).unix());
    setDataSource(data);
  }, [JSON.stringify(days)]);

  return (
    <Table
      style={{
        marginLeft: -46,
        marginTop: 10,
      }}
      rowKey={(row) => row?.created}
      columns={columns}
      dataSource={dataSource}
      size="small"
    />
  );
}

DaysTable.propTypes = {
  days: array,
};
