import { addItem, updateItem } from '../../utils/modifier';
import UserTimelineProjectConstants from './constants';

const initState = {
  isFetching: false,
  list: [],
  single: null,
  error: false,
  errorMessage: '',
};

export default (state = initState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    // GET USER TIMELINE STATUSES
    case UserTimelineProjectConstants.GET_USER_TIMELINES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserTimelineProjectConstants.GET_USER_TIMELINES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: payload,
        error: false,
      };
    }

    case UserTimelineProjectConstants.GET_USER_TIMELINES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

      // GET USER TIMELINE STATUS
    case UserTimelineProjectConstants.GET_USER_TIMELINE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        errorMessage: '',
      };

    case UserTimelineProjectConstants.GET_USER_TIMELINE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        single: payload[0],
        error: false,
        errorMessage: '',
      };
    }

    case UserTimelineProjectConstants.GET_USER_TIMELINE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

      // CREATE USER TIMELINE STATUS
    case UserTimelineProjectConstants.CREATE_USER_TIMELINE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserTimelineProjectConstants.CREATE_USER_TIMELINE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: [payload[0], ...state.list],
        error: false,
      };
    }

    case UserTimelineProjectConstants.CREATE_USER_TIMELINE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

      // UPDATE USER TIMELINE STATUS
    case UserTimelineProjectConstants.UPDATE_USER_TIMELINE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserTimelineProjectConstants.UPDATE_USER_TIMELINE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: updateItem(state.list, payload[0]),
        error: false,
      };
    }

    case UserTimelineProjectConstants.UPDATE_USER_TIMELINE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return state;
  }
};
