import keyMirror from 'keymirror';

const UserHolidaysConstants = keyMirror({
  GET_USER_HOLIDAYS_REQUEST: null,
  GET_USER_HOLIDAYS_SUCCESS: null,
  GET_USER_HOLIDAYS_FAILURE: null,

  CREATE_USER_HOLIDAYS_REQUEST: null,
  CREATE_USER_HOLIDAYS_SUCCESS: null,
  CREATE_USER_HOLIDAYS_FAILURE: null,

  UPDATE_USER_HOLIDAYS_REQUEST: null,
  UPDATE_USER_HOLIDAYS_SUCCESS: null,
  UPDATE_USER_HOLIDAYS_FAILURE: null,

  DELETE_USER_HOLIDAYS_REQUEST: null,
  DELETE_USER_HOLIDAYS_SUCCESS: null,
  DELETE_USER_HOLIDAYS_FAILURE: null,
});

export default UserHolidaysConstants;
