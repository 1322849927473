import UserDayOffConstants from './constants';

const initState = {
  isFetchingYear: false,
  isFetching: false,
  isFetchingList: false,
  isFetchingLastMonth: false,
  total: 0,
  totalLastMonth: 0,
  list: [],
  listLastMonth: [],
  error: false,
  errorMessage: '',
  currentYear: null,
};

export default (state = initState, action = {}) => {
  const { type, payload, total } = action;

  switch (type) {
    case UserDayOffConstants.GET_YEAR_DAY_OFF_REQUEST: {
      return {
        ...state,
        isFetchingYear: true,
        error: false,
      };
    }
    case UserDayOffConstants.GET_YEAR_DAY_OFF_SUCCESS: {
      return {
        ...state,
        isFetchingYear: false,
        currentYear: payload,
      };
    }
    case UserDayOffConstants.GET_YEAR_DAY_OFF_FAILURE: {
      return {
        ...state,
        isFetchingYear: false,
        error: true,
        errorMessage: payload.error_message,
      };
    }
    // GET USER DAY OFF
    case UserDayOffConstants.GET_USER_DAY_OFF_REQUEST:
      return {
        ...state,
        isFetchingList: true,
        error: false,
      };

    case UserDayOffConstants.GET_USER_DAY_OFF_SUCCESS: {
      return {
        ...state,
        isFetchingList: false,
        list: payload,
        total: total || 0,
        error: false,
        errorMessage: '',
      };
    }

    case UserDayOffConstants.GET_USER_DAY_OFF_FAILURE:
      return {
        ...state,
        isFetching: false,
        totalLastMonth: 0,
        error: true,
        errorMessage: payload.error_message,
      };

      // CREATE USER DAY OFF
    case UserDayOffConstants.CREATE_USER_DAY_OFF_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserDayOffConstants.CREATE_USER_DAY_OFF_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserDayOffConstants.CREATE_USER_DAY_OFF_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

      // UPDATE USER DAY OFF
    case UserDayOffConstants.UPDATE_USER_DAY_OFF_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserDayOffConstants.UPDATE_USER_DAY_OFF_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserDayOffConstants.UPDATE_USER_DAY_OFF_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

      // DELETE USER DAY OFF
    case UserDayOffConstants.DELETE_USER_DAY_OFF_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserDayOffConstants.DELETE_USER_DAY_OFF_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserDayOffConstants.DELETE_USER_DAY_OFF_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

    default:
      return state;
  }
};
