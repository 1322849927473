import { string } from 'prop-types';
import React from 'react';
import { mdiLink } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, message, Tooltip } from 'antd';
import { stopPropagation } from '../../utils/utils54origins';

function CopyURL({
  className,
  entityType,
  entityUUID,
  placement,
}) {
  const getPatch = () => {
    switch (entityType) {
      case 'vacation': return `v=${entityUUID}`;
      default: return '';
    }
  };

  const copyToClipboard = (e) => {
    stopPropagation(e);
    navigator.clipboard.writeText(`${window.location.origin}/s/${getPatch()}`);
    message.success('Link copied');
  };

  return (
    <Tooltip
      placement={placement || 'top'}
      title="Copy link"
      color="rgb(240, 148, 29)"
    >
      <Button
        id="link"
        shape="circle"
        type="primary"
        ghost
        className={`view_btn_link__orange ${className}`}
        onClick={copyToClipboard}
      >
        <Icon
          path={mdiLink}
          size={1}
        />
        {/* Copy link */}
      </Button>
    </Tooltip>
  );
}

CopyURL.propTypes = {
  className: string,
  entityType: string,
  entityUUID: string,
  placement: string,
};

export default CopyURL;
