import React, {
  useCallback,
  useEffect, useState,
} from 'react';

import {
  Card, Divider, Flex, Segmented, Space, Spin,
  Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get, omit } from 'lodash';

import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { getFetchingVacationList, getVacations, getVacationsCount } from '../../../redux/userVacation/selectors';

import useAPI from '../../../api/useAPI';
import { getProfileInfo } from '../../../redux/profile/selectors';
import TableVacations from '../../user/vacationsRequests/TableVacations';
import ApprovedUserVacation from './modals/ApprovedUserVacation';
import RejectedUserVacation from './modals/RejectedUserVacation';
import { hideColumnsVacations } from './utils';
import { defaultRequestOptions, maxCountOfVacationDays } from '../../user/vacationsRequests/utils';
import GlobalUpdateConstants from '../../../redux/globalUpdate/constants';
import CopyURL from '../../../components/commonComponents/CopyURL';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { getFullNameOrInitials, stopPropagation } from '../../../utils/utils54origins';
import { diffDays } from '../../../utils/dateTimeUtils';
import EditVacation from '../../user/vacationsRequests/components/EditVacation';
import UserDayOffHistory from './modals/UserDayOffHistory';

function UsersVacationsRequestCard() {
  const dispatch = useDispatch();

  const { getUserVacationsRequests } = useAPI();

  const isFetching = useSelector(getFetchingVacationList);

  const rootPartitionUuid = useSelector(getDeliveryPartition);
  const myProfileData = useSelector(getProfileInfo);
  const vacations = useSelector(getVacations);
  const vacationsTotal = useSelector(getVacationsCount);

  const [config, setConfig] = useState(defaultRequestOptions);

  const [selectedUser, setSelectedUser] = useState('');

  const [selectedStatus, setStatus] = useState('all');

  const optionsStatusFilter = [
    {
      value: 'all',
      label: 'Все',
    }, {
      value: 'pending',
      label: 'На рассмотрении',
    }, {
      value: 'pre-agreed',
      label: 'Предварительно согласован',
    },
  ];

  const onRow = (record, rowIndex) => ({
    onClick: () => {
      setSelectedUser({
        uinfo: record.uinfo,
        actor: record.actor,
      });
    },
  });

  const getVacationsRequests = () => {
    getUserVacationsRequests(
      rootPartitionUuid,
      {
        params: {
          status: selectedStatus === 'all' ? ['pending', 'pre-agreed'] : selectedStatus,
        },
        ...config,
      },
    );
  };

  const reloadVacations = () => {
    getVacationsRequests();
    dispatch({ type: GlobalUpdateConstants.SET_GET_DATA, payload: 'getAgreedList' });
  };

  const saveAgreedCallback = () => {
    if (vacationsTotal - 1 === config.offset) {
      setConfig(defaultRequestOptions);
      dispatch({ type: GlobalUpdateConstants.SET_RESET_PAGE, payload: 'vacationRequests' });
      return;
    }
    reloadVacations();
  };

  const getColumnsCallback = useCallback((defaultColumns) => {
    const data = defaultColumns.reduce(((acc, i) => {
      if (i?.key === 'status') {
        return [
          {
            dataIndex: 'uuid',
            width: 20,
            title: '',
            render: (uuid) => (
              <CopyURL
                entityType="vacation"
                entityUUID={uuid}
              />
            ),
          },
          {
            title: 'Сотрудник',
            dataIndex: ['uinfo'],
            render: (uinfo, row) => {
              const fullName = getFullNameOrInitials({ uinfo }, 'fullname');
              const startDate = get(row, 'params.start_date');
              const endDate = get(row, 'params.end_date');
              const days = diffDays(startDate, endDate);
              return (
                <Flex align="center">
                  {days > maxCountOfVacationDays ? (
                    <Tooltip
                      title={`Отпуск больше ${maxCountOfVacationDays} дней!`}
                      color="#DC2626"
                      className="mr-1"
                    >
                      <Icon
                        path={mdiAlertCircleOutline}
                        size={1}
                        color="#DC2626"
                      />
                    </Tooltip>
                  ) : null}
                  {fullName?.length >= 60 ? (
                    <Tooltip
                      style={{ width: 200 }}
                      title={fullName}
                    >
                      {fullName.slice(0, 57)}
                      ...
                    </Tooltip>
                  ) : fullName}
                </Flex>
              );
              // capitalize((actors.has(row?.params?.actorUuid) ? actors.get(row?.params?.actorUuid)?.label : ''));
            },
          }, i, ...acc];
      }

      if (i?.key === 'created') {
        return [
          ...acc,
          {
            title: 'Согласовал',
            dataIndex: ['params', 'agreed'],
            render: (agreed, row) => {
              if (!agreed) {
                return '-';
              }
              return `${get(agreed?.uinfo, 'first_name')} ${get(agreed?.uinfo, 'last_name')}`;
            },
          },
          i,
          {
            className: 'col-actions',
            dataIndex: ['uuid'],
            title: 'Действия',
            render: (uuid, row) => (
              <Space onClick={(e) => stopPropagation(e)}>
                <ApprovedUserVacation
                  row={row}
                  status={row?.params?.status}
                  buttonProps={{
                    type: 'primary',
                    ghost: true,
                  }}
                  saveCallback={saveAgreedCallback}
                />
                <RejectedUserVacation
                  row={row}
                  saveCallback={saveAgreedCallback}
                />
                <EditVacation
                  data={row}
                  isAdminMode
                  currentActor={row?.actor}
                  type={row?.entity_type}
                  buttonProps={{ disabled: false }}
                  saveCallback={reloadVacations}
                />
              </Space>
            )
            ,
          }];
      }
      return [...acc, i];
    }), []);

    return data;
  }, [vacations]);

  const savePaginationConfigCallback = (data) => setConfig((prev) => ({ ...prev, ...data }));

  const currentSortCallback = (data) => {
    setConfig((prev) => ({
      ...omit(prev, ['order_by', 'order_by_params']),
      order: data.order,
      [data.orderBy === 'created' ? 'order_by' : 'order_by_params']: data.orderBy,
    }));
  };

  const onChangeStatus = (status) => {
    setStatus(status);
    dispatch({ type: GlobalUpdateConstants.SET_RESET_PAGE, payload: 'vacationRequests' });

    setConfig((prev) => ({
      ...prev,
      offset: 0,
    }));
  };

  useEffect(() => {
    if (myProfileData?.uuid && rootPartitionUuid) {
      getVacationsRequests();
    }
  }, [myProfileData?.uuid, rootPartitionUuid, config]);

  return (
    <Spin spinning={isFetching}>
      <Card title="Запросы на отпуск">
        <Space
          wrap
          className="mb-2 -mt-2"
          split={<Divider type="vertical" />}
        >
          <span>Статус:</span>
          <Segmented
            options={optionsStatusFilter}
            value={selectedStatus}
            onChange={onChangeStatus}
          />
        </Space>
        <div className="overflow-auto">
          <TableVacations
            onRow={onRow}
            id="vacationRequests"
            total={vacationsTotal}
            vacations={vacations}
            isSorterStatus={false}
            hide={hideColumnsVacations}
            getColumnsCallback={getColumnsCallback}
            savePaginationConfigCallback={savePaginationConfigCallback}
            currentSortCallback={currentSortCallback}
          />
        </div>
      </Card>

      {selectedUser && (
        <UserDayOffHistory
          isOpen={selectedUser}
          onClose={() => setSelectedUser('')}
          actor={selectedUser.actor}
          uinfo={selectedUser.uinfo}
        />
      )}
    </Spin>
  );
}

export default UsersVacationsRequestCard;
