import keyMirror from 'keymirror';

const StoragesConstants = keyMirror({
  RESET_STORAGES: null,

  GET_ROOT_USER_REQUEST: null,
  GET_ROOT_USER_SUCCESS: null,
  GET_ROOT_USER_FAILURE: null,

  GET_VACATION_STORAGE_REQUEST: null,
  GET_VACATION_STORAGE_SUCCESS: null,
  GET_VACATION_STORAGE_FAILURE: null,

  GET_MONTHLY_REPORTS_STORAGE_REQUEST: null,
  GET_MONTHLY_REPORTS_STORAGE_SUCCESS: null,
  GET_MONTHLY_REPORTS_STORAGE_FAILURE: null,
});

export default StoragesConstants;
