import keyMirror from 'keymirror';

const HolidaysConstants = keyMirror({
  GET_HOLIDAYS_REQUEST: null,
  GET_HOLIDAYS_SUCCESS: null,
  GET_HOLIDAYS_FAILURE: null,

  CREATE_HOLIDAYS_REQUEST: null,
  CREATE_HOLIDAYS_SUCCESS: null,
  CREATE_HOLIDAYS_FAILURE: null,
});

export default HolidaysConstants;
