import keyMirror from 'keymirror';

const ReportConstants = keyMirror({
  GET_YEAR_REPORT_REQUEST: null,
  GET_YEAR_REPORT_SUCCESS: null,
  GET_YEAR_REPORT_FAILURE: null,

  CREATE_REPORT_REQUEST: null,
  CREATE_REPORT_SUCCESS: null,
  CREATE_REPORT_FAILURE: null,

  GET_REPORTS_REQUEST: null,
  GET_REPORTS_SUCCESS: null,
  GET_REPORTS_FAILURE: null,

  GET_LAST_MONTH_REPORTS_REQUEST: null,
  GET_LAST_MONTH_REPORTS_SUCCESS: null,
  GET_LAST_MONTH_REPORTS_FAILURE: null,
});

export default ReportConstants;
