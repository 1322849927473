import React from 'react';

import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

function UnderDevelopment() {
  const history = useHistory();

  const goToDashboardPage = () => history.push('/deliverydashboard');

  return (
    <Result
      title="Under development"
      subTitle="Not available yet"
      extra={(
        <Button
          type="primary"
          key="console"
          onClick={goToDashboardPage}
        >
          Go home page
        </Button>
  )}
    />
  );
}

export default UnderDevelopment;
