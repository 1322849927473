import React, {
  useState, useRef,
} from 'react';

import {
  array,
  bool,
  func, number, object, string,
} from 'prop-types';
import {
  Flex,
  Row,
} from 'antd';
import {
  AutoSizer, List,
} from 'react-virtualized';
import ItemForListFoActorsW54 from './ItemForListFoActorsR54';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';
import './user-list-styles.scss';
import useActorsR54WithPagination from '../hooks/useActorsR54WithPagination';

export default function ListOfActorsR54WithHook({
  onClickCallback,
  selectedUser = null,
}) {
  const {
    actors, total, config, setConfig,
    onChangeSearch,
    valueSearch,
  } = useActorsR54WithPagination();

  const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
    const { limit = 100, offset = 0 } = config;

    if (scrollTop + clientHeight >= scrollHeight - 400
      && setConfig) {
      const newOffset = offset + limit;
      if (newOffset < total) {
        setConfig((prev) => ({
          ...prev,
          offset: newOffset,
        }));
      }
    }
  };

  const rowRenderer = ({ index, style, isScrolling }) => {
    if (actors[index] === undefined && isScrolling) {
      return (
        <div key={index} style={style}>
          Scrolling...
        </div>
      );
    }

    const {
      value, label, actor,
    } = actors[index];

    return (
      <div key={value} style={{ ...style }}>
        <ItemForListFoActorsW54
          onClickCallback={onClickCallback}
          label={label}
          uuid={value}
          actor={actor}
          isSelected={value === selectedUser?.value}
        />
      </div>
    );
  };

  return (
    <>
      <AntDesignSearchBox54origins
        maxWidth={350}
        valueSearch={valueSearch}
        onSearch={onChangeSearch}
      />
      <AutoSizer
        className="w-100 mt-2 mb-2"
        style={{
          height: '300px',
        }}
      >
        {({ width, height }) => (
          <List
            height={height}
            rowHeight={50}
            width={width}
            rowCount={actors?.length || 0}
            onScroll={handleScroll}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </>
  );
}

ListOfActorsR54WithHook.propTypes = {
  onClickCallback: func,
  selectedUser: object,
};
