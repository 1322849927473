import keyMirror from 'keymirror';

const UserVacationConstants = keyMirror({
  GET_USER_VACATIONS_REQUEST: null,
  GET_USER_VACATIONS_SUCCESS: null,
  GET_USER_VACATIONS_FAILURE: null,

  CREATE_USER_VACATION_REQUEST: null,
  CREATE_USER_VACATION_SUCCESS: null,
  CREATE_USER_VACATION_FAILURE: null,

  UPDATE_USER_VACATION_REQUEST: null,
  UPDATE_USER_VACATION_SUCCESS: null,
  UPDATE_USER_VACATION_FAILURE: null,

  DELETE_USER_VACATION_REQUEST: null,
  DELETE_USER_VACATION_SUCCESS: null,
  DELETE_USER_VACATION_FAILURE: null,

  GET_ALL_USER_VACATIONS_REQUEST: null,
  GET_ALL_USER_VACATIONS_SUCCESS: null,
  GET_ALL_USER_VACATIONS_FAILURE: null,
});

export default UserVacationConstants;
