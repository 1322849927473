import _ from 'lodash';

import ConfigConstants from './constants';

const initState = {
  isFetching: false,
  list: [],
  current: {
    uuid: '',
  },
  isLoaded: false,
};

export default (state = initState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ConfigConstants.GET_HUMAN_RESOURCE_LIST_REQUEST:
    case ConfigConstants.GET_HUMAN_RESOURCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isLoaded: false,
      };

    case ConfigConstants.GET_HUMAN_RESOURCE_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        list: payload,
      };
    }

    case ConfigConstants.GET_HUMAN_RESOURCE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        current: {
          ...state.current,
          ..._.head(payload),
        },
      };
    }

    case ConfigConstants.GET_HUMAN_RESOURCE_LIST_FAILURE:
    case ConfigConstants.GET_HUMAN_RESOURCE_FAILURE:
      return {
        ...state,
        isLoaded: true,
        isFetching: false,
      };

    default:
      return state;
  }
};
