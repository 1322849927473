/* eslint-disable */
// noinspection ES6CheckImport
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";



import { history } from "../routers/history";
import rootReducer from ".";
import { routerMiddleware } from "connected-react-router/immutable";


const initialState = {}
const enhancers = []
const middleware = [thunkMiddleware, routerMiddleware(history)]

// noinspection JSUnresolvedVariable
if (process.env.NODE_ENV === 'development') {
	// noinspection JSUnresolvedVariable
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension())
	}
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

export default createStore(
	rootReducer(history),
	initialState,
	composedEnhancers,
)
