import keyMirror from 'keymirror'

const ConfigConstants = keyMirror({
	GET_PUBLIC_INTERFACES_REQUEST: null,
	GET_PUBLIC_INTERFACES_SUCCESS: null,
	GET_PUBLIC_INTERFACES_FAILURE: null,

	GET_PARTITIONS_REQUEST: null,
	GET_PARTITIONS_SUCCESS: null,
	GET_PARTITIONS_FAILURE: null,
})

export default ConfigConstants
