import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';

export const daysList = [
  { key: 'office_work', label: 'Работа на проекте' },
  // { key: 'remote_work', label: 'Работаю удаленно' },
  { key: 'bench_1_month', label: 'Работа на бенче' },
];

export const benchList = [
  { key: 'bench_1_month', label: 'Бенч меньше месяца' },
  { key: 'bench_over_1_month', label: 'Бенч больше 1-го месяца' },
  { key: 'bench_over_3_month', label: 'Бенч больше 3-х месяцев' },
];

export const minutesInDay = 1440;

export const isValidKeys = {
  hoursWorked: 'isValidTotalHoursWorked',
  lunchBreak: 'isValidLunchBreakTime',
  startWork: 'isValidStartWorkTime',
  finishWork: 'isValidFinishWorkTime',
};

export const arrTimeFromStartAndFinishModal = [{ type: 'PlusAndMinus', number: '30' }];

export const arrTimeLunchBreak = [{ type: 'PlusAndMinus', number: '15' }];

export const convertTimeFromHHhmmm = (time) => {
  const hours = parseInt(time.slice(0, time.indexOf('h')), 10);
  const minutes = parseInt(time.slice(time.indexOf('h') + 1), 10);

  const startDay = dayjs().startOf('day').add(hours, 'h').add(minutes, 'minute')
    .format('YYYY-MM-DD HH:mm');
  return {
    fullTime: startDay,
    hours,
    minutes,
  };
};

export const convertHHhmmmToHours = (time) => {
  if (!time) return null;

  const { hours, minutes } = convertTimeFromHHhmmm(time);

  return (+hours + +minutes / 60).toFixed(2);
};

export const convertHHhmmmToMinutes = (time) => {
  if (!time) return null;

  const { hours, minutes } = convertTimeFromHHhmmm(time);

  return hours * 60 + minutes;
};

export const convertArrayOfHHhmmmToSum = (arr) => {
  if (isEmpty(arr)) {
    return '00h00m';
  }
  const arrInMinutes = arr.map((time) => {
    const e = time || '00h00m';
    const h = Number.parseInt(e.slice(0, e.indexOf('h')), 10);
    const m = Number.parseInt(e.slice(e.indexOf('h') + 1), 10);

    const fullMin = h * 60 + m;
    return fullMin;
  });

  const sum = arrInMinutes.reduce((a, b) => a + b, 0);

  const newH = Math.floor(sum / 60);
  const newMin = sum - Math.floor(sum / 60) * 60;
  return `${newH < 10 ? `0${newH}` : newH}h${newMin < 10 ? `0${newMin}` : newMin}m`;
};

export const sumAllTimeWorked = (oldTime, newTime) => {
  const splitOldTime = oldTime ? oldTime.split(':') : ['00', '00'];
  const splitNewTime = newTime ? newTime.split(':') : ['00', '00'];

  const oldHours = splitOldTime[0];
  const newHours = splitNewTime[0];

  const oldMinute = splitOldTime[1];
  const convertNewMinuteToHoursAndMinute = dayjs('00:00', 'HH:mm')
    .add(splitNewTime[1], 'm')
    .add(oldMinute, 'm')
    .format('HH:mm');

  const splitNewMinuteAntHours = convertNewMinuteToHoursAndMinute.split(':');

  const newAllHours = +oldHours + +newHours + +splitNewMinuteAntHours[0];

  return `${newAllHours < 10 ? `0${newAllHours}` : newAllHours}:${splitNewMinuteAntHours[1]}`;
};

export const convertHoursToMinute = (time) => {
  const splitTime = time ? time.split(':') : ['00', '00'];
  const convertHours = +splitTime[0] * 60;
  const minute = +splitTime[1];
  return convertHours + minute;
};

export const validTime = (data, messageAboutInvalid = false, checkNotMore24 = true) => {
  const check = /^\d\dh\d\dm$/.test(data)
  || /^\dh\d\dm$/.test(data)
  || /^\d\dh\dm$/.test(data)
  || /^\d\d\dh\d\dm$/.test(data)
  || /^\d\d\dh\dm$/.test(data)
  || /^\dh\dm$/.test(data)
  || (+data === 0 && data !== '00' && data !== '');

  if (check && +data !== 0) {
    // console.log('data',data)
    const splitData = data.split('h');
    const hours = splitData[0];
    const minute = splitData[1].slice(0, splitData[1].length - 1);
    // console.log('hours', hours, 'minute', minute);
    const isValid = (checkNotMore24 ? +hours <= 23 : true) && minute <= 59;
    return messageAboutInvalid
      ? 'need no more than 23h59m'
      : isValid;
  }
  return messageAboutInvalid
    ? 'need "00h00m" or "0h0m"'
    : check;
};

export const timeConvertForView = (time) => {
  // console.log('time',time)
  if (isNil(time) || time === 0) {
    return '00h00m';
  }
  const allTime = time.split(':');
  return `${+allTime[0] < 10 ? `0${+allTime[0]}` : +allTime[0]}h${allTime[1]}m`;
};

export const timeConvertForMoment = (time, checkNotMore24) => {
  // конвертирует 00h00m в 00:00 что бы в моменте можно было с ним работать
  if (!isNil(time) && validTime(time, false, checkNotMore24) && +time !== 0) {
    const allTime = time.split('h');
    const hours = allTime[0];
    const min = allTime[1].replace('m', '');
    return `${hours}:${min}`;
  }
  return '00:00';
};

export const takeTimeAwayFrom = (oldTime, takeAway) => {
  const currentTime = dayjs(oldTime, 'HH:mm').format('YYYY-MM-DD');
  const subtractTime = dayjs(oldTime, 'HH:mm').subtract(takeAway, 'HH:mm');
  const newTime = dayjs(subtractTime).format('YYYY-MM-DD');

  if (dayjs(newTime).isBefore(currentTime)) {
    // console.log('currentTime', moment(newTime).isBefore(currentTime));
    return '00:00';
  }
  return dayjs(subtractTime).format('HH:mm');
};

export const parseTimeFromMinutesToHHhmmm = (time, moreThan24h) => {
  if (time === 0) {
    return '00h00m';
  }
  if (!time) return null;

  if (moreThan24h) {
    const hours = Math.floor(time / 60);
    const minutes = time - Math.floor(time / 60) * 60;

    const fulltime = `${hours < 10 ? `0${hours}` : hours}h${minutes < 10 ? `0${minutes}` : minutes}m`;

    return fulltime;
  }
  if (time / 60 > 24) {
    const hours = Math.floor(time / 60) - 24;
    const minutes = time - Math.floor(time / 60) * 60;

    const fulltime = `${hours < 10 ? `0${hours}` : hours}h${minutes < 10 ? `0${minutes}` : minutes}m`;

    return fulltime;
  }

  const hours = Math.floor(time / 60);
  const minutes = time - Math.floor(time / 60) * 60;

  const fulltime = `${hours < 10 ? `0${hours}` : hours}h${minutes < 10 ? `0${minutes}` : minutes}m`;

  return fulltime;
};

export const validStartWork = (start) => {
  if (start || start === 0) return true;
  return false;
};

export const validateStartAndFinishWork = (params = {}, { showLunchBreak }) => {
  const {
    startWork,
    lunchBreak,
    hoursWorked,
    finishWork,
    // clientWork,
    // internalWork,
  } = params || {};

  const currentStartWork = startWork || dayjs().format('HH[h]mm[m]');

  let currentFinishWork;

  if (finishWork) {
    const parsedFinishWork = finishWork;
    currentFinishWork = (parsedFinishWork !== '24h00m' && parsedFinishWork) || '00h00m';
  } else {
    currentFinishWork = dayjs().add(8, 'h').add(30, 'minute').format('HH[h]mm[m]');
    // currentFinishWork = dayjs().add(30, 'minute').format('HH[h]mm[m]');
  }

  const config = {
    // clientWork: clientWork || '00h00m',
    // internalWork: internalWork || '00h00m',
    startWork: currentStartWork,
    finishWork: currentFinishWork,
    hoursWorked: hoursWorked || '08h00m',
  };

  if (showLunchBreak) {
    config.lunchBreak = lunchBreak || '00h30m';
  }

  return config;
};

export const getInitFormStateFromStartWorkReportParams = (params, myProfile) => {
  const {
    lunchBreak,
    startWork,
    finishWork,
    hoursWorked,
    // clientWork,
    // internalWork,
  } = validateStartAndFinishWork(params, myProfile);

  const defaultTypesOfDay = ['office_work', 'bench_1_month', 'bench_over_3_month', 'bench_over_1_month'];

  return ({
    timeState: {
      startWork,
      finishWork,
      lunchBreak,
      hoursWorked,
      // clientWork,
      // internalWork,
    },
    disabledSubmit: params?.disabledSubmit ?? false,
    dailyPlanningText: params?.comment || '',
    kindOfDay: defaultTypesOfDay.includes(params?.type) ? params?.type : 'office_work',
    extraOptions: {
      dontHaveEnoughTasks: params?.dontHaveEnoughTasks || false,
      haveWorkQuestions: params?.haveWorkQuestions || false,
    },
    wasChanges: false,
    isSubmittedStartWorkRecord: params?.isSubmittedStartWorkRecord || false,
  });
};
