import _ from 'lodash';

import PublicUIConstants from '../constants/types';

const initialState = {
  isFetching: false,
  data: [],
};
export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PublicUIConstants.GET_ALL_OPEN_UI_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case PublicUIConstants.GET_ALL_OPEN_UI_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: _.get(payload, 'services', []),
      };

    case PublicUIConstants.GET_ALL_OPEN_UI_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
