import _, { toLower } from 'lodash';
import { createSelector } from 'reselect';

export const getPublicInterfacesFetching = (state) => _.get(state, 'public_interfaces.isFetching', []);

export const getOpenInterfacesList = (state) => _.get(state, 'public_interfaces.data', []);

export const getInterfaceWms = createSelector(
  getOpenInterfacesList,
  (list) => list.find((i) => toLower(i?.service_name) === 'w54'),
);
