/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { head } from 'lodash';

const initialState = {
  fetching: false,
  artifacts: [],
  fetchingSingle: false,
  artifact: {},
};

const artifactsSlice = createSlice({
  name: 'artifactsSlice',
  initialState,
  reducers: {
    CREATE_ARTIFACT_REQUEST(state) {
      state.fetching = true;
    },
    CREATE_ARTIFACT_SUCCESS(state, action) {
      state.fetching = false;
    },
    CREATE_ARTIFACT_FAILURE(state) {
      state.fetching = false;
    },
    DELETE_SINGLE_ARTIFACT_REQUEST(state) {
      state.fetching = true;
    },
    DELETE_SINGLE_ARTIFACT_SUCCESS(state, action) {
      state.fetching = false;
      state.artifact = {};
    },
    DELETE_SINGLE_ARTIFACT_FAILURE(state) {
      state.fetching = false;
    },
    UPDATE_SINGLE_ARTIFACT_REQUEST(state) {
      state.fetching = true;
    },
    UPDATE_SINGLE_ARTIFACT_SUCCESS(state, action) {
      state.fetching = false;
      state.artifact = action.payload;
    },
    UPDATE_SINGLE_ARTIFACT_FAILURE(state) {
      state.fetching = false;
    },
    GET_ARTIFACT_SUCCESS(state, action) {
      state.fetchingSingle = false;
      state.artifact = head(action.payload);
    },
    GET_ARTIFACT_FAILURE(state) {
      state.fetchingSingle = false;
    },
    GET_ARTIFACTS_REQUEST(state) {
      state.fetching = true;
    },
    GET_ARTIFACTS_SUCCESS(state, action) {
      state.fetching = false;
      state.artifacts = action.payload;
    },
    GET_ARTIFACTS_FAILURE(state) {
      state.fetching = false;
    },
    GET_ARTIFACT_REQUEST(state) {
      state.fetchingSingle = true;
    },
  },
});

export const {
  CREATE_ARTIFACT_REQUEST,
  CREATE_ARTIFACT_SUCCESS,
  CREATE_ARTIFACT_FAILURE,
  GET_ARTIFACT_REQUEST,
  GET_ARTIFACT_SUCCESS,
  GET_ARTIFACT_FAILURE,
  DELETE_SINGLE_ARTIFACT_REQUEST,
  DELETE_SINGLE_ARTIFACT_SUCCESS,
  DELETE_SINGLE_ARTIFACT_FAILURE,
  UPDATE_SINGLE_ARTIFACT_REQUEST,
  UPDATE_SINGLE_ARTIFACT_SUCCESS,
  UPDATE_SINGLE_ARTIFACT_FAILURE,
  GET_ARTIFACTS_REQUEST,
  GET_ARTIFACTS_SUCCESS,
  GET_ARTIFACTS_FAILURE,
} = artifactsSlice.actions;

export default artifactsSlice.reducer;
