import MonthlyReportConstants from './constants';

const initialState = {
  isFetching: true,
  report: {},
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        report: payload,
      };

    case MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        report: payload?.[0] || null,
      };

    case MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
