import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, Tooltip,
} from 'antd';
import { capitalize } from 'lodash';
import './user-list-styles.scss';
import UserAvatar from '../../components/UserAvatar';
import { getInitialsNameOfActor } from '../../54origins/utils54origins';

export default function ItemForListFoActorsR54({
  onClickCallback,
  label = '',
  uuid = '',
  actor = '',
  isSelected = false,
}) {
  const getPartOfText = (text, size) => (text?.length > size ? (
    <Tooltip
      title={text}
      className="user-item-tooltip"
    >
      {`${text.slice(0, size)}...`}
    </Tooltip>
  ) : text || '');

  const initials = label.split(' ')
    .map((item) => item[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  return (
    <Flex
      align="center"
      justify="flex-start"
      className={`${isSelected ? 'user-item-for-list-selected' : 'user-item-for-list'}`}
      onClick={() => onClickCallback({ value: uuid, actor, label })}
    >
      <UserAvatar
        customInitials={initials}
        className="avatar-my mr-2"
        userUUID={uuid}
        size={35}
      />
      {getPartOfText(label, 35)}
    </Flex>
  );
}

ItemForListFoActorsR54.propTypes = {
  onClickCallback: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  uuid: PropTypes.string,
  actor: PropTypes.string,
};
