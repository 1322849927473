import keyMirror from 'keymirror';

const UserTimelineProjectConstants = keyMirror({
  GET_USER_TIMELINES_REQUEST: null,
  GET_USER_TIMELINES_SUCCESS: null,
  GET_USER_TIMELINES_FAILURE: null,

  CREATE_USER_TIMELINE_REQUEST: null,
  CREATE_USER_TIMELINE_SUCCESS: null,
  CREATE_USER_TIMELINE_FAILURE: null,

  GET_USER_TIMELINE_REQUEST: null,
  GET_USER_TIMELINE_SUCCESS: null,
  GET_USER_TIMELINE_FAILURE: null,

  UPDATE_USER_TIMELINE_REQUEST: null,
  UPDATE_USER_TIMELINE_SUCCESS: null,
  UPDATE_USER_TIMELINE_FAILURE: null,

  DELETE_USER_TIMELINE_REQUEST: null,
  DELETE_USER_TIMELINE_SUCCESS: null,
  DELETE_USER_TIMELINE_FAILURE: null,
});

export default UserTimelineProjectConstants;
