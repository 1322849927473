import React from 'react';

import { Button, Modal } from 'antd';
import { capitalize } from 'lodash';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { bool, func, string } from 'prop-types';
import antNotification from '../../../../utils/antNotification';

import useAPI from '../../../../api/useAPI';

const { confirm } = Modal;

export default function DeleteVacation({
  uuid, isDisabled, deleteCallback, type,
}) {
  const { requestDeleteUserVacation, requestDeleteUserHolidays } = useAPI();

  const showConfirmDelete = (uuidVacation) => {
    confirm({
      title: `Удаление ${type === 'vacation' ? 'отпуска' : 'каникул'}`,
      icon: <ExclamationCircleFilled />,
      content: `Вы уверены, что хотите удалить ${type === 'vacation' ? 'этот отпуск' : 'эти каникулы'}?`,
      onOk() {
        switch (type) {
          case 'vacation':
            requestDeleteUserVacation(uuidVacation).then(() => {
              antNotification.success('План отпуска успешно удален.');
              deleteCallback();
            });
            break;
          case 'holidays':
            requestDeleteUserHolidays(uuidVacation).then(() => {
              antNotification.success('План каникул успешно удален.');
              deleteCallback();
            });
            break;
          default: break;
        }
      },
      onCancel() {},
    });
  };

  return (
    <Button
      onClick={() => showConfirmDelete(uuid)}
      danger
      ghost
      disabled={isDisabled}
    >
      {capitalize('удалить')}
    </Button>
  );
}

DeleteVacation.propTypes = {
  uuid: string,
  isDisabled: bool,
  deleteCallback: func,
  type: string,
};
