import React from 'react';
import { Tag } from 'antd';
import dayjs from 'dayjs';

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(isSameOrBefore);

export function getFirstAndLastDayCalendar(date) {
  const firstDayOfMonth = dayjs(date).startOf('month');
  const firstDayOfCal = firstDayOfMonth.clone().startOf('week');

  return {
    firstDayCalendar: dayjs(firstDayOfCal.toDate()).format('YYYY-MM-DD'),
    lastDayCalendar: dayjs(firstDayOfCal.toDate()).add(41, 'days').format('YYYY-MM-DD'),
  };
}

export const localConfig = {
  lang: { locale: 'ru' },
};
export const translateTypeDay = {
  dayoff: 'Отгул',
  bench: 'Бенч',
  bench_1_month: 'Бенч 1 месяц',
  bench_over_1_month: 'Бенч больше 1-го месяца',
  bench_over_3_month: 'Бенч больше 3-х месяцев',
  localHoliday: 'Местный выходной',
  projectDayOff: 'Проектный выходной',
  sickday: 'Больничный',
  vacation: 'Отпуск',
  holidays: 'Каникулы',
  office_work: 'Работа в офисе',
  remote_work: 'Работа удаленно',
  work_on_day_off: 'Работа в выходной',
  report_not_sent: 'Отчет не отправлен',
};

export const getLabelByType = (type, el) => {
  switch (type) {
    case 'office_work': return (
      <Tag color="green">
        В офисе (
        {el?.hoursWorked}
        )
      </Tag>
    );
    case 'remote_work': return (
      <Tag color="cyan">
        Удаленно (
        {el?.hoursWorked}
        )
      </Tag>
    );
    case 'work_on_day_off': return (
      <Tag color="blue">
        Работа в выходной (
        {el?.hoursWorked}
        )
      </Tag>
    );
    case 'dayoff': return <Tag color="orange">Отгул</Tag>;
    case 'holidays': return <Tag color="lime">Каникулы</Tag>;
    case 'sickday': return <Tag color="gold">Больничный</Tag>;
    case 'bench_1_month': return <Tag color="magenta">Бенч 1 месяц</Tag>;
    case 'bench_over_1_month': return <Tag color="purple">Бенч больше 1-го месяца</Tag>;
    case 'bench_over_3_month': return <Tag color="geekblue">Бенч больше 3-х месяцев</Tag>;
    case 'report_not_sent': return <Tag color="red">День был удален</Tag>;
    default: return null;
  }
};

export const colorByDayType = {
  office_work: 'green',
  remote_work: 'cyan',
  work_on_day_off: 'blue',
  dayoff: 'orange',
  holidays: 'lime',
  sickday: 'gold',
  bench_1_month: 'magenta',
  bench_over_1_month: 'purple',
  bench_over_3_month: 'geekblue',
  report_not_sent: 'red',
};

export const dayTypes = [
  {
    label: 'Работа в офисе',
    value: 'office_work',
  },
  {
    label: 'Работа удаленно',
    value: 'remote_work',
  },
  {
    label: 'Работа в выходной',
    value: 'work_on_day_off',
  },
  {
    label: 'Выходной',
    value: 'dayoff',
  },
  // {
  //   label: 'Больничный',
  //   value: 'sickday',
  // },
  {
    label: 'Местный выходной',
    value: 'localHoliday',
  },
  {
    label: 'Проектный выходной',
    value: 'projectDayOff',
  },
  // {
  //   label: 'Каникулы',
  //   value: 'holidays',
  // },
];

export const isWorkingDay = (type) => type === 'office_work'
|| type === 'remote_work'
|| type === 'work_on_day_off';

export const isNotWorkingDay = (type) => type !== 'office_work'
&& type !== 'remote_work'
&& type !== 'work_on_day_off';

export const getMinutesFromStr = (str) => parseInt(str) * 60 + (parseInt(str.slice(str.length - 3))
    || parseInt(str.slice(str.length - 2)));

export const sumTime = (totalTime, newTime) => {
  if (!newTime) {
    return totalTime;
  }
  totalTime.hours += parseInt(newTime);
  totalTime.minutes += (parseInt(newTime.slice(newTime.length - 3))
  || parseInt(newTime.slice(newTime.length - 2)));

  if (totalTime.minutes >= 60) {
    totalTime.hours++;
    totalTime.minutes -= 60;
  }
  return totalTime;
};

export const getHours = (arrOfDay, field = 'hoursWorked') => {
  const hoursObj = arrOfDay
    .reduce((acc, el) => sumTime(acc, el?.[field]), { hours: 0, minutes: 0 });
  if (hoursObj.minutes === 0) {
    return hoursObj.hours;
  }

  return Math.ceil((hoursObj.hours + hoursObj.minutes / 60) * 10) / 10;
};

export const getDayjsDateFromString = (time = null) => {
  if (!time) {
    return null;
  }
  const hours = parseInt(time, 10);
  const minutes = (parseInt(time.slice(time.length - 3), 10)
  || parseInt(time.slice(time.length - 2), 10));
  return dayjs().startOf('day').add(hours, 'hour').add(minutes, 'minute');
};

export const getArrByType = (arr, dates) => {
  const list = new Map([]);
  const workTypesOfDay = ['office_work', 'remote_work', 'work_on_day_off'];

  arr.forEach((el) => {
    const rangeDates = el?.params?.rangeDates ?? el?.rangeDates;
    const type = el?.entity_type ?? el?.type;
    const isWorkDay = workTypesOfDay.includes(type);

    if (rangeDates) {
      rangeDates.forEach((i) => {
        if (dates.includes(i)) {
          const candidate = list.get(i);
          if (candidate) {
            if (dayjs(candidate.created).isBefore(dayjs(el?.created))) {
              candidate.created = el?.created;
              candidate.type = type;
              candidate.hoursWorked = null;
              candidate.startWork = null;
              candidate.finishWork = null;
              candidate.lunchBreak = null;
              candidate.comment = el?.comment || '';
              if (isWorkDay) {
                candidate.hoursWorked = el?.hoursWorked || '08h00m';
                candidate.startWork = el?.startWork || null;
                candidate.finishWork = el?.finishWork || null;
                candidate.lunchBreak = el?.lunchBreak || null;
              }
            }
          } else {
            const newDay = {
              date: i,
              type,
              created: el?.created,
              hoursWorked: null,
              startWork: null,
              finishWork: null,
              lunchBreak: null,
              comment: el?.comment || '',
            };
            if (isWorkDay) {
              newDay.hoursWorked = el?.hoursWorked || '08h00m';
              newDay.startWork = el?.startWork || null;
              newDay.finishWork = el?.finishWork || null;
              newDay.lunchBreak = el?.lunchBreak || null;
            }
            list.set(i, newDay);
          }
        }
      });
    }
  });
  dates.forEach((day) => {
    if (!list.has(day)) {
      list.set(day, {
        date: day,
        type: 'report_not_sent',
        created: null,
      });
    }
  });
  return list;
};
