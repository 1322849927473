import React, { Fragment, useEffect, useState } from 'react';
import {isNil, path, propOr} from 'ramda';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './TagsSettingList.scss';

import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import ExcludeOrInclude from './ExcludeOrInclude';
import NormalTeg from './NormalTeg';
import { prefixSize } from './utilsFilter';
import {capitalizeFirstChar} from "../../../utils";
import keyMirror from "keymirror";

export const isResetTags = (state) => path(['EntityService', 'entityFilter', 'resetTags'], state);
export const EntityConstants = keyMirror({
  FILTER_RESET_TAGS: null,
})

// TAG STRUCTURE:
//   value - Tag value
//   label - Tag display
//   className - Tag DOM class
//
//   [
//     {
//       tags: [
//         {
//           value: 'key',
//           label: 'Display Name',
//           className: 'key_tag' (optional)
//         },
//         ...
//       ],
//       isRadio: true,
//       ...
//     },
//     ...
//   ]

// FLAGS ITEM:
// ruleOfTag - Excluded or include tag
//
// FLAGS ROW:
//   isReset - To reset all tags
//   isRow - Put tags on a separate line
//   rowText - Text above the row
//   noReset - Non resettable tag
//   isRadio - Switching mode
//   noDeselect - Repeated click does not deselect tag
//   className - Its work, if tags is row or is group

function TagsWrapper({
  children,
  isRow,
  rowText,
  className,
  customClassName,
}) {
  const { t } = useTranslation();

  if (isRow) {
    return (
      <Col
        className={
          `areas_filter-container flex-wrap
           ${customClassName} ${className}`
        }
        span={24}
      >
        {rowText && (
        <Col
          span={24}
          className={`ml-2 mt-2 pb-0 smallEntityText ${customClassName}`}
        >
          {/* {rowText} */}
          {`${capitalizeFirstChar(t(rowText))}:`}
        </Col>
        )}
        {children}
      </Col>
    );
  }

  return children;
}

function TagsSettingList({
  allTags = [],
  id,
  defaultSelectedTags = [],
  onSelectTags,
  size,
  customClassName,
}) {
  const [currentDefaultSelectedTags, setDefaultSelectedTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  // const tagsReset = useSelector(isResetTags);

  const dispatch = useDispatch();

  const handleSelectTags = (tags) => {
    if (onSelectTags) {
      onSelectTags(tags);
    }
  };

  const filterTagsByParam = (param, tagsList = allTags) => tagsList
    .filter((item) => propOr(false, param, item))
    .map(({ tags }) => tags)
    .flat();

  const toDeselect = (tag) => setSelectedTags((prev) => {
    const result = prev.filter((item) => !(item.value === tag.value && item.key === tag.key));
    handleSelectTags(result);
    return result;
  });

  const addOrUpdateStatus = (currentTag, newStatus, isUpdate) => {
    let newTags = [...selectedTags];

    if (isUpdate) {
      newTags = selectedTags.map((item) => (
        currentTag.key === item.key && currentTag.value === item.value
          ? { ...item, ruleOfTag: newStatus }
          : item));
    } else {
      newTags.push({ ...currentTag, ruleOfTag: newStatus });
    }

    handleSelectTags(newTags);
    setSelectedTags(newTags);
  };

  const toResetTags = () => {
    const exclusions = filterTagsByParam('noReset', selectedTags);

    handleSelectTags([...exclusions]);
    setSelectedTags([...exclusions]);
  };
  //
  // useEffect(() => {
  //   if (tagsReset.includes(id) && selectedTags.length > 0) {
  //     setSelectedTags([]);
  //     handleSelectTags([]);
  //     dispatch({
  //       type: EntityConstants.FILTER_RESET_TAGS,
  //       payload: [id],
  //     });
  //   }
  // }, [JSON.stringify(tagsReset)]);

  useEffect(() => {
    if (!isNil(defaultSelectedTags)
      && JSON.stringify(defaultSelectedTags) !== JSON.stringify(currentDefaultSelectedTags)
    ) {
      setDefaultSelectedTags(defaultSelectedTags);
      setSelectedTags(defaultSelectedTags);
    }
  }, [JSON.stringify(defaultSelectedTags)]);

  return (
    <Row
      className={`areas_filter-wrapper ${customClassName} ${prefixSize[size]}`}
      onClick={(e) => e?.stopPropagation()}
    >
      {allTags.map((data, idx) => {
        const isRadio = propOr(false, 'isRadio', data);
        const isRow = propOr(false, 'isRow', data);
        const rowText = propOr(false, 'rowText', data);
        const tags = propOr([], 'tags', data);
        const className = propOr('', 'className', data);
        const noDeselect = propOr(false, 'noDeselect', data);
        const isReset = propOr(false, 'isReset', data);
        const lettersLimit = get(data, 'lettersLimit', false);

        return (
          <TagsWrapper
            isRow={isRow}
            rowText={rowText}
            key={`tags_${idx}`}
            className={className}
            customClassName={customClassName}
          >
            {tags.map((item, idTag) => {
              if (item.hidden) {
                return <></>;
              }
              const key = `tag_${item.value}_${idTag}`;
              const ruleOfTag = propOr(false, 'ruleOfTag', item);
              const currentLabel = lettersLimit && item.label.length > lettersLimit
                ? `${item.label.slice(0, +lettersLimit)}...`
                : item.label;

              if (ruleOfTag) {
                return (
                  <ExcludeOrInclude
                    key={key}
                    isRadio={isRadio}
                    selectedTags={selectedTags}
                    currentTag={item}
                    size={size}
                    labelButton={currentLabel}
                    addOrUpdateStatus={addOrUpdateStatus}
                    toDeselect={toDeselect}
                  />
                );
              }

              return (
                <NormalTeg
                  key={key}
                  tags={tags}
                  noDeselect={noDeselect}
                  isReset={isReset}
                  isRadio={isRadio}
                  currentTag={item}
                  size={size}
                  isRow={isRow}
                  labelButton={currentLabel}
                  selectedTags={selectedTags}
                  lettersLimit={lettersLimit}
                  handleSelectTags={handleSelectTags}
                  setSelectedTags={setSelectedTags}
                  toDeselect={toDeselect}
                  filterTagsByParam={filterTagsByParam}
                  toResetTags={toResetTags}
                />
              );
            })}
          </TagsWrapper>
        );
      })}
    </Row>
  );
}

TagsSettingList.propTypes = {
  allTags: PropTypes.array,
  defaultSelectedTags: PropTypes.array,
  id: PropTypes.string,
  onSelectTags: PropTypes.func,
  size: PropTypes.string,
  customClassName: PropTypes.string,
};

export default TagsSettingList;

TagsWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  isRow: PropTypes.bool,
  rowText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,

  ]),
  customClassName: PropTypes.string,
};
