import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col, Divider, Flex, Modal, Row, Segmented, Tooltip, Typography,
} from 'antd';
import {
  bool, func, object, string,
} from 'prop-types';

import './startWorkCard.scss';

import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { mdiTrashCan } from '@mdi/js';
import { range } from 'lodash';
import { capitalizeFirstChar } from '../../../utils';
import { getInitFormStateFromStartWorkReportParams, daysList, benchList } from './utils';
import BaseReportWorkTimeManagmentForm from './BaseReportWorkTimeManagmentForm';
import BaseReportWorkPlanTextarea from './BaseReportWorkPlanTextarea';

const { Paragraph } = Typography;

const optionsDayOff = daysList.map(
  ({ label, key }) => ({
    label: (
      <div>
        <div className="fontSize11 text-wrap py-1">{capitalizeFirstChar(label)}</div>
      </div>
    ),
    value: key,
  }),
);

const optionsBenches = benchList.map(
  ({ label, key }) => ({
    label: (
      <div>
        <div className="fontSize11 text-wrap py-1">{capitalizeFirstChar(label)}</div>
      </div>
    ),
    value: key,
  }),
);

const benchTypes = ['bench_1_month', 'bench_over_3_month', 'bench_over_1_month'];
const workTypes = ['office_work', 'remote_work', 'work_on_day_off'];

function StartWorkCard({
  defaultDisabled = false,
  showDeleteButton,
  currentDay,
  titleSaveBtn,
  updateDayCallback,
  deleteCallback,
  isStartWorkRecord = true,
  showLunchBreak,
  defaultData = {},
  getPrevDayCallback,
}) {
  const [formState, setFormState] = useState(null);
  const [modal, contextHolder] = Modal.useModal();

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [messagePrevReport, setMessagePrevReport] = useState('');

  const setPrevReportTime = () => {
    const prevDay = getPrevDayCallback(currentDay);

    if (!prevDay) {
      setMessagePrevReport('Последний отчет не найден');
      return;
    }

    setMessagePrevReport(`Отчет от ${prevDay?.rangeDates[0]}`);
    setFormState(
      getInitFormStateFromStartWorkReportParams(prevDay, { showLunchBreak }),
    );
  };

  const resetCurrentTime = () => {
    const defaultFormStateConfig = {
      timeState: {
        hoursWorked: '00h00m',
        startWork: dayjs().format('HH[h]mm[m]'),
        finishWork: dayjs().add(8, 'hours').add(30, 'minutes').format('HH[h]mm[m]'),
        // finishWork: dayjs().add(30, 'minutes').format('HH[h]mm[m]'),
        // internalWork: '00h00m',
        // clientWork: '00h00m',
      },
      dailyPlanningText: '',
      kindOfDay: 'office_work',
      extraOptions: {
        dontHaveEnoughTasks: false,
        haveWorkQuestions: false,
      },
      wasChanges: true,
    };

    if (showLunchBreak) {
      defaultFormStateConfig.timeState.lunchBreak = '00h30m';
    }
    setFormState(defaultFormStateConfig);
  };

  const changeDayCharacteristics = (key) => {
    setFormState((prev) => ({
      ...prev,
      kindOfDay: key,
      wasChanges: true,
      disabledSubmit: false,
    }));
  };

  const onDeleteDay = () => {
    modal.warning({
      title: dayjs(currentDay).format('YYYY-MM-DD'),
      content: 'Вы уверены, что хотите удалить данные?',
      width: 'auto',
      centered: true,
      keyboard: true,
      maskClosable: true,
      closable: true,
      onOk: () => deleteCallback(),
    });
  };

  const submitDay = async () => {
    setDisabledSubmit(true);
    if (benchTypes.includes(formState?.kindOfDay)) {
      await updateDayCallback({
        kindOfDay: formState?.kindOfDay,
        wasChanges: true,
      });
    } else {
      await updateDayCallback(formState);
    }
    setDisabledSubmit(false);
  };

  useEffect(() => {
    const isWorkingDay = workTypes.includes(defaultData?.type);
    const initData = {
      ...defaultData,
      disabledSubmit: defaultDisabled,
      comment: isWorkingDay ? defaultData?.comment : '',
    };
    setFormState(
      getInitFormStateFromStartWorkReportParams(
        initData,
        { showLunchBreak },
      ),
    );
  }, [defaultData]);

  return formState && (
    <div className="w-100">
      <div className="d-flex  align-items-center w-100">
        <Row style={{ alignItems: 'center' }}>
          <Col span={16} style={{ overflow: 'hidden' }}>
            <Segmented
              value={formState?.kindOfDay}
              defaultValue="office_work"
              className="segmented_style"
              options={optionsDayOff}
              size="small"
              onChange={changeDayCharacteristics}
            />
          </Col>
        </Row>
      </div>
      {formState?.kindOfDay === 'office_work'
        ? (
          <div className="position-relative">
            <Row className="my-2">
              <Col span={10}>
                <Divider orientation="left">
                  <span className="text-secondary">Настройка времени:</span>
                </Divider>

                <Col span={24} className="mr-3">
                  <BaseReportWorkTimeManagmentForm
                    formState={formState}
                    setFormState={setFormState}
                    showLunchBreak={showLunchBreak}
                  />
                </Col>
              </Col>

              <Col span={14}>
                <Divider orientation="left">
                  <span className="text-secondary">
                    План работы
                    {isStartWorkRecord ? ' на сегодня' : ''}
                    :
                  </span>
                </Divider>

                <Col span={24} className="">
                  <BaseReportWorkPlanTextarea
                    currentDay={currentDay}
                    formState={formState}
                    setFormState={setFormState}
                    rows={4}
                  />
                  {/* <Col>
                <Row>
                  <span className="text-secondary">
                    Дополнительные опции дня:
                  </span>
                  <div>
                    <Button
                      size="small"
                      type="dashed"
                      className={`w-100 btnDanger${formState.extraOptions.dontHaveEnoughTasks ? '-selected' : '-outline'}`}
                      onClick={() => onChangeCheckboxHandler('dontHaveEnoughTasks')}
                    >
                      У меня недостаточно заданий
                    </Button>

                    <Button
                      size="small"
                      type="dashed"
                      className={`w-100 ml-2 btnDanger${formState.extraOptions.haveWorkQuestions ? '-selected' : '-outline'}`}
                      onClick={() => onChangeCheckboxHandler('haveWorkQuestions')}
                    >
                      У меня есть рабочие вопросы
                    </Button>
                  </div>
                </Row>
              </Col> */}
                </Col>
              </Col>
            </Row>
          </div>
        ) : null}
      {formState?.kindOfDay === 'bench_1_month'
      || formState?.kindOfDay === 'bench_over_3_month'
      || formState?.kindOfDay === 'bench_over_1_month' ? (
        <Row
          className="mt-2"
        >
          <Col span={24}>
            <Alert
              style={{ paddingInline: '16px', paddingBlock: '16px' }}
              message="Правила бенча"
              type="info"
              showIcon
              className="w-100"
              description={(
                <div>
                  <Paragraph style={{ marginBottom: 2 }}>
                    Если вы провели на бенче менее 30 дней, то вам следует выбрать пункт "Бенч меньше месяца".
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 2 }}>
                    От 30 до 90 дней выбирать пункт "Бенч больше месяца".
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 2 }}>
                    Пункт "Бенч больше 3-х месяцев" выбирать при длительности бенча больше 90 дней.
                  </Paragraph>
                </div>
              )}
            />
          </Col>
          <Col span={24} className="mt-2">
            Бенч:
            <Segmented
              value={formState?.kindOfDay}
              defaultValue="bench_1_month"
              className="segmented_style ml-2"
              options={optionsBenches}
              size="small"
              onChange={changeDayCharacteristics}
            />
          </Col>
        </Row>
        ) : null}
      <Flex
        justify="space-between"
        align="center"
        style={{
          width: '100%',
          marginTop: 25,
        }}
      >
        <Flex
          justify="start"
          align="center"
        >
          <Button
            className=""
            size="small"
            onClick={resetCurrentTime}
            disabled={!formState.wasChanges}
          >
            Сбросить форму
          </Button>
          <Button
            className="ml-2"
            size="small"
            onClick={setPrevReportTime}
          >
            Загрузить последний отчет
          </Button>
          {messagePrevReport ? (
            <Paragraph
              style={{
                color: 'orange',
                marginLeft: 8,
                marginTop: 14,
              }}
            >
              {messagePrevReport}
            </Paragraph>
          ) : null}
        </Flex>
        <Flex
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          {showDeleteButton ? (
            <Tooltip
              color="#cc161f"
              title={`Удалить данные ${dayjs(currentDay).format('YYYY-MM-DD')}`}
            >
              <Button
                className="btnDanger mr-3"
                onClick={onDeleteDay}
              >
                <Icon path={mdiTrashCan} size={1} />
              </Button>
            </Tooltip>
          ) : null}
          <Button
            className="btnGreen mr-1"
            onClick={submitDay}
            disabled={formState?.disabledSubmit || disabledSubmit}
          >
            {titleSaveBtn}
          </Button>
        </Flex>
      </Flex>
      {contextHolder}
    </div>
  );
}

StartWorkCard.propTypes = {
  currentDay: string,
  titleSaveBtn: string,
  updateDayCallback: func,
  isStartWorkRecord: bool,
  deleteCallback: func,
  showLunchBreak: bool,
  defaultData: object,
  showDeleteButton: bool,
  getPrevDayCallback: func,
  defaultDisabled: bool,
};

export default StartWorkCard;
