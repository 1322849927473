import VacationConstants from './constants';

const initState = {
  isFetching: false,
  isFetchingSignal: false,
  error: false,
  errorMessage: '',
  data: [],
  dataSignal: [],
};

export default (state = initState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case VacationConstants.GET_ROOT_ENTITIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case VacationConstants.GET_ROOT_ENTITIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: false,
        errorMessage: '',
      };
    }

    case VacationConstants.GET_ROOT_ENTITIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

    case VacationConstants.GET_ROOT_ENTITIES_SIGNAL_REQUEST:
      return {
        ...state,
        isFetchingSignal: true,
        error: false,
      };

    case VacationConstants.GET_ROOT_ENTITIES_SIGNAL_SUCCESS: {
      return {
        ...state,
        isFetchingSignal: false,
        dataSignal: [payload],
        error: false,
        errorMessage: '',
      };
    }

    case VacationConstants.GET_ROOT_ENTITIES_SIGNAL_FAILURE:
      return {
        ...state,
        isFetchingSignal: false,
        error: true,
        errorMessage: payload.error_message,
      };

    default:
      return state;
  }
};
