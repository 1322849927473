import { mdiArrowLeftBold, mdiArrowRightBold, mdiCalendarMonthOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { func, string } from 'prop-types';
import {
  Col, DatePicker, Modal, Row, Tooltip,
} from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { capitalizeFirstChar } from '../../../../utils';

const dayNames = {
  0: 'Воскресенье',
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
};

function DaySwitch({
  changeCurrentDayCallback,
  customClassName = 'wrapper-switcher-day',
  disableModal,
  defaultDay,
  children,
}) {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDay, changeCurrentDay] = useState(null);

  const toggleModal = () => {
    if (!disableModal) {
      setIsModalVisible(!isModalVisible);
    }
  };

  const changeDay = (param) => {
    if (param === 'up') {
      const day = dayjs(currentDay).add(1, 'day').format('YYYY-MM-DD');
      changeCurrentDayCallback(day);
      changeCurrentDay(day);
    }

    if (param === 'down') {
      const day = dayjs(currentDay).subtract(1, 'day').format('YYYY-MM-DD');
      changeCurrentDayCallback(day);
      changeCurrentDay(day);
    }
  };

  const changeDayFromInput = (date) => {
    if (!date) return;

    const day = dayjs(date).format('YYYY-MM-DD');

    changeCurrentDayCallback(day);
    changeCurrentDay(day);
  };

  useEffect(() => {
    changeCurrentDay(defaultDay || dayjs().format('YYYY-MM-DD'));
  }, [defaultDay]);

  return (
    <div className={customClassName}>
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
        }}
      >
        <div
          aria-hidden
          onClick={() => changeDay('down')}
          className="arrow-wrapper arrow-wrapper__arrow"
        >
          <Icon
            path={mdiArrowLeftBold}
            className="arrow-wrapper__icon"
            size={1.3}
          />
        </div>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {disableModal
            ? (
              <Row
                id="TooltipDateBtn"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h5
                  aria-hidden
                  className="h5InDateBtn"
                  onClick={toggleModal}
                  style={{
                    margin: 0,
                    fontSize: '20px',
                  }}
                >
                  {capitalizeFirstChar(dayjs(currentDay)
                    .format('MMMM DD'))}
                  {', '}
                  {dayNames[dayjs(currentDay).day()]}
                </h5>
                <Icon
                  path={mdiCalendarMonthOutline}
                  onClick={toggleModal}
                  className="ml-1"
                  size={1.3}
                />
              </Row>
            )
            : (
              <Tooltip
                trigger="hover"
                title={capitalizeFirstChar(t('wms.labels.watch_weekly_report', 'watch weekly report'))}
              >
                <Row
                  onClick={toggleModal}
                  id="TooltipDateBtn"
                  className="hoverDateBtn p-4"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <h5
                    aria-hidden
                    className="h5InDateBtn"
                    style={{
                      margin: 0,
                      fontSize: '20px',
                    }}
                  >
                    {capitalizeFirstChar(dayjs(currentDay)
                      .format('MMMM DD'))}
                    {', '}
                    {dayNames[dayjs(currentDay).day()]}
                  </h5>
                  <Icon
                    path={mdiCalendarMonthOutline}
                    onClick={toggleModal}
                    className="ml-1"
                    size={1.3}
                  />
                </Row>
              </Tooltip>
            )}
          <DatePicker
            clearIcon={false}
            value={dayjs(currentDay)}
            onChange={changeDayFromInput}
            style={{ marginLeft: 15 }}
          />
        </Col>

        {!disableModal && (
          <Modal
            title={capitalizeFirstChar(t('wms.labels.daily_plan_week', 'Daily plan and report calendar'))}
            open={isModalVisible}
            destroyOnClose
            width={1650}
            blockPropagation
            footer={null}
            handleCancel={toggleModal}
          >
            {children}
          </Modal>
        )}
        <div
          onClick={() => changeDay('up')}
          className="arrow-wrapper arrow-wrapper__arrow"
        >
          <Icon
            path={mdiArrowRightBold}
            className="arrow-wrapper__icon"
            size={1.3}
          />
        </div>
      </div>

    </div>
  );
}

DaySwitch.propTypes = {
  actorMainDailyPlan: string,
  changeCurrentDayCallback: func,
};

export default memo(DaySwitch);
