import React, { useMemo } from 'react';

import {
  AutoSizer, List,
} from 'react-virtualized';

import { Button, Flex } from 'antd';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiCrown } from '@mdi/js';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import useActorsR54WithPagination from '../../../../actors/hooks/useActorsR54WithPagination';

export default function AssigningUsersForDepartments({
  selected = [],
  onUserSelect,
}) {
  const {
    actors, total, config, setConfig,
    onChangeSearch,
    valueSearch,
  } = useActorsR54WithPagination();

  const filteredActors = useMemo(
    () => actors
      .filter((actor) => !selected.includes(actor.actor)),
    [JSON.stringify(actors), JSON.stringify(selected)],
  );

  const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
    const { limit = 100, offset = 0 } = config;

    if (scrollTop + clientHeight >= scrollHeight - 400
          && setConfig) {
      const newOffset = offset + limit;
      if (newOffset < total) {
        setConfig((prev) => ({
          ...prev,
          offset: newOffset,
        }));
      }
    }
  };

  const rowRenderer = ({ index, style, isScrolling }) => {
    if (filteredActors[index] === undefined && isScrolling) {
      return (
        <div key={index} style={style}>
          Scrolling...
        </div>
      );
    }

    const {
      value, label,
    } = filteredActors[index];

    return (
      <Flex
        vertical
        align="center"
        key={value}
        style={{ ...style }}
        onClick={() => onUserSelect(filteredActors[index])}
      >
        <Flex
          justify="space-between"
          className="w-full"
        >
          {label.length > 20 ? `${label.split(20)}...` : (
            <span
              style={{
                maxWidth: 380,
              }}
            >
              {label}
            </span>
          )}
          <Flex
            className="text-blue-700 hover:text-white hover:bg-blue-700 rounded-full !p-0 mr-4 cursor-pointer"
            style={{
              border: '1px solid blue',
              height: 22,
              width: 22,
            }}
          >
            <Icon path={mdiChevronRight} size={1} />
          </Flex>
        </Flex>
        <div
          className="bg-gray-200 w-full mt-3.5"
          style={{ height: 1 }}
        />
      </Flex>
    );
  };

  return (
    <Flex>
      <Flex
        vertical
        className="rounded p-2"
        style={{
          height: 420,
          border: '1px dashed gray',
        }}
      >
        <AntDesignSearchBox54origins
          maxWidth={350}
          valueSearch={valueSearch}
          onSearch={onChangeSearch}
        />

        <div
          className="bg-gray-200 w-full mt-3.5 mb-1"
          style={{ height: 1 }}
        />
        <AutoSizer
          className="w-100 h-full mt-2 mb-2 pb-6"
        >
          {({ width, height }) => (
            <List
              height={350}
              rowHeight={50}
              width={width}
              rowCount={filteredActors?.length || 0}
              onScroll={handleScroll}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      </Flex>
    </Flex>
  );
}

AssigningUsersForDepartments.propTypes = {

};
