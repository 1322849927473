import React, { useEffect, useState } from 'react';
import { array, string } from 'prop-types';
import { Tag } from 'antd';
import dayjs from 'dayjs';

export default function VacationTimeStatus({
  rangeDates = [], start_date, end_date, status,
}) {
  const [tagData, setTagData] = useState({});

  useEffect(() => {
    const today = dayjs();
    if (rangeDates?.includes(today.format('YYYY-MM-DD'))) {
      setTagData({
        color: 'green',
        text: 'Текущий',
      });
    } else if (today.isSameOrBefore(dayjs(start_date))) {
      setTagData({
        color: 'blue',
        text: 'Предстоящий',
      });
    } else if (today.isSameOrAfter(dayjs(end_date))) {
      setTagData({
        color: 'grey',
        text: 'Прошедший',
      });
    }
  }, [JSON.stringify(rangeDates)]);

  return status === 'approved' && (
    <Tag
      color={tagData?.color}
    >
      {tagData?.text}
    </Tag>
  );
}

VacationTimeStatus.propTypes = {
  start_date: string,
  end_date: string,
  rangeDates: array,
};
