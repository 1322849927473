import dayjs from 'dayjs';
import { capitalizeFirstChar } from '../../../utils';

export const defaultConfig = {
  startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
};

export const allTagsForStatisticsTable = ({ showBannedTag }) => [
  {
    tags: [
      {
        key: 'all',
        label: capitalizeFirstChar('all'),
      },
    ],
    isReset: true,
  },
  {
    tags: [
      {
        key: 'report',
        value: 'report',
        label: capitalizeFirstChar('report'),
        className: 'report-tag',
        ruleOfTag: 'off',
      },
    ],
    isRadio: true,
  },
  {
    tags: [
      {
        key: 'internal',
        value: 'internal',
        label: capitalizeFirstChar('internal'),
        className: 'internal-tag',
        ruleOfTag: 'off',
      },
    ],
    isRadio: true,
  },
  {
    tags: [
      {
        key: 'banned',
        value: 'banned',
        label: capitalizeFirstChar('banned'),
        className: 'banned-tag',
        ruleOfTag: 'off',
        hidden: !showBannedTag,
      },
    ],
    isRadio: true,
  },
];
