import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getProfileInfo, isRootOrAdmin } from '../redux/profile/selectors';

function ShortLinksRedirect() {
  const goToDefaultPage = () => window.location.replace(`${window.location.origin}`);

  const isUserRootOrAdmin = useSelector(isRootOrAdmin);
  const userInfo = useSelector(getProfileInfo);

  const getLink = () => {
    const arrOfRout = window.location.pathname.split('=');
    const pathForEntity = arrOfRout[0];
    const entityUUID = arrOfRout[1];

    switch (pathForEntity) {
      case '/s/v':
        if (isUserRootOrAdmin) {
          window.location.replace(`${window.location.origin}/admin/vacations?v=${entityUUID}`);
        } else {
          window.location.replace(`${window.location.origin}/deliverydashboard?v=${entityUUID}`);
        }
        break;
      default:
        goToDefaultPage();
        break;
    }
  };

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      getLink();
    }
  }, [userInfo]);

  return null;
}

export default ShortLinksRedirect;
