import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import './Logo.scss';

import { bool } from 'prop-types';
import logoIconSvg from '../../assets/icons/logo-icon-2.svg';
import { APP_LABEL } from '../../api/app_config';
import { capitalizeFirstChar } from '../../utils';

function Logo({ isSidebarCollapsed }) {
  const style = useMemo(() => ({ marginRight: isSidebarCollapsed ? 0 : 8 }), [isSidebarCollapsed]);
  return (
    <Link to="/deliverydashboard" className="logo">
      <img
        className="logo-icon"
        src={logoIconSvg}
        alt={APP_LABEL}
        style={style}
      />
      {!isSidebarCollapsed && (
      <span className="logo-text">
        <span>{capitalizeFirstChar(APP_LABEL)}</span>
      </span>
      )}
    </Link>
  );
}
export default Logo;

Logo.propTypes = {
  isSidebarCollapsed: bool,
};
