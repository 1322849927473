import { addItem, deleteItem, updateItem } from '../../utils/modifier';
import UserVacationConstants from './constants';

const initState = {
  isFetching: false,
  total: 0,
  totalAll: 0,
  list: [],
  listAll: [],
  prev: [],
  current: [],
  next: [],
  single: null,
  error: false,
  errorMessage: '',
  isLoaded: false,
};

export default (state = initState, action = {}) => {
  const { type, payload, total } = action;

  switch (type) {
    // GET ALL USER VACATIONS AND HOLIDAYS
    case UserVacationConstants.GET_ALL_USER_VACATIONS_REQUEST:
      return {
        ...state,
        isFetchingAllList: true,
        error: false,
      };

    case UserVacationConstants.GET_ALL_USER_VACATIONS_SUCCESS: {
      return {
        ...state,
        isFetchingAllList: false,
        listAll: payload,
        totalAll: total || 0,
        error: false,
        errorMessage: '',
      };
    }

    case UserVacationConstants.GET_ALL_USER_VACATIONS_FAILURE:
      return {
        ...state,
        isFetchingAllList: false,
        totalAll: 0,
        error: true,
        errorMessage: payload.error_message,
      };

    // GET USER VACATIONS
    case UserVacationConstants.GET_USER_VACATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserVacationConstants.GET_USER_VACATIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: payload,
        total: total || 0,
        error: false,
        errorMessage: '',
      };
    }

    case UserVacationConstants.GET_USER_VACATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        total: 0,
        error: true,
        errorMessage: payload.error_message,
      };

      // CREATE USER VACATION
    case UserVacationConstants.CREATE_USER_VACATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserVacationConstants.CREATE_USER_VACATION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserVacationConstants.CREATE_USER_VACATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

      // UPDATE USER VACATION
    case UserVacationConstants.UPDATE_USER_VACATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserVacationConstants.UPDATE_USER_VACATION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserVacationConstants.UPDATE_USER_VACATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

      // DELETE USER VACATION
    case UserVacationConstants.DELETE_USER_VACATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UserVacationConstants.DELETE_USER_VACATION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
      };
    }

    case UserVacationConstants.DELETE_USER_VACATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

    default:
      return state;
  }
};
