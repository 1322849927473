import React, { useState } from 'react';
import {
  Button,
  Flex, Input, Modal, Segmented,
  Upload,
  message,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { func, string } from 'prop-types';
import { get, head } from 'lodash';
import { createArtifact } from '../../../../redux/departments/actions/artifactsActions';

const { TextArea } = Input;
const { Dragger } = Upload;

const options = [{
  label: 'Инструкция',
  value: 'instruction',
}, {
  label: 'Файл',
  value: 'file',
}, {
  label: 'Расписание',
  value: 'schedule',
}];

export default function AddArtifactButton({
  departmentUUID = '',
  onCreateArtifactCallback,
}) {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedType, setSelectedType] = useState('instruction');

  const [isOpen, setIsOpen] = useState(false);

  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);

  const props = {
    name: 'file',
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onCreateArtifact = async () => {
    const newArtifact = {
      entity_type: selectedType,
      params: {
        status: 'active',
        description,
        title,
      },
    };

    if (selectedType === 'instruction' && selectedType === 'schedule') {
      newArtifact.params.description = description;
    }

    onCloseModal();
    dispatch(createArtifact(departmentUUID, newArtifact))
      .then((res) => {
        if (onCreateArtifactCallback) {
          onCreateArtifactCallback(get(head(res), 'uuid', ''));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Button
        size="small"
        onClick={onOpenModal}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Создать артефакт
      </Button>
      <Modal
        open={isOpen}
        title="Создание артефакта"
        onCancel={onCloseModal}
        footer={null}
        centered
        width={600}
        destroyOnClose
      >
        <Flex vertical className="mb-3">
          <span>
            Название
          </span>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Flex>
        <Flex align="center" className="mb-3">
          <span className="mr-1">Тип</span>
          <Segmented
            options={options}
            value={selectedType}
            onChange={setSelectedType}
          />
        </Flex>
        {selectedType === 'instruction' && (
        <Flex
          vertical
          className="mb-3"
        >
          <span>
            Инструкция
          </span>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            row={4}
          />
        </Flex>
        )}
        {selectedType === 'schedule'
        && (
        <Flex
          vertical
          className="mb-3"
        >
          <span>
            Расписание
          </span>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            row={4}
          />
        </Flex>
        )}
        {selectedType === 'file' && (
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Щелкните или перетащите файл в эту область для загрузки
          </p>
          <p className="ant-upload-hint">
            Поддерживается одиночная или массовая загрузка.
            Строго запрещено загружать данные компании или другие
            запрещенные файлы.
          </p>
        </Dragger>
        )}
        <Flex
          className="mt-3"
          justify="end"
        >
          <Button
            className="mr-2 !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white"
            onClick={onCloseModal}
          >
            Отмена
          </Button>
          <Button
            className="!border-green-500 text-green-500 hover:bg-green-500 hover:!text-white"
            onClick={onCreateArtifact}
          >
            Создать
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

AddArtifactButton.propTypes = {
  departmentUUID: string,
  onCreateArtifactCallback: func,
};
