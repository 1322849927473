import React from 'react';
import { ConfigProvider } from 'antd';
import { element } from 'prop-types';

function ThemeProvider({ children }) {
  return (
    <ConfigProvider
      theme={{ token: { fontFamily: 'Ubuntu' } }}
    >
      {children}
    </ConfigProvider>
  );
}
export default ThemeProvider;

ThemeProvider.propTypes = { children: element };
