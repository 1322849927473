import _ from 'lodash';
import { propOr } from 'ramda';

import { createSelector } from '@reduxjs/toolkit';

export const getProfileInfo = (state) => _.get(state, 'profile.info');

export const isRootOrAdmin = createSelector(
  getProfileInfo,
  (item) => propOr(false, 'is_admin', item) || propOr(false, 'root', item),
);

export const isAdmin = createSelector(getProfileInfo, (item) => propOr(false, 'is_admin', item));

export const isRoot = createSelector(getProfileInfo, (item) => propOr(false, 'root', item));

export const getProfileFetching = (state) => _.get(state, 'profile.isFetching', false);

export const getAccess = (state) => _.get(state, 'profile.info.access', false);
