import React, { useState } from 'react';

import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Segmented,
} from 'antd';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import { SendOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import cn from 'classnames';
import dayjs from 'dayjs';

import humanizeDuration from 'humanize-duration';
import {
  bool, func, object, string,
} from 'prop-types';
import ru from 'date-fns/locale/ru';
import antNotification from '../../../../utils/antNotification';
import { getProfileInfo } from '../../../../redux/profile/selectors';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';
import { daysToMilliseconds, diffDays } from '../../../../utils/dateTimeUtils';
import useAPI from '../../../../api/useAPI';
import { getVacationStorageUUID } from '../../../../redux/storages/selectors';

const { TextArea } = Input;

export default function AddHolidaysForm({
  currentActor,
  initValues,
  form,
  isEditMode,
  uuid,
  onCancelModal,
  saveCallback,
}) {
  const {
    requestCreateUserHolidays,
    requestUpdateUserHolidays,
  } = useAPI();

  const isEditDisabled = false;

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const myProfileData = useSelector(getProfileInfo);

  const [startDate, setStartDate] = useState(
    initValues.start_date ? new Date(initValues.start_date) : null,
  );
  const [endDate, setEndDate] = useState(
    initValues.start_date ? new Date(initValues.end_date) : null,
  );

  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('custom');

  const optionsTemplateFilter = [{
    label: 'Пользовательский',
    value: 'custom',
  },
  {
    label: 'Западный',
    value: 'western',
  },
  {
    label: 'Российский',
    value: 'russian',
  }];

  const onSelectTemplate = (template) => {
    switch (template) {
      case 'western': {
        setSelectedTemplate(template);
        setStartDate(new Date(2023, 11, 23));
        setEndDate(new Date(2024, 0, 1));
        break;
      }
      case 'russian': {
        setSelectedTemplate(template);
        setStartDate(new Date(2023, 11, 30));
        setEndDate(new Date(2024, 0, 8));
        break;
      }
      default: {
        setSelectedTemplate('custom');
        setStartDate();
        setEndDate();
        break;
      }
    }
  };

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const status = Form.useWatch('status', { form, preserve: true });

  const onReset = () => {
    form.resetFields();
    onCancelModal();
  };

  const onFinish = (values) => {
    const config = {
      params: {
        ...values,
        status,
        start_date: startDate,
        end_date: endDate,
        rangeDates: enumerateDaysBetweenDates(startDate, endDate),
      },
    };

    if (!isEditMode) {
      if (currentActor !== myProfileData.uuid) {
        config.actor = currentActor;
      }
      config.params.actorUuid = currentActor;
      requestCreateUserHolidays(vacationStorageUUID, config).then(() => {
        antNotification.success(
          'Каникулы запланированы и отправлены на рассмотрение.',
        );
        saveCallback();
        onReset();
      });
    } else {
      requestUpdateUserHolidays(uuid, config.params).then(() => {
        antNotification.success('Каникулы были успешно изменен.');
        saveCallback();
        onCancelModal();
      });
    }
  };

  const onSaveAndSendRequest = () => {
    setIsDisabled(true);
    form.setFieldValue('status', 'pending');
    form.submit();
  };

  const diffDate = daysToMilliseconds(diffDays(startDate, endDate));

  return (
    <Form
      form={form}
      name="add-timeline-status-form"
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      initialValues={{
        start_date: null,
        end_date: null,
        status: 'created',
        ...(initValues || {}),
      }}
      disabled={isEditDisabled}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Alert
            message={(
              <>
                Длина каникул варьируется в зависимости от вашего рабочего проекта.
              </>
                  )}
            type="info"
            showIcon
          />
        </Col>

        <Col span={24}>
          <Row
            className="text-[#777]"
            style={{
              marginTop: -4,
              display: 'flex',
              alignItems: 'center',
              marginBottom: 7,
            }}
          >
            <span>Шаблоны</span>
            <Segmented
              style={{
                marginLeft: 6,
              }}
              options={optionsTemplateFilter}
              value={selectedTemplate}
              onChange={onSelectTemplate}
            />
          </Row>
          <div style={{ marginBottom: 4 }}>
            <label
              htmlFor="add-timeline-status-form_dates"
              className="ant-form-item-required text-xs text-[#777]"
              title="Тип"
            >
              <span style={{ color: '#ff4d4f', paddingRight: 4 }}>
                *
              </span>
              Дата начала / Дата окончания
            </label>
          </div>

          <DatePicker
            selected={startDate}
            onChange={onChangeDate}
            startDate={startDate}
            endDate={endDate}
            inline
            monthsShown={4}
            selectsRange
            locale={ru}
            maxDate={undefined}
          />
          <div className={cn({ hidden: !startDate || !endDate })}>
            <Alert
              message={(
                <p className="m-0 mt-1">
                  Вы планируете каникулы с
                  <span className="text-green-600 font-medium mx-2">{dayjs(startDate).format('DD MMMM YYYY')}</span>
                  по
                  <span className="text-green-600 font-medium mx-2">{dayjs(endDate).format('DD MMMM YYYY')}</span>
                  они составляют
                  <span className="text-green-600  font-medium mx-3">
                    {humanizeDuration(diffDate, { units: ['d'], round: true, language: 'ru' })}
                  </span>
                </p>
              )}
              type="info"
            />
          </div>
        </Col>

        <Col span={24} style={{ marginTop: -10 }}>
          <Form.Item name="comment" label="Комментарий">
            <TextArea placeholder="Введите комментарий" rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{ margin: 0 }}>
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button disabled={false} onClick={() => onCancelModal()}>
            Отмена
          </Button>
          <Button
            type="primary"
            onClick={onSaveAndSendRequest}
            disabled={!startDate || !endDate || isEditDisabled || isDisabled}
            icon={<SendOutlined />}
          >
            {capitalize(`${isEditMode ? 'Изменить' : 'Создать'} и отправить на рассмотрение`)}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

AddHolidaysForm.propTypes = {
  initValues: object,
  form: object,
  isEditMode: bool,
  uuid: string,
  onCancelModal: func,
  saveCallback: func,
  currentActor: string,
};
