// noinspection JSValidateTypes
import _ from 'lodash';
import Cookies from 'universal-cookie';

import { history } from '../routers/history';

class AuthHelper {
  static getToken() {
    const cookies = new Cookies();

    return cookies.get('Entity');
  }

  static access() {
    const access = localStorage.getItem('access') || false;
    return !!access;
  }

  static authorizedAccess(data) {
    localStorage.setItem('access', data);
  }

  static isAuthorised() {
    const cookies = new Cookies();
    const authToken = cookies.get('Entity');
    return !!(authToken && authToken !== 'undefined');
  }

  static removeSession() {
    try {
      const cookies = new Cookies();
      const allCookies = cookies.getAll();
      _.map(allCookies, (value, key) => {
        if (key === 'Entity') {
          cookies.remove('Entity', { path: '/login' });
        }
      });
    } catch (e) {
      // do nothing
    }
  }

  static clearCookies() {
    try {
      const cookies = new Cookies();
      const allCookies = cookies.getAll();

      _.keys(allCookies).forEach((key) => cookies.remove(key, { path: '/' }));
    } catch (e) {
      // console.log('removeSessionToken Error');
    }
  }

  static removeSessionToken(name) {
    try {
      const cookies = new Cookies();
      const allCookies = cookies.getAll();
      _.map(allCookies, (value, key) => {
        if (key === name) {
          cookies.remove('Entity', { path: '/' });
        }
      });
    } catch (e) {
      // console.log('removeSessionToken Error');
    }
  }

  static logout() {
    try {
      this.clearCookies();

      const currentLocale = localStorage.getItem('locale') || 'en';

      localStorage.clear();
      localStorage.setItem('locale', currentLocale);

      history.push('/login');
    } catch (e) {
      // console.log('Error');
    }
  }
}

export default AuthHelper;
