import { mdiMinus, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import {
  convertHoursToMinute,
  timeConvertForMoment,
  timeConvertForView,
} from './utils';

function AddTimeButtons({
  disableAddTimeBtnClick,
  useCircleTime = false,
  enableAddBtn,
  times,
  small = false,
  disabled,
  customClass,
  currentTime,
  saveTimeCallback,
}) {
  const addTime = (newMinutes) => {
    const oldTime = timeConvertForMoment(currentTime);
    const newTime = timeConvertForView(dayjs(oldTime, 'HH:mm').add(newMinutes, 'm').format('HH:mm'));
    return newTime;
  };

  const takeTime = (newMinutes) => {
    const oldTime = timeConvertForMoment(currentTime);
    if (+convertHoursToMinute(oldTime) < +newMinutes && !useCircleTime) {
      return '00h00m';
    }
    const newTime = timeConvertForView(dayjs(oldTime, 'HH:mm').subtract(newMinutes, 'm').format('HH:mm'));
    return newTime;
  };

  const addTimeByButton = (e) => {
    if (!enableAddBtn) {
      e.stopPropagation();
    }
    e.preventDefault();

    let newTime;
    switch (e.currentTarget.id) {
      case '-5': newTime = takeTime('5'); break;
      case '5': newTime = '00h05m'; break;
      case '+5': newTime = addTime('5'); break;
      case '-15': newTime = takeTime('15'); break;
      case '15': newTime = '00h15m'; break;
      case '+15': newTime = addTime('15'); break;
      case '-30': newTime = takeTime('30'); break;
      case '30': newTime = '00h30m'; break;
      case '+30': newTime = addTime('30'); break;
      case '45': newTime = '00h45m'; break;
      case '-60': newTime = takeTime('60'); break;
      case '60': newTime = '01h00m'; break;
      case '+60': newTime = addTime('60'); break;
      case '2h': newTime = '02h00m'; break;
      case '4h': newTime = '04h00m'; break;
      case '-4h': newTime = takeTime('240'); break;
      case '+4h': newTime = addTime('240'); break;
      case '8h': newTime = '08h00m'; break;
      case '-8h': newTime = takeTime('480'); break;
      case '+8h': newTime = addTime('480'); break;
      default: newTime = ''; break;
    }
    if (newTime) {
      saveTimeCallback(newTime);
    }
  };

  return (
    <>
      {times.map((({ type, number }) => {
        if (type === 'normal') {
          return (
            <button
              key={number}
              className="button-time-number"
              id={number}
              type="button"
              onClick={addTimeByButton}
              disabled={disabled}
            >
              {number}
            </button>
          );
        }
        return (
          <div
            key={number}
            className={`button-time-wrapper${small ? '-small' : ''} ${customClass}`}
          >
            <button
              className={`button-time-wrapper__button-left${small ? '-small' : ''} ${customClass}`}
              id={`-${number}`}
              type="button"
              onClick={addTimeByButton}
              disabled={disabled}
            >

              <Icon path={mdiMinus} size={0.6} />
            </button>

            <button
              className={`button-time-wrapper__title w-auto ${customClass}`}
              type="button"
              id={number}
              onClick={addTimeByButton}
              disabled={disabled || disableAddTimeBtnClick}
            >
              <span className="text-no-wrap">{number}</span>
            </button>

            <button
              id={`+${number}`}
              onClick={addTimeByButton}
              type="button"
              className={`button-time-wrapper__button-right${small ? '-small' : ''} ${customClass}`}
              disabled={disabled}
            >

              <Icon className={`${customClass}`} path={mdiPlus} size={0.6} />
            </button>
          </div>
        );
      }))}
    </>
  );
}

AddTimeButtons.propTypes = {
  small: PropTypes.bool,
  customClass: PropTypes.string,
  useCircleTime: PropTypes.bool,
  enableAddBtn: PropTypes.bool,
  times: PropTypes.array,
  disabled: PropTypes.bool,
  currentTime: PropTypes.string,
  saveTimeCallback: PropTypes.func,
};

export default React.memo(AddTimeButtons);
