import ProfileConstants from './constants';

const initialState = {
  isFetching: false,
  info: {},
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ProfileConstants.GET_PROFILE_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ProfileConstants.GET_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        info: payload,
      };

    case ProfileConstants.GET_PROFILE_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
