import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import { Col, Tooltip } from 'antd';
import { get, isNil } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { iconSize } from './utilsFilter';
import {capitalizeFirstChar} from "../../../utils";

export default function NormalTeg({
  currentTag,
  labelButton,
  size,
  isRow,
  toDeselect,
  selectedTags,
  filterTagsByParam,
  isRadio,
  toResetTags,
  tags,
  noDeselect,
  isReset,
  handleSelectTags,
  setSelectedTags,
  lettersLimit,
}) {
  const { t } = useTranslation();

  const disabled = !isNil(currentTag.disabled) && currentTag.disabled;

  const isActive = (tag) => selectedTags
    .map((item) => ({ value: item.value, key: get(item, 'key', null) }))
    .some((item) => item.key === get(tag, 'key', null) && item.value === tag.value);

  const checked = isActive(currentTag)
    || (isReset && selectedTags.filter((i) => i !== 'noReset').length === 0);

  const toSelect = (tag, deselectTags) => setSelectedTags((prev) => {
    let result;
    if (deselectTags) {
      result = [...prev].filter(
        (item) => !deselectTags.some(({ value }) => item.value === value),
      );
      result = [...result, tag];
    } else {
      result = [...prev, tag];
    }

    handleSelectTags(result);
    return result;
  });

  const selectOrDeselectSubSection = (tag, tags) => {
    const subTags = get(tag, 'subSectionTags', []);

    return setSelectedTags((prev) => {
      const isSelectedAllSubTag = subTags
        .every((i) => prev.some((e) => e.value === i && tag.key === e.key));

      let newList = prev.filter((p) => subTags
        .every((e) => p.value !== e));
        // .every((e) => p.value !== e && p.key === tag.key));

      if (!isSelectedAllSubTag) {
        const section = tags
          .filter((i) => subTags.some((e) => i.value === e))
          .map((i) => (i?.ruleOfTag ? { ...i, ruleOfTag: 'included' } : i));
        newList = newList.concat(section);
      }
      handleSelectTags(newList);
      return newList;
    });
  };

  const handleChange = () => {
    if (get(currentTag, 'parentSubSection', '')) {
      return selectOrDeselectSubSection(currentTag, tags);
    }

    if (isReset) {
      return toResetTags();
    }

    if (isActive(currentTag)) {
      if (!noDeselect) {
        toDeselect(currentTag);
      }
    } else if (isRadio) {
      toSelect(currentTag, tags);
    } else {
      toSelect(currentTag);
    }
  };

  const filterComponent = (
    <Tooltip title={lettersLimit && currentTag.label.length > lettersLimit ? currentTag.label : ''}>
      {currentTag?.titleHeader && <div className="switch-title-header">{currentTag?.titleHeader}</div>}
      <label
        className={
            `${get(currentTag, 'className')}
             ${checked ? 'active' : 'grayText'}
            ${disabled ? 'disableTag' : ''} `
            }
        style={{ paddingLeft: 4 }}
      >
        {checked
          ? <Icon path={mdiCheck} size={iconSize[size]} />
          : currentTag.icon && <Icon path={currentTag.icon} size={iconSize[size]} />}

        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          style={{ marginLeft: -2 }}
        />
        <span className="wrapper-text-tag">{capitalizeFirstChar(t(labelButton))}</span>
      </label>
    </Tooltip>
  );

  if (!isRow) {
    return (
      <Col className="areas_filter-container position-relative">
        {filterComponent}
      </Col>
    );
  }

  return filterComponent;
}
