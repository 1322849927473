import React from 'react';
import { Alert, Card } from 'antd';

import StartWorkRoot from './StartWorkRoot';

import useCheckDailyReport from '../../../hooks/useCheckDailyReport';

function StartWorkRecordTaskCard({
  successTask,
}) {
  const { hasWorkRecord } = useCheckDailyReport();

  const renderCard = (
    <Alert
      type={hasWorkRecord ? 'success' : 'warning'}
      showIcon
      className="w-80"
      message={hasWorkRecord ? 'Ежедневный отчёт отправлен!' : 'Вы не отправили ежедневный отчёт!'}
      description={<StartWorkRoot />}
    />
  );

  if (hasWorkRecord && successTask) return renderCard;
  if (!hasWorkRecord && !successTask) return renderCard;

  return null;
}

export default StartWorkRecordTaskCard;
