import Reports54ActorsConstants from './constants';

const initialState = {
  isFetching: false,
  actors: [],
  total: 0,
};

export default (state = initialState, action = {}) => {
  const { type, payload, total } = action;
  switch (type) {
    case Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        actors: payload,
        total,
      };

    case Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_FAILURE:
      return {
        ...state,
        isFetching: false,
        total: 0,
      };

    default:
      return state;
  }
};
