import React, { useEffect, useMemo, useState } from 'react';
import { object, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Popover, Row, Segmented, Table, Tag, Typography,
} from 'antd';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import AntViewFiles from '../../../components/commonComponents/AntViewFiles';
import { entityRead } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import DaysTable from './DaysTable';
import DisciplinaryReport from './DisciplinaryReport';

const {
  Paragraph,
} = Typography;

const optionTabs = [
  {
    label: 'Дисциплинарный отчет',
    value: 'disciplinary',
  },
  {
    label: 'История отчета',
    value: 'report',
  },
  {
    label: 'История дней',
    value: 'days',
  },
];

function TypeTag({ status }) {
  switch (status) {
    case 'auto': return (
      <Tag color="#87d068">
        Автоматический
      </Tag>
    );
    case 'submitted': return (
      <Tag color="#108ee9">
        Пользовательский
      </Tag>
    );
    default: return null;
  }
}

export default function ExpandedMonthlyReport({ report, actor, month }) {
  const dispatch = useDispatch();

  const rootPartitionUuid = useSelector(getDeliveryPartition);

  const [statistics, setStatistics] = useState(report?.params?.statistics ?? {});
  const [filesList, setFiles] = useState([]);

  const [selecetedTab, setSelectedTab] = useState('disciplinary');

  const dataSource = useMemo(() => [
    {
      ...(report?.params?.statistics || {}),
      status: report?.params?.status,
    },
    ...(report?.params?.logs || []),
  ], [JSON.stringify(report)]);

  const getReportFiles = async () => {
    const res = await dispatch(
      entityRead({
        data: {
          entity_type: 'vacationRequest',
          entity_uuids: [report?.uuid],
          parent: rootPartitionUuid,
          depth: 0,
          with_files: true,
        },
        constants: [
          'GET_MONTLY_REPORT_FILES_REQUEST',
          'GET_MONTLY_REPORT_FILES_SUCCESS',
          'GET_MONTLY_REPORT_FILES_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    const reportFiles = res?.[0]?.files || [];

    reportFiles?.forEach((file) => {
      file.url = `data:${file?.content_type};base64, ${file?.file}`;
      file.status = 'done';
    });

    const reportLogs = res?.[0]?.params?.logs;

    const filesWithName = [];
    let filesLog = [];

    if (reportLogs?.length) {
      reportLogs.forEach(({ files, created }) => {
        if (files?.length) {
          filesLog = [...filesLog, ...files];
          filesWithName.push({
            name: `Файлы отчета от ${dayjs(created).format('YYYY-MM-DD')}:`,
            files: reportFiles?.filter?.((file) => files?.includes(file?.uuid)),
          });
        }
      });
    }

    const lastFiles = reportFiles?.filter?.((file) => !filesLog?.includes(file?.uuid));
    if (lastFiles?.length) {
      filesWithName.unshift({
        name: 'Файлы последнего отчета:',
        files: lastFiles,
      });
    }

    setFiles(filesWithName);
  };

  const columns = [
    {
      title: 'Дата создания',
      dataIndex: ['created'],
      render: (date) => (
        <Row>
          {dayjs(date).format('DD MMM YYYY')}
        </Row>
      ),
      sorter: (a, b) => dayjs(a?.created).unix() - dayjs(b?.created).unix(),
    },
    {
      title: 'Тип',
      dataIndex: ['status'],
      render: (status) => <TypeTag status={status} />,
    },
    {
      title: 'Больнич.',
      dataIndex: ['sickday'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => a?.sickday - b?.sickday,
    },
    {
      title: 'Отгул',
      dataIndex: ['dayoff'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => a?.dayoff - b?.dayoff,
    },
    {
      title: 'Отпуск',
      dataIndex: ['vacation'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => a?.vacation - b?.vacation,
    },
    // {
    //   title: 'Кан.',
    //   dataIndex: ['holidays'],
    //   render: (el) => <span>{el ?? 0}</span>,
    //   sorter: (a, b) => a?.holidays - b?.holidays,
    // },
    {
      title: 'Проектные часы',
      dataIndex: ['totalWorks'],
      sorter: (a, b) => a?.totalWorks - b?.totalWorks,
      render: (totalWorks, row) => {
        const content = (
          <Paragraph>{row?.comment}</Paragraph>
        );
        if (row?.comment) {
          return (
            <div className="flex">
              <span className="mr-1">{totalWorks ?? 0}</span>
              <Popover
                content={content}
                style={{
                  cursor: 'pointer',
                  color: '#1677ff',
                }}
                title="Comment"
              >
                <Icon
                  color="#1d39c4"
                  path={mdiHelpCircleOutline}
                  size={1}
                />
              </Popover>
            </div>
          );
        }
        return <span>{totalWorks ?? 0}</span>;
      },
    },
    {
      title: 'Нерабочие часы',
      dataIndex: ['totalDayOff'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => (a?.totalDayOff ?? 0) - (b?.totalDayOff ?? 0),
    },
  ];

  useEffect(() => {
    if (report?.uuid) {
      getReportFiles();
    }
    if (report) {
      setStatistics(report?.params?.statistics ?? {});
    }
  }, [JSON.stringify(report)]);

  return (
    <Row
      style={{
        width: '100%',
      }}
    >
      <Col span={(statistics?.comment?.length || filesList?.length) ? 18 : 24}>
        <Row
          style={{
            alignItems: 'center',
            marginBottom: 4,
          }}
        >
          <span>Раздел:</span>
          <Segmented
            style={{ marginLeft: 4 }}
            options={optionTabs}
            value={selecetedTab}
            onChange={setSelectedTab}
          />
        </Row>
        {selecetedTab === 'disciplinary' ? (
          <DisciplinaryReport
            report={report}
            hasInfo={statistics?.comment?.length || filesList?.length}
          />
        ) : null}
        {selecetedTab === 'report' ? (
          <Col>
            <Table
              style={{
                marginLeft: -46,
                marginTop: 10,
              }}
              rowKey={(row) => row?.created}
              columns={columns}
              dataSource={dataSource}
              size="small"
            />
          </Col>
        ) : null}
        {selecetedTab === 'days' ? (
          <Col>
            <DaysTable
              days={report?.params?.days}
            />
          </Col>
        ) : null}
      </Col>
      {(statistics?.comment?.length || filesList?.length) && selecetedTab !== 'days' ? (
        <Col
          span={6}
        >
          {statistics?.comment?.length > 0 ? (
            <>
              <span style={{ color: 'grey' }}>Комментарий: </span>
              <Paragraph className="mb-1" style={{ overflowY: 'scroll', maxHeight: '250px' }}>
                {statistics?.comment}
              </Paragraph>
            </>
          ) : null}
          {filesList?.length
            ? (
              <>
                {filesList?.map((item) => (
                  <>
                    <span style={{ color: 'grey' }}>{item?.name}</span>
                    <AntViewFiles wrapperStyle={{ overflowY: 'scroll', maxHeight: '300px' }} filesList={item?.files} />
                  </>
                ))}
              </>
            )
            : null}

        </Col>
      ) : null}
    </Row>
  );
}

ExpandedMonthlyReport.propTypes = {
  report: object,
  month: string,
  actor: object,
};
