import { get } from 'lodash';
import { createSelector } from 'reselect';

export const getActors = (state) => get(state, 'actors.actors', []);

export const getFetchingActors = (state) => get(state, 'actors.isFetching', false);

export const transformActorsToSelect = createSelector(
  getActors,
  (actors) => {
    const firstName = (actor) => get(actor, 'uinfo.first_name', 'name');
    const lastName = (actor) => get(actor, 'uinfo.last_name', 'last_name');
    const getInitial = (string) => string.charAt(0).toUpperCase();

    const getActorName = (item) => {
      let name = 'name not found';

      switch (item.actor_type) {
        case 'user':
        case 'classic_user':
        case 'phantom':
          name = `${firstName(item)} ${lastName(item)}`;
          break;
        case 'group':
          name = get(item, 'uinfo.group_name', 'last_name');
          break;
        default:
          return name;
      }
      return name;
    };

    return actors.map((item) => ({
      value: get(item, 'uuid') || get(item, 'actor_uuid'),
      label: getActorName(item),
      entityType: get(item, 'actor_type'),
      initials: `${getInitial(firstName(item))}${getInitial(lastName(item))}`,
    }));
  },
);

export const actorsMap = createSelector(
  transformActorsToSelect,
  (actors) => new Map([...actors.map((i) => ([i?.value, i]))]),
);
