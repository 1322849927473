import dayjs from "dayjs";

export const TIMELINE_STATUSES = [
  {
    value: 'internal',
    label: 'Internal funded project',
  },
  {
    value: 'client',
    label: "Client's funded project",
  },
  {
    value: 'bench',
    label: 'Bench',
  },
  {
    value: 'contract_ends',
    label: 'Contract ends',
  },
  {
    value: 'reserved',
    label: 'Reserved',
  },
  {
    value: 'potential_bench',
    label: 'Potential bench',
  },
];

export const initStatusesTimeRange = {
  fromSelectMonth: 0,
  fromSelectYear: new Date().getFullYear(),
  toSelectMonth: 1,
  toSelectYear: new Date().getFullYear(),
};

export const defaultConfig = {
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
};
