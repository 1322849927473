import _ from 'lodash';
import React from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';
import MD5 from 'crypto-js/md5';
import i18n from '../i18n/i18n';
import antNotification from './antNotification';

export const capitalizeFirstChar = (string) => (string ? `${string[0].toUpperCase()}${string.slice(1)}` : '');

export const textToClipboard = (value) => {
  navigator.clipboard.writeText(value).then(() => {
    antNotification.success(
      `${capitalizeFirstChar(
        i18n.t('app.notifications.copied', 'copied to clipboard'),
      )}!`,
    );
  });
};

export const clearObject = (obj) => JSON.parse(JSON.stringify(obj, (key, value) => _.identity(value)));

export const getUserColor = (userUUID) => {
  const userColorNum = (Number.parseInt(userUUID[userUUID.length - 1], 16)
    + Number.parseInt(userUUID[0], 16)) % 10;

  switch (userColorNum) {
    case 7:
      return '#722ed1';
    case 8:
      return '#eb2f96';
    case 0:
      return '#f5222d';
    case 1:
      return '#fa541c';
    case 2:
      return '#fa8c16';
    case 3:
      return '#faad14';
    case 4:
      return '#fadb14';
    case 5:
      return '#a0d911';
    case 6:
      return '#52c41a';
    case 9:
      return '#13c2c2';
    default:
      return '#2f54eb';
  }
};

export const getVacationType = (type) => {
  let label;
  let colorTag;

  switch (type) {
    case 'vacationRequest':
    case 'vacation':
      label = 'Отпуск';
      colorTag = '#f50';
      break;
    case 'holidaysRequest':
    case 'holidays':
      label = 'Каникулы';
      colorTag = '#2db7f5';
      break;
    case 'sickday':
      label = 'Больничный';
      colorTag = '#13C2C2';
      break;
    default:
      label = '-';
      colorTag = '#87d068';
  }

  return { label, colorTag };
};

export const getStatusParams = (status) => {
  let formattedStatus;
  let icon;

  if (status === 'contract_ends') {
    formattedStatus = 'Contract ends';
    // eslint-disable-next-line react/jsx-filename-extension
    icon = <MinusCircleOutlined />;
  } else if (status === 'client') {
    formattedStatus = "Client's project";
    icon = <MinusCircleOutlined />;
  } else if (status === 'internal') {
    formattedStatus = 'Internal project';
    icon = <MinusCircleOutlined />;
  } else if (status === 'bench') {
    formattedStatus = status;
    icon = <MinusCircleOutlined />;
  } else if (status === 'reserved') {
    formattedStatus = status;
    icon = <MinusCircleOutlined />;
  } else {
    formattedStatus = status;
    icon = <MinusCircleOutlined />;
  }

  return { label: formattedStatus, icon };
};

export const getClassNameStatusColor = (status) => {
  let classNameColor;

  if (status === 'bench') {
    classNameColor = 'timeline-status-red';
  } else if (status === 'contract_ends') {
    classNameColor = 'timeline-status-orange';
  } else if (status === 'client') {
    classNameColor = 'timeline-status-green';
  } else if (status === 'reserved' || status === 'internal') {
    classNameColor = 'timeline-status-blue';
  } else if (status === 'potential_bench') {
    classNameColor = 'timeline-status-amber';
  } else {
    classNameColor = 'timeline-status-gray';
  }

  return classNameColor;
};

export const getVacationStatusColor = (status) => {
  let color;

  if (status === 'rejected') {
    color = '#f50';
  } else if (status === 'approved') {
    color = '#87d068';
  } else if (status === 'pending') {
    color = '#108ee9';
  } else if (status === 'pre-agreed') {
    color = '#FFA500';
  } else {
    color = '#2db7f5';
  }

  return color;
};

export const getMD5HashFromString = (str) => MD5(str).toString();
