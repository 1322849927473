import { mdiArrowLeftBold, mdiArrowRightBold } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Col, Divider, Row, Select, Space,
} from 'antd';
import React from 'react';

import './dateSwitcher.scss';
import { func, string } from 'prop-types';
import cn from 'classnames';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

export default function DateSwitcher({
  type = 'month',
  formatView,
  currentDate,
  onChangeHandler,
}) {
  const year = +dayjs(currentDate).format('YYYY');
  const options = [];
  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>,
    );
  }

  return (
    <div className="flex justify-start items-center">
      <Space split={<Divider type="vertical" />} align="center">
        <Row className="flex items-center">
          <Col
            className="monthControll"
            onClick={() => onChangeHandler(dayjs(currentDate).add(-1, type))}
          >
            <Icon path={mdiArrowLeftBold} className="arrow-wrapper__icon" size={2} />
          </Col>
          <Col
            className="mx-3"
            style={{
              color: '#3e9bffec',
              fontSize: '22px',
            }}
          >
            {capitalize(dayjs(currentDate).format(formatView || 'MMMM, YYYY'))}
          </Col>
          <Col
            className="monthControll"
            onClick={() => onChangeHandler(dayjs(currentDate).add(1, type))}
          >
            <Icon path={mdiArrowRightBold} className="arrow-wrapper__icon" size={2} />
          </Col>
        </Row>

        <Row className={cn({ hidden: type === 'year' })}>
          <Col>
            <Select
              id="from-select-year"
              name="from-select-year"
              value={year}
              onChange={(newYear) => {
                onChangeHandler(dayjs(`${newYear}-${dayjs(currentDate).format('MM-DD')}`));
              }}
              style={{ width: 120 }}
              size="large"
            >
              {options}
            </Select>
          </Col>
        </Row>
      </Space>
    </div>
  );
}

DateSwitcher.propTypes = {
  currentDate: string,
  onChangeHandler: func,
  type: string,
  formatView: string,
};
