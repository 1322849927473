import GroupsConstants from './constants';

const initialState = {
  isFetching: true,
  groups: [],
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GroupsConstants.FETCH_GROUPS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GroupsConstants.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        groups: payload?.data ?? [],
      };

    case GroupsConstants.FETCH_GROUPS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
