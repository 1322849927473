import React, { useMemo } from 'react';

import {
  Col, Row, Spin, Typography,
} from 'antd';

const { Title } = Typography;

function PageWrapper({
  title,
  children,
  isLoading = false,
  fixed,
  maxWidth = 960,
  actions,
  needWrapperClassName = true,
}) {
  const style = useMemo(() => ({ maxWidth: fixed ? maxWidth : '', margin: 'auto' }), [fixed]);
  const titleStyle = useMemo(() => ({
    fontSize: 20,
    marginTop: 4,
    marginBottom: 16,
  }), []);
  return (
    <Spin spinning={isLoading} wrapperClassName={needWrapperClassName ? "spin-h-full" : ''}>
      <div style={style} className="h-full flex flex-col">
        <Row gutter={[16, 16]}>
          <Col span={actions ? 12 : 24}>
            <Title
              style={titleStyle}
            >
              {title}
            </Title>
          </Col>
          <Col
            span={actions ? 12 : 0}
            className="flex justify-end"
          >
            {actions}
          </Col>
        </Row>
        {children}
      </div>
    </Spin>
  );
}

export default PageWrapper;
