import keyMirror from 'keymirror'

const HumanResourcesConstants = keyMirror({
	GET_HUMAN_RESOURCE_LIST_REQUEST: null,
	GET_HUMAN_RESOURCE_LIST_SUCCESS: null,
	GET_HUMAN_RESOURCE_LIST_FAILURE: null,

	CREATE_HUMAN_RESOURCE_REQUEST: null,
	CREATE_HUMAN_RESOURCE_SUCCESS: null,
	CREATE_HUMAN_RESOURCE_FAILURE: null,

	GET_HUMAN_RESOURCE_REQUEST: null,
	GET_HUMAN_RESOURCE_SUCCESS: null,
	GET_HUMAN_RESOURCE_FAILURE: null,

	UPDATE_HUMAN_RESOURCE_REQUEST: null,
	UPDATE_HUMAN_RESOURCE_SUCCESS: null,
	UPDATE_HUMAN_RESOURCE_FAILURE: null,

	DELETE_HUMAN_RESOURCE_REQUEST: null,
	DELETE_HUMAN_RESOURCE_SUCCESS: null,
	DELETE_HUMAN_RESOURCE_FAILURE: null,
})

export default HumanResourcesConstants
