import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dateFormat2 } from '../54origins/dateFormats54origins';
import { getMonthlyReport } from '../redux/monthlyReport/selectors';

export default function useCheckDailyReport() {
  const monthlyReport = useSelector(getMonthlyReport);

  const hasWorkRecord = useMemo(
    () => monthlyReport?.params?.days?.some((el) => el?.rangeDates?.[0] === dateFormat2() && el?.wasChoiceOfDay),
    [JSON.stringify(monthlyReport)],
  );

  return (
    {
      hasWorkRecord,
    }
  );
}
