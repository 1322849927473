import { Modal } from 'antd';
import dayjs from 'dayjs';

export const dataIsDayOff = (day) => {
  const date = day.toLowerCase();
  return date === 'sa' || date === 'su' || date === 'сб' || date === 'вс';
};

export const getArrayForItem = (item) => (typeof item === 'string' ? [item] : item);

export function enumerateDaysBetweenDates(start, end) {
  let currentStart = start;
  const date = [];
  while (dayjs(currentStart) <= dayjs(end)) {
    date.push(dayjs(currentStart).format('YYYY-MM-DD'));
    currentStart = dayjs(currentStart).add(1, 'days').format('YYYY-MM-DD');
  }
  return date;
}

export const checkIsDayOff = (currentDay) => dayjs(currentDay).format('ddd') === 'sun'
|| dayjs(currentDay).format('ddd') === 'sat'
|| dayjs(currentDay).format('ddd') === 'сбт'
|| dayjs(currentDay).format('ddd') === 'вск';

export const errorMaxSize = () => {
  Modal.error({
    title: 'Ошибка',
    content: 'Файл/файлы превышают максимально допустимый размер! Максимальный размер составляет 15 МБ!',
  });
};
