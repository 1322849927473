import { Alert, Typography } from 'antd';
import React from 'react';

const { Paragraph } = Typography;

export default function InfoCommentVacation() {
  return (
    <Alert
      className="!w-full !m-0"
      style={{
        maxHeight: '165px',
      }}
      message="Комментарий должен содержать следующую информацию:"
      description={(
        <div>
          <Paragraph
            style={{ marginBottom: 2 }}
          >
            1. Руководитель, который был уведомлен.
          </Paragraph>
          <Paragraph
            style={{ marginBottom: 2 }}
          >
            2. Сотрудник, который будет заменять вас на время отпуска (при необходимости).
          </Paragraph>
          <Paragraph
            style={{ marginBottom: 2 }}
          >
            3. Проекты, в которых вы задействованы.
          </Paragraph>
        </div>
            )}
      type="warning"
      showIcon
    />
  );
}

InfoCommentVacation.propTypes = {

};
