import ReportConstants from './constants';
import dayjs from "dayjs";
import {get} from "lodash";

const initState = {
  isFetchingYear: false,
  isFetchingLastMonthReports: true,
  isFetching: false,
  currentYear: null,
  list: [],
  lastMonthReportsList: [],
};

export default (state = initState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ReportConstants.GET_YEAR_REPORT_REQUEST: {
      return {
        ...state,
        isFetchingYear: true,
        error: false,
      };
    }
    case ReportConstants.GET_YEAR_REPORT_SUCCESS: {
      return {
        ...state,
        isFetchingYear: false,
        currentYear: payload,
      };
    }
    case ReportConstants.GET_YEAR_REPORT_FAILURE: {
      return {
        ...state,
        isFetchingYear: false,
      };
    }
    case ReportConstants.GET_REPORTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    }
    case ReportConstants.GET_REPORTS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: payload,
      };
    }
    case ReportConstants.GET_REPORTS_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case ReportConstants.GET_LAST_MONTH_REPORTS_REQUEST: {
      return {
        ...state,
        isFetchingLastMonthReports: true,
        error: false,
      };
    }
    case ReportConstants.GET_LAST_MONTH_REPORTS_SUCCESS: {
      return {
        ...state,
        isFetchingLastMonthReports: false,
        lastMonthReportsList: payload,
      };
    }
    case ReportConstants.GET_LAST_MONTH_REPORTS_FAILURE: {
      return {
        ...state,
        isFetchingLastMonthReports: false,
      };
    }
    default:
      return state;
  }
};
