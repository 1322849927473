import { get } from 'lodash';
import { createSelector } from 'reselect';

export const rootEntities = (state) => get(state, 'rootEntities.data', []);

export const rootEntitiesSignal = (state) => get(state, 'rootEntities.dataSignal', []);

export const timelineStatusRoot = createSelector(
  rootEntities,
  (items) => items.find((i) => i?.entity_type === 'timelineStatusStorage') || [],
);
export const vacationRequestsRoot = createSelector(
  rootEntities,
  (items) => items.find((i) => i?.entity_type === 'vacationRequestsStorage') || [],
);

export const dayOffRoot = createSelector(
  rootEntities,
  (items) => items.find((i) => i?.entity_type === 'dayOffStorage') || [],
);

export const vacationsRoot = createSelector(
  rootEntities,
  (items) => items.find((i) => i?.entity_type === 'vacationsStorage') || [],
);

export const reportsStorageRoot = createSelector(
  rootEntities,
  (items) => items.find((i) => i?.entity_type === 'reportsStorage') || [],
);

export const instructionStorageRoot = createSelector(
    rootEntitiesSignal,
  (items) => items.find((i) => i?.entity_type === 'instructionStorage') || [],
);
