import keyMirror from 'keymirror';

const ProfileConstants = keyMirror({
  GET_PROFILE_INFO_REQUEST: null,
  GET_PROFILE_INFO_SUCCESS: null,
  GET_PROFILE_INFO_FAILURE: null,

  GET_MY_PERMISSIONS_REQUEST: null,
  GET_MY_PERMISSIONS_SUCCESS: null,
  GET_MY_PERMISSIONS_FAILURE: null,
});

export default ProfileConstants;
