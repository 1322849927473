import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDeliveryPartition } from '../../config/selectors';
import { getDepartmentsStorageUUID, getIsFetchingDepartmentsStorage } from '../selectors/departmentsStorageSelector';

import { getOrCreateSingle } from '../../../api/actions/entity';
import {
  GET_OR_CREATE_DEPARTMENTS_STORAGE_FAILURE,
  GET_OR_CREATE_DEPARTMENTS_STORAGE_REQUEST,
  GET_OR_CREATE_DEPARTMENTS_STORAGE_SUCCESS,
} from '../slices/departmentsStorageSlice';

import { SERVICE_PARTITION_NAME } from '../../../api/app_config';

const useDepartmentsStorage = () => {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);
  const storageUUID = useSelector(getDepartmentsStorageUUID);
  const storageFetching = useSelector(getIsFetchingDepartmentsStorage);

  const initFunc = () => {
    dispatch(getOrCreateSingle({
      data: {
        parent: partitionUUID,
        entity_type: 'departmentsStorage',
      },
      constants: [
        GET_OR_CREATE_DEPARTMENTS_STORAGE_REQUEST,
        GET_OR_CREATE_DEPARTMENTS_STORAGE_SUCCESS,
        GET_OR_CREATE_DEPARTMENTS_STORAGE_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }));
  };

  useEffect(() => {
    if (partitionUUID) {
      initFunc();
    }
  }, [partitionUUID]);

  return {
    storageUUID,
    storageFetching,
  };
};

export default useDepartmentsStorage;
