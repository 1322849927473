import keyMirror from 'keymirror';

const MonthlyReportConstants = keyMirror({
  GET_OR_CREATE_MONTHLY_REPORT_REQUEST: null,
  GET_OR_CREATE_MONTHLY_REPORT_SUCCESS: null,
  GET_OR_CREATE_MONTHLY_REPORT_FAILURE: null,

  UPDATE_MONTHLY_DAY_REPORT_REQUEST: null,
  UPDATE_MONTHLY_DAY_REPORT_SUCCESS: null,
  UPDATE_MONTHLY_DAY_REPORT_FAILURE: null,
});

export default MonthlyReportConstants;
