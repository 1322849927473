import dayjs from 'dayjs';
import _ from 'lodash';

import { createSelector } from '@reduxjs/toolkit';
import { isDateAfterToday, isDateBeforeToday } from '../../utils/dateTimeUtils';

export const getFetchingVacationList = (state) => _.get(state, 'userVacations.isFetching', false);

export const getVacations = (state) => _.get(state, 'userVacations.list', []);

export const getVacationsCount = (state) => _.get(state, 'userVacations.total', 0);

export const getAllVacations = (state) => _.get(state, 'userVacations.listAll', []);

export const getAllVacationsCount = (state) => _.get(state, 'userVacations.totalAll', 0);

export const selectVacationsByDate = createSelector(
  [(state) => getVacations(state), (state, params) => params],
  (items, params) => items.filter(
    (item) => dayjs(item.params.start_date).year() === params.year
				&& dayjs(item.params.end_date).year() === params.year
				&& dayjs(item.params.start_date).month() >= params.fromMonth
				&& dayjs(item.params.end_date).month() <= params.toMonth,
  ),
);

export const getActualVacations = (state) => _.get(state, 'userVacations.list', []).filter((v) => isDateAfterToday(v.params.dates[1]));

export const getCurrentVacations = (state) => _.get(state, 'userVacations.list', []).filter(
  (v) => dayjs(v.params.dates[0]).isSameOrBefore()
			&& dayjs(v.params.dates[1]).isSameOrAfter(),
);

export const getPastVacations = (state) => _.get(state, 'userVacations.list', []).filter((v) => isDateBeforeToday(v.params.dates[1]));

export const getFutureVacations = (state) => _.get(state, 'userVacations.list', []).filter(
  (v) => isDateAfterToday(v.params.dates[0])
			&& isDateAfterToday(v.params.dates[1]),
);
