import { get } from 'lodash';

export const getAllGroups = (state) => get(state, 'groups.groups', []);

export const getFetchingGroups = (state) => get(state, 'groups.isFetching', true);

const getGroupUUID = (state, name) => state.groups.groups?.filter((item) => item?.uinfo?.group_name === name)[0]?.uuid;
const getGroup = (state, name) => state.groups.groups?.filter((item) => item?.uinfo?.group_name === name)[0];

export const getBanGroupUUID = (state) => getGroupUUID(state, 'BAN');

export const getAdminGroupUUID = (state) => getGroupUUID(state, 'ADMIN');

export const getDefaultGroupUUID = (state) => getGroupUUID(state, 'DEFAULT');
export const getDefaultGroup = (state) => getGroup(state, 'DEFAULT');
