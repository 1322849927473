import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Switch, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';

function ReportsSwitchers({
  disabled,
  onChangeShowReportsConfirmation,
  showBaseReport,
  showLunchBreak,
}) {
  const { t } = useTranslation();

  // const getLabelForSwitch = (param) => (
  //   <>
  //     {capitalizeAndTranslateMsg(`wms.adjective.${param}.0`)}
  //     {' '}
  //     {t('wms.noun.report.0')}
  //   </>
  // );

  return (
    <Row className="d-flex" gutter={4} wrap={false}>
      <Col>
        <div className="text-secondary fontSize11">
          <Tooltip title="Start work report">
            SWR
          </Tooltip>
        </div>
        <Switch
          // size="small"
          disabled={disabled}
          onClick={(_, e) => e.stopPropagation()}
          onChange={(checked) => onChangeShowReportsConfirmation(checked, 'showBaseReport')}
          checked={showBaseReport}
          // checkedChildren="On"
          // unCheckedChildren="Off"
        />
      </Col>
      <Col>
        <div className="text-secondary fontSize11">
          <Tooltip title="Lunch break mandatory">
            LBM
          </Tooltip>
        </div>
        <Switch
          // size="small"
          disabled={disabled}
          onClick={(_, e) => e.stopPropagation()}
          onChange={(checked) => onChangeShowReportsConfirmation(checked, 'showLunchBreak')}
          checked={showLunchBreak}
          // checkedChildren="On"
          // unCheckedChildren="Off"
        />
      </Col>
    </Row>
  );
}

ReportsSwitchers.propTypes = {
  disabled: PropTypes.bool,
  onChangeShowReportsConfirmation: PropTypes.func,
  showBaseReport: PropTypes.bool,
  showLunchBreak: PropTypes.bool,
};

export default ReportsSwitchers;
