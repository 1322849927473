import _ from 'lodash';

import { SERVICE_PARTITION_NAME } from '../../api/app_config';

export const getConfigFetching = (state) => _.get(state, 'config.isFetching', false);

export const getConfig = (state) => _.get(state, 'config', null);

export const getEntityDomain = (state) => _.get(state, 'config.entity_domain', null);

export const getHeaderPartition = (
  state,
  serviceName = SERVICE_PARTITION_NAME,
) => _.get(state, `config.header_partitions.${serviceName}`, null);

export const getDeliveryPartition = (state) => _.get(state, `config.partitions.${SERVICE_PARTITION_NAME}`, null);

export const getPartition = (state, serviceName = SERVICE_PARTITION_NAME) => _.get(state, `config.partitions.${serviceName}`, null);

export const getPublicPartition = (state) => _.get(state, 'config.partitions.PUBLIC', null);
