import { number, string } from 'prop-types';
import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import { getUserColor } from '../utils';

function UserAvatar({
  userFirstName = '',
  userLastName = '',
  userUUID = '',
  size = 40,
  className = 'avatar-user',
  customInitials = 'NN',
}) {
  const initials = customInitials || `${capitalize(
    userFirstName[0],
  )}${capitalize(userLastName[0])}`;

  const style = useMemo(() => ({
    width: size,
    height: size,
    backgroundColor: getUserColor(userUUID),
  }), [userUUID]);

  return (
    <div
      className={className}
      style={style}
    >
      <p className="text-center">{initials}</p>
    </div>
  );
}

UserAvatar.propTypes = {
  size: number,
  userFirstName: string,
  userLastName: string,
  className: string,
  userUUID: string,
  customInitials: string,
};

export default UserAvatar;
