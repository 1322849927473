import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useParams } from 'react-router-dom';
import AppContent from './AppContent';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';

import { getDeliveryPartition } from '../../redux/config/selectors';
import useAPI from '../../api/useAPI';
import { getUserRootFetching, getUserRootInfo, getUserRootUUID } from '../../redux/storages/selectors';
import { getProfileInfo, isRootOrAdmin } from '../../redux/profile/selectors';
import CheckLastMonthReport from './CheckLastMonthReport';
import GroupsConstants from '../../redux/groups/constants';
import useActorsAPI from '../../api/useActorsApi';

const layoutStyle = {
  minHeight: '100vh',
};

export default function AppLayout() {
  const dispatch = useDispatch();

  const {
    getRootUser,
    getVacationStorage,
    getMonthlyReportsStorage,
  } = useAPI();

  const { getListOfAllActors } = useActorsAPI();

  const myProfileInfo = useSelector(getProfileInfo);
  const partitionUUID = useSelector(getDeliveryPartition);

  const userRootUUID = useSelector(getUserRootUUID);
  const userRootInfo = useSelector(getUserRootInfo);
  const isFetchingUser = useSelector(getUserRootFetching);

  const isUserRootOrAdmin = useSelector(isRootOrAdmin);

  const urlParams = new URLSearchParams(window.location.search);
  const actor = urlParams.get('actor');

  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const getGroups = () => {
    const defaultGroups = ['BAN', 'ADMIN', 'DEFAULT'];

    const publicConfig = {
      actor_type: 'group',
      uinfo: {
        group_name: defaultGroups,
      },
    };

    const constants = [
      GroupsConstants.FETCH_GROUPS_REQUEST,
      GroupsConstants.FETCH_GROUPS_SUCCESS,
      GroupsConstants.FETCH_GROUPS_FAILURE,
    ];

    getListOfAllActors(publicConfig, constants);
  };

  const initFunc = async () => {
    if (!actor) {
      getRootUser();
    }
    if (isUserRootOrAdmin) {
      getGroups();
    } else {
      dispatch({ type: GroupsConstants.FETCH_GROUPS_FAILURE });
    }
  };

  useEffect(() => {
    if (!isFetchingUser && userRootUUID) {
      getVacationStorage(userRootUUID, userRootInfo?.actor);
      getMonthlyReportsStorage(userRootUUID, userRootInfo?.actor);
    }
  }, [userRootUUID, isFetchingUser]);

  useEffect(() => {
    if (partitionUUID && myProfileInfo?.uuid) {
      initFunc();
    }
  }, [partitionUUID, myProfileInfo?.uuid]);

  return (
    <Layout style={layoutStyle}>
      <>
        <AppSidebar
          isSidebarCollapsed={isSidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />
        <CheckLastMonthReport />
        <Layout>
          <AppHeader
            isSidebarCollapsed={isSidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
          <div className="relative h-full w-full mb-2">
            <AppContent />
          </div>
          <AppFooter />
        </Layout>
      </>
    </Layout>
  );
}
