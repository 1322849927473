import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllDepartments } from '../actions/departmentsActions';
import { getDepartments } from '../selectors/departmentsSelector';

const useDepartments = ({
  storageUUID,
  storageFetching,
}) => {
  const dispatch = useDispatch();

  const departments = useSelector(getDepartments);

  const initFunc = () => {
    if (storageUUID && !storageFetching) {
      dispatch(getAllDepartments({}, storageUUID));
    }
  };

  useEffect(() => {
    initFunc();
  }, [storageUUID, storageFetching]);

  return {
    departments,
    reloadDepartments: initFunc,
  };
};

export default useDepartments;
