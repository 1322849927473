import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import config from './config/reducer';
import humanResources from './humanResource/reducer';
import locales from './locale/reducer';
import profileInfo from './profile/reducer';
import publicUI from './publicInterfaces/reducers';
import userTimelineStatuses from './userTimelineStatus/reducer';
import userVacations from './userVacation/reducer';
import userHolidays from './userHolidays/reducer';
import vacations from './vacations/reducer';
import holidays from './holidays/reducer';
import monthlyReport from './monthlyReport/reducer';
import rootEntities from './rootEntities/reducer';
import globalUpdate from './globalUpdate/reducer';
import userDayOff from './userDayOff/reducer';
import actors from './actors/reducer';
import publicUserEntity from './publicUserEntity/reducer';
import reports from './reports/reducer';
import storages from './storages/reducer';
import masquerading from './masquerading/reducer';
import reports54Actors from './reports54Actors/reducer';
import groups from './groups/reducer';
import departmentsSlice from './departments/slices/departmentsSlice';
import departmentsStorageSlice from './departments/slices/departmentsStorageSlice';
import artifactsSlice from './departments/slices/artifactsSlice';

const createRootReducer = (history) => combineReducers({
  config,
  locales,
  profile: profileInfo,
  rootEntities,
  globalUpdate,
  userDayOff,
  storages,
  actors,
  groups,
  masquerading,
  publicUserEntity,
  monthlyReport,
  reports,
  humanResources,
  userTimelineStatuses,
  userVacations,
  userHolidays,
  reports54Actors,
  vacations,
  holidays,
  Departments: combineReducers({
    artifacts: artifactsSlice,
    listOfDepartments: departmentsSlice,
    departmentsStorage: departmentsStorageSlice,
  }),
  public_interfaces: publicUI,
  router: connectRouter(history),
});

export default createRootReducer;
