import HolidaysConstants from './constants';

const initState = {
  isFetching: false,
  isFetchingSingle: false,
  list: [],
  total: 0,
  single: null,
  error: false,
  errorMessage: '',
  isLoaded: false,
};

export default (state = initState, action = {}) => {
  const { type, payload, total } = action;

  switch (type) {
    // GET ALL VACATIONS
    case HolidaysConstants.GET_HOLIDAYS_REQUEST:
      return {
        ...state,
        isFetching: false,
        total: 0,
        error: false,
      };

    case HolidaysConstants.GET_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: payload,
        total,
        error: false,
        errorMessage: '',
      };
    }

    case HolidaysConstants.GET_HOLIDAYS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: payload.error_message,
      };

    default:
      return state;
  }
};
