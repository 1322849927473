import React, { useMemo } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Row } from 'antd';
import PropTypes, { string } from 'prop-types';
import { get } from 'lodash';

function BaseReportWorkPlanTextarea({
  disabled,
  formState,
  setFormState,
  rows = 3,
}) {
  const lastAdjustmentData = {};

  const reason = get(lastAdjustmentData, ['params', 'reason'], '');

  const typeOfAdjustment = useMemo(() => {
    const overtime = get(lastAdjustmentData, ['params', 'type'], '') === 'finishWorkLater';
    const overtimeValue = get(lastAdjustmentData, ['params', 'overtimeValue'], '00h00m');
    const personalNeeds = get(lastAdjustmentData, ['params', 'personalNeeds'], false);

    const defaultString = 'Adjustment status: ';

    if (overtime) {
      return `${defaultString}finish work laster - overtime ${overtimeValue}`;
    }

    return `${defaultString}finish work earlier - ${personalNeeds ? 'personal needs' : 'I feel sick, going home'}`;
  }, [lastAdjustmentData]);

  const onChangeTextHandler = (e) => {
    setFormState((prev) => ({
      ...prev, dailyPlanningText: e.target.value, wasChanges: true, disabledSubmit: false,
    }));
  };

  const currentValue = useMemo(() => {
    if (reason) {
      return (`${formState.dailyPlanningText}\n\nReport was adjusted!\n${typeOfAdjustment} \nReason of adjustments: ${reason}`);
    }
    return formState.dailyPlanningText;
  }, [formState.dailyPlanningText, reason, typeOfAdjustment]);

  return (
    <Row className="my-2">
      <div className="d-flex align-items-center">
        <div className="text-secondary">
          Ваш план:
        </div>
      </div>

      <TextArea
        disabled={disabled}
        className="w-100"
        maxLength={1000}
        onChange={onChangeTextHandler}
        value={currentValue}
        rows={rows}
        placeholder="Опишите вашу планируемую деятельность..."
      />
    </Row>
  );
}

BaseReportWorkPlanTextarea.propTypes = {
  disabled: PropTypes.bool,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  rows: PropTypes.number,
  currentDay: string,
};

export default BaseReportWorkPlanTextarea;
