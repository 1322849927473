import StoragesConstants from './constants';

const initialState = {
  userRoot: null,
  isFetchingUser: true,
  vacationStorage: null,
  isFetchingVacationSt: true,
  monthlyReportsStorage: null,
  isFetchingMonthlyReportsSt: true,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case StoragesConstants.RESET_STORAGES: {
      return {
        ...state,
        userRoot: null,
        isFetchingUser: true,
        vacationStorage: null,
        monthlyReportsStorage: null,
        isFetchingVacationSt: true,
        isFetchingMonthlyReportsSt: true,
      };
    }
    case StoragesConstants.GET_ROOT_USER_REQUEST: {
      return {
        ...state,
        userRoot: null,
        isFetchingUser: true,
      };
    }
    case StoragesConstants.GET_ROOT_USER_SUCCESS: {
      return {
        ...state,
        userRoot: payload,
        isFetchingUser: false,
      };
    }
    case StoragesConstants.GET_ROOT_USER_FAILURE: {
      return {
        ...state,
        isFetchingUser: false,
      };
    }
    case StoragesConstants.GET_VACATION_STORAGE_REQUEST: {
      return {
        ...state,
        vacationStorage: null,
        isFetchingVacationSt: true,
      };
    }
    case StoragesConstants.GET_VACATION_STORAGE_SUCCESS: {
      return {
        ...state,
        vacationStorage: payload,
        isFetchingVacationSt: false,
      };
    }
    case StoragesConstants.GET_VACATION_STORAGE_FAILURE: {
      return {
        ...state,
        isFetchingVacationSt: false,
      };
    }
    case StoragesConstants.GET_MONTHLY_REPORTS_STORAGE_REQUEST: {
      return {
        ...state,
        monthlyReportsStorage: null,
        isFetchingMonthlyReportsSt: true,
      };
    }
    case StoragesConstants.GET_MONTHLY_REPORTS_STORAGE_SUCCESS: {
      return {
        ...state,
        monthlyReportsStorage: payload,
        isFetchingMonthlyReportsSt: false,
      };
    }
    case StoragesConstants.GET_MONTHLY_REPORTS_STORAGE_FAILURE: {
      return {
        ...state,
        isFetchingMonthlyReportsSt: false,
      };
    }
    default:
      return state;
  }
};
