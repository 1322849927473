import React, { useRef, useState } from 'react';
import {
  Alert,
  Button,
  Card, Col, Flex, Input, Row,
} from 'antd';
import dayjs from 'dayjs';
import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import humanizeDuration from 'humanize-duration';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { daysToMilliseconds, diffDays } from '../../../utils/dateTimeUtils';
import ListOfActorsR54WithHook from '../../../actors/components/ListOfActorsR54WithHook';
import useAPI from '../../../api/useAPI';
import antNotification from '../../../utils/antNotification';
import { getUserRootInfo } from '../../../redux/storages/selectors';
import { enumerateDaysBetweenDates } from '../../../utils/commonUtils';
import './vacation-styles.scss';
import GlobalUpdateConstants from '../../../redux/globalUpdate/constants';
import useResize from '../../../hooks/useResize';
import { maxCountOfVacationDays } from '../../user/vacationsRequests/utils';

const { TextArea } = Input;

export default function FormCreateUserVacation() {
  const dispatch = useDispatch();

  const dateRef = useRef();

  const {
    width,
  } = useResize();

  const {
    getVacationStorage,
    requestCreateUserVacation,
  } = useAPI();

  const userInfo = useSelector(getUserRootInfo);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [comment, setComment] = useState('');

  const [selectedUser, setSelectedUser] = useState(null);

  const getWidth = () => {
    if (width >= 1800) {
      return 1055;
    }
    if (width >= 1490) {
      return 845;
    }
    if (width >= 1190) {
      return 633;
    }
    return 422;
  };

  const getCount = () => {
    if (width >= 1800) {
      return 5;
    }
    if (width >= 1490) {
      return 4;
    }
    if (width >= 1190) {
      return 3;
    }
    return 2;
  };

  const onClickCallback = (user = null) => {
    if (user?.value === selectedUser?.value) {
      setSelectedUser(null);
    } else {
      setSelectedUser(user);
    }
  };

  const diffDate = daysToMilliseconds(diffDays(startDate, endDate));

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    if (diffDays(start, end) > maxCountOfVacationDays) {
      antNotification.warning(
        `Отпуск больше ${maxCountOfVacationDays} дней!`,
      );
    }
    setStartDate(start);
    setEndDate(end);
  };

  const onResetForm = () => {
    setSelectedUser(null);
    setStartDate(null);
    setEndDate(null);
    setComment('');
  };

  const onCreateVacation = async () => {
    try {
      if (!selectedUser) {
        antNotification.info(
          'Необходимо выбрать сотрудника',
        );
        return;
      }
      if (!startDate || !endDate) {
        antNotification.info(
          'Необходимо выбрать даты',
        );
        return;
      }
      const constants = [
        'FETCH_VACATION_STORAGE_FOR_ACTOR_REQUEST',
        'FETCH_VACATION_STORAGE_FOR_ACTOR_SUCCESS',
        'FETCH_VACATION_STORAGE_FOR_ACTOR_FAILURE',
      ];
      const vacationStorage = await getVacationStorage(
        selectedUser?.value,
        selectedUser?.actor,
        constants,
      );
      if (vacationStorage?.uuid) {
        const config = {
          actor: selectedUser?.actor,
          params: {
            actorUuid: selectedUser?.actor,
            status: 'approved',
            start_date: startDate,
            end_date: endDate,
            comment,
            rangeDates: enumerateDaysBetweenDates(startDate, endDate),
            reviewed: {
              uinfo: userInfo.uinfo,
              actor: userInfo.actor,
            },
          },
        };

        await requestCreateUserVacation(vacationStorage?.uuid, config);
        onResetForm();
      }
      antNotification.success(
        'Отпуск создан!',
      );
    } catch (e) {
      console.log(e);
      antNotification.warning(
        'Ошибка!',
      );
    } finally {
      dispatch({ type: GlobalUpdateConstants.SET_GET_DATA, payload: 'getAgreedList' });
    }
  };

  return (
    <Card title="Создание отпуска" className="mt-2">
      <Row>
        <Col
          span={6}
          className="user-list-wrapper"
          style={{ marginTop: -5 }}
        >
          <Flex
            vertical
          >
            <label
              htmlFor="add-timeline-status-form_dates"
              className="ant-form-item-required text-xs text-[#777]"
              title="Тип"
            >
              {/* TODO: Create custom label component */}
              Сотрудник
            </label>
            <ListOfActorsR54WithHook
              onClickCallback={onClickCallback}
              selectedUser={selectedUser}
            />
          </Flex>
        </Col>
        <Col
          span={17}
          className="ml-4"
        >
          <Flex
            vertical
          >
            <label
              htmlFor="add-timeline-status-form_dates"
              className="ant-form-item-required text-xs text-[#777]"
              title="Тип"
            >
              {/* TODO: Create custom label component */}
              <span style={{ color: '#ff4d4f', paddingRight: 4 }}>
                *
              </span>
              Дата начала / Дата окончания
            </label>
            <DatePicker
              selected={startDate}
              ref={dateRef}
              style={{
                width: getWidth(),
              }}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              inline
              monthsShown={getCount()}
              selectsRange
              locale={ru}
            />

            <label
              htmlFor="add-timeline-status-form_dates"
              className="ant-form-item-required text-xs text-[#777]"
              title="Тип"
            >
              {/* TODO: Create custom label component */}
              Комментарий
            </label>
            <TextArea
              placeholder="Введите комментарий"
              value={comment}
              style={{
                width: getWidth(),
              }}
              onChange={(e) => setComment(e.target.value)}
              rows={4}
            />
            <Flex
              className="w-100"
              align="center"
            >
              <Flex className={cn({ hidden: (!startDate || !endDate) && !selectedUser })}>
                <Alert
                  className="mt-2 mr-2"
                  message={(
                    <p className="m-0">
                      Вы планируете отпуск
                      {selectedUser ? ` для сотрудника ${selectedUser?.label} ` : ''}
                      {startDate && endDate
                        ? (
                          <>
                            с
                            <span className="text-green-600 font-medium mx-2">{dayjs(startDate).format('DD MMMM YYYY')}</span>
                            по
                            <span className="text-green-600 font-medium mx-2">{dayjs(endDate).format('DD MMMM YYYY')}</span>
                            (
                            <span className="text-green-600 font-medium mx-2">
                              {humanizeDuration(diffDate, { units: ['d'], round: true, language: 'ru' })}
                            </span>
                            )
                          </>
                        ) : ''}
                      .
                    </p>
              )}
                  type="info"
                />
              </Flex>

              <Button
                style={{ width: 140 }}
                className="mt-2"
                type="primary"
                ghost
                onClick={onCreateVacation}
              >
                Создать отпуск
              </Button>
              <Button
                style={{ width: 150 }}
                className="mt-2 ml-2"
                type="primary"
                ghost
                danger
                disabled={!startDate && !endDate && !selectedUser && !comment}
                onClick={onResetForm}
              >
                Очистить данные
              </Button>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
}
