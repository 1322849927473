import React, { useState } from 'react';

import {
  Alert,
  Badge, Button, Col, Descriptions, Modal, Row,
} from 'antd';

import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import { func, object, string } from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import antNotification from '../../../../utils/antNotification';
import { diffDays, formatDate } from '../../../../utils/dateTimeUtils';

import useAPI from '../../../../api/useAPI';
import { getUserRootInfo } from '../../../../redux/storages/selectors';
import { stopPropagation } from '../../../../utils/utils54origins';

export default function ApprovedUserVacation({
  row, buttonProps, status, saveCallback,
}) {
  const {
    requestUpdateUserVacation,
  } = useAPI();

  const userInfo = useSelector(getUserRootInfo);

  const [isModalOpen, setModalOpen] = useState(false);

  const onOpenModal = () => setModalOpen(true);

  const onCancelModal = () => setModalOpen(false);

  const handleOk = async () => {
    try {
      await requestUpdateUserVacation(row.uuid, {
        status: status === 'pending' ? 'pre-agreed' : 'approved',
        [status === 'pending' ? 'agreed' : 'reviewed']: {
          uinfo: userInfo.uinfo,
          actor: userInfo.actor,
        },
      });
      saveCallback();
      antNotification.success(`Заявка на отпуск ${status === 'pending' ? 'предварительно согласован' : 'утвержден'}.`);
    } catch {
      antNotification.error('Ошибка!');
    }

    onCancelModal();
  };

  return (
    <>
      <Button
        onClick={() => onOpenModal()}
        style={{ width: 140 }}
        {...buttonProps}
        className={cn('flex items-center', {
          'border !border-green-500 !bg-green-500 !text-white hover:!text-green-500 hover:!bg-white': status === 'pre-agreed',
        })}
      >
        <Icon path={mdiCheck} className="mr-2" size={0.8} />
        {status === 'pending' ? 'Согласовать' : 'Утвердить'}
      </Button>
      <Modal
        title={`${status === 'pending' ? 'Согласовать' : 'Утвердить'} отпуск для ${row.uinfo.first_name} ${row.uinfo.last_name}?`}
        open={isModalOpen}
        onCancel={() => onCancelModal()}
        width={500}
        destroyOnClose
        onOk={() => handleOk()}
      >
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Имя:">
                {row.uinfo.first_name}
              </Descriptions.Item>
              <Descriptions.Item label="Фамилия:">
                {row.uinfo.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Дата начала:">
                {formatDate(row.params.start_date)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Дата окончания:"
              >
                {formatDate(row.params.end_date)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Количество дней:"
              >
                <Badge
                  count={diffDays(row.params.start_date, row.params.end_date)}
                />
              </Descriptions.Item>

              <Descriptions.Item
                label="Комментарий:"
              >
                {row.params.comment || '-'}
              </Descriptions.Item>

              {row?.params?.status === 'pre-agreed' ? (
                <Descriptions.Item
                  label="Дополнительная информация после согласования:"
                >
                  {row.params.additionalСomment || '-'}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

ApprovedUserVacation.propTypes = {
  row: object.isRequired,
  buttonProps: object,
  saveCallback: func,
  status: string,
};
