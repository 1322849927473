import {
  Col, Row, Table, Tag, Typography,
} from 'antd';
import dayjs from 'dayjs';
import { bool, object } from 'prop-types';
import React, { useMemo } from 'react';
import { getLabelByType, translateTypeDay } from '../dayOff/utils';

const {
  Paragraph,
} = Typography;

const reportFields = [
  'office_work',
  'remote_work',
  'work_on_day_off',
  'dayoff',
  'sickday',
  'bench_1_month',
  'bench_over_1_month',
  'bench_over_3_month',
  'holidays',
  'vacation',
  'totalWorks',
  'totalDayOff',
];

function TypeTag({ status }) {
  switch (status) {
    case 'auto': return (
      <Tag color="#87d068">
        Автоматический
      </Tag>
    );
    case 'submitted': return (
      <Tag color="#108ee9">
        Пользовательский
      </Tag>
    );
    default: return null;
  }
}

export default function DisciplinaryReport({ report, hasInfo }) {
  const historyChanges = useMemo(() => {
    const days = report?.params?.days ?? [];
    days.sort((a, b) => dayjs(a?.created).unix() - dayjs(b?.created).unix());
    const changesArr = days?.reduce((acc, el) => {
      if (el?.logs?.length > 1) {
        const historyLogType = [getLabelByType(el?.type, el)];
        el?.logs?.forEach((log) => {
          historyLogType.push(getLabelByType(log?.type, log));
        });
        const itemDayHistory = {
          date: el?.rangeDates?.[0],
          history: historyLogType,
        };
        acc.push(itemDayHistory);
      }
      return acc;
    }, []);

    return changesArr;
  }, [JSON.stringify(report)]);

  const difference = useMemo(() => {
    const reportLogs = report?.params?.logs ?? [];
    const status = report?.params?.status ?? '';
    const statistics = report?.params?.statistics ?? {};
    if (reportLogs?.length) {
      let autoReport;
      let prevReport;
      if (status === 'auto') {
        autoReport = statistics;
        prevReport = reportLogs?.[0] ?? {};
      } else {
        autoReport = reportLogs?.find((log) => log?.status === 'auto');
        prevReport = statistics;
      }
      if (autoReport) {
        const diff = {};
        reportFields?.forEach((key) => {
          diff[key] = (+prevReport?.[key] || 0) - (+autoReport?.[key] || 0);
        });
        return [autoReport, { ...prevReport, status: 'submitted' }, diff];
      }
    }
    return [];
  }, [JSON.stringify(report)]);

  const columns = [
    {
      title: 'Тип',
      dataIndex: ['status'],
      render: (status, row) => {
        if (status) {
          return <TypeTag status={status} />;
        }
        return null;
      },
    },
    // {
    //   title: 'В офисе',
    //   dataIndex: ['office_work'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time;
    //   },
    // },
    // {
    //   title: 'Удаленно',
    //   dataIndex: ['remote_work'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time;
    //   },
    // },
    // {
    //   title: 'Работа в вых.',
    //   dataIndex: ['work_on_day_off'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time;
    //   },
    // },
    // {
    //   title: 'Отгул',
    //   dataIndex: ['dayoff'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time;
    //   },
    // },
    // {
    //   title: 'Больничный',
    //   dataIndex: ['sickday'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time;
    //   },
    // },
    // {
    //   title: 'Бенч 1 месяц',
    //   dataIndex: ['bench_1_month'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time ?? 0;
    //   },
    // },
    // {
    //   title: 'Бенч больше месяца',
    //   dataIndex: ['bench_over_1_month'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time ?? 0;
    //   },
    // },
    // {
    //   title: 'Бенч больше 3-х месяцев',
    //   dataIndex: ['bench_over_3_month'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time ?? 0;
    //   },
    // },
    // {
    //   title: 'Каникулы',
    //   dataIndex: ['holidays'],
    //   render: (time, row, index) => {
    //     if (index !== 2) {
    //       return time;
    //     }
    //     if (+time > 0) {
    //       return <span style={{ color: 'green' }}>{time}</span>;
    //     }
    //     if (+time < 0) {
    //       return <span style={{ color: 'red' }}>{time}</span>;
    //     }
    //     return time;
    //   },
    // },
    {
      title: 'Проектные часы',
      dataIndex: ['totalWorks'],
      render: (time, row, index) => {
        if (index !== 2) {
          return time;
        }
        if (+time > 0) {
          return (
            <span style={{ color: 'green' }}>
              +
              {time}
            </span>
          );
        }
        if (+time < 0) {
          return <span style={{ color: 'red' }}>{time}</span>;
        }
        return time;
      },
    },
    {
      title: 'Нерабочие часы',
      dataIndex: ['totalDayOff'],
      render: (time, row, index) => {
        if (index !== 2) {
          return time;
        }
        if (+time > 0) {
          return (
            <span style={{ color: 'green' }}>
              +
              {time}
            </span>
          );
        }
        if (+time < 0) {
          return <span style={{ color: 'red' }}>{time}</span>;
        }
        return time;
      },
    },
    {
      title: 'Отпуск',
      dataIndex: ['vacation'],
      render: (time, row, index) => {
        if (index !== 2) {
          return time;
        }
        if (+time > 0) {
          return (
            <span style={{ color: 'green' }}>
              +
              {time}
            </span>
          );
        }
        if (+time < 0) {
          return <span style={{ color: 'red' }}>{time}</span>;
        }
        return time;
      },
    },
  ];

  return (
    <Row>
      {(!difference?.length && !historyChanges?.length && !report?.params?.statistics)
        ? (
          <Col
            span={24}
            style={{
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'grey',
            }}
          >
            Данные для дисциплинарного отчета отсутствуют
          </Col>
        ) : null}
      {/* {report?.params?.statistics ? (
        <Col style={{
        }}
        >
          <Row>
            <Paragraph style={{ color: 'grey' }}>
              Проектные часы:
              {' '}
              {report?.params?.statistics?.totalWorks ?? 0}
            </Paragraph>
          </Row>
          <Row>
            <Paragraph style={{ color: 'grey' }}>
              Нерабочие часы:
              {' '}
              {report?.params?.statistics?.totalDayOff ?? 0}
            </Paragraph>
          </Row>
        </Col>
      ) : null} */}
      {difference?.length ? (
        <Col span={(historyChanges?.length && !hasInfo) ? 10 : 23}>
          <Paragraph style={{ color: 'grey' }}>
            Сравнение последнего отчета с автоматическим :
          </Paragraph>
          <Table
            style={{
              marginLeft: 0,
              marginTop: 10,
            }}
            rowKey={(row) => row?.status}
            columns={columns}
            dataSource={difference}
            pagination={false}
            size="small"
          />
        </Col>
      ) : null}
      {historyChanges?.length ? (
        <Col span={(difference?.length && !hasInfo) ? 13 : 23} style={{ marginTop: difference?.length ? 15 : 0 }}>
          <Paragraph style={{ color: 'grey' }}>
            Многократно измененные дни:
          </Paragraph>
          {historyChanges.map((el) => (
            <Row style={{ marginTop: 6 }}>
              <Col style={{ marginRight: 12 }}>
                {dayjs(el?.date).format('DD MMM')}
              </Col>
              {el?.history.map((tag, index) => {
                if (!tag) return null;
                if (index + 1 === el?.history?.length) {
                  return (
                    <Col style={{ marginRight: 8, marginBottom: 6 }}>
                      {tag}
                    </Col>
                  );
                }
                return (
                  <Col style={{ marginRight: 8, marginBottom: 6 }}>
                    {tag}
                    {' '}
                    {'<-'}
                    {' '}
                  </Col>
                );
              })}
            </Row>
          ))}

        </Col>
      ) : null}
    </Row>
  );
}

DisciplinaryReport.propTypes = {
  report: object,
  hasInfo: bool,
};
