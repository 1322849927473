import LocaleConstants from './constants'

const initialState = {
	language: 'en',
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case LocaleConstants.SWITCH_LANGUAGE:
			return {
				...state,
				language: payload,
			}

		default:
			return state
	}
}
