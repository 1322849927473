import React from 'react';

import { Button, Modal } from 'antd';
import { capitalize } from 'lodash';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { func, object } from 'prop-types';
import { mdiCancel } from '@mdi/js';
import Icon from '@mdi/react';
import { useSelector } from 'react-redux';
import antNotification from '../../../../utils/antNotification';

import useAPI from '../../../../api/useAPI';
import { getUserRootInfo } from '../../../../redux/storages/selectors';

const { confirm } = Modal;

function RejectedUserVacation({ row, saveCallback }) {
  const {
    requestUpdateUserVacation,
  } = useAPI();

  const userInfo = useSelector(getUserRootInfo);

  const showConfirmDelete = (rowData) => {
    confirm({
      title: 'Отклонение заявки на отпуск',
      icon: <ExclamationCircleFilled />,
      content: 'Вы уверены, что хотите удалить оклонить эту заявку?',
      async onOk() {
        try {
          await requestUpdateUserVacation(rowData.uuid, {
            status: 'rejected',
            reviewed: {
              uinfo: userInfo.uinfo,
              actor: userInfo.actor,
            },
          });
          saveCallback();
          antNotification.success('Заявка на отпуск успешно отклонена.');
        } catch {
          antNotification.error('Что-то пошло не так.');
        }
      },
      onCancel() {},
    });
  };

  return (
    <Button
      onClick={() => showConfirmDelete(row)}
      danger
      ghost
      className="flex items-center"
    >
      <Icon path={mdiCancel} className="mr-2" size={0.8} />
      {capitalize('отклонить')}
    </Button>
  );
}

export default RejectedUserVacation;

RejectedUserVacation.propTypes = {
  row: object.isRequired,
  saveCallback: func,
};
