import React from 'react';

import {
  Avatar, Dropdown, Space, Tag, Tooltip, Typography,
} from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  CaretDownOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import AuthHelper from '../../../utils/authUtils';

import { getProfileInfo } from '../../../redux/profile/selectors';

const { Text } = Typography;

function ProfileMenu() {
  const profileInfo = useSelector(getProfileInfo);
  const { t } = useTranslation();

  const getUserInfo = (key) => _.get(profileInfo, `uinfo.${key}`, '');

  const items = [
    {
      key: '1',
      label: (
        <Link to="/profile">
          <Space>
            <Avatar
              icon={<UserOutlined style={{ color: '#222' }} />}
              shape="square"
              style={{
							  background: '#eee',
							  display: 'flex',
							  justifyContent: 'center',
							  alignItems: 'center',
              }}
            />
            <div>
              <Text
                style={{
								  display: 'block',
								  lineHeight: 1.15,
                }}
              >
                Profile
              </Text>
              {_.get(profileInfo, 'root', false) && (
              <Tag className="tag-purple ml-2">
                {t(
									  'auth.headers.root',
									  'root',
                ).toUpperCase()}
              </Tag>
              )}
            </div>
          </Space>
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to="/login" onClick={() => AuthHelper.logout()}>
          <Space>
            <Avatar
              icon={<LogoutOutlined style={{ color: '#222' }} />}
              shape="square"
              className="flex items-center justify-center bg-[#eee]"
            />
            <div>
              <Text className="block leading-[1.15]">
                Log Out
              </Text>
            </div>
          </Space>
        </Link>
      ),
    },
  ];

  if (!profileInfo.uuid) {
    return null;
  }

  const userName = getUserInfo('first_name') + ' ' + getUserInfo('last_name');

  return (
    <Dropdown
      menu={{ items, selectable: true }}
      trigger={['click']}
    >
      <div
        style={{ cursor: 'pointer', color: '#1677ff' }}
        onClick={(e) => e.preventDefault()}
        className="flex"
      >
        <Space>
          <Avatar
            icon={<UserOutlined style={{ color: '#1677ff' }} />}
            shape="square"
            style={{
						  background: '#e6f4ff',
						  display: 'flex',
						  justifyContent: 'center',
						  alignItems: 'center',
            }}
          />
          <span style={{ fontSize: 15, fontWeight: 500 }}>
            {userName.length > 45 ? (
                <Tooltip
                  title={userName}
                >
                  {userName.slice(0, 42)}...
                </Tooltip>
            ) : userName}
          </span>
          <CaretDownOutlined />
        </Space>
      </div>
    </Dropdown>
  );
}

export default ProfileMenu;
