import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'lodash';
import ReportsSwitchers from './ReportsSwitchers';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import antNotification from '../../../../utils/antNotification';
import useAPI from '../../../../api/useAPI';

function SwitchShowReportsToActor({
  actorUUID,
  defaultShowBaseReport,
  defaultShowLunchBreak,
  disabled,
}) {
  const dispatch = useDispatch();

  const { updateDeliveryUser } = useAPI();

  const [isChecked, setIsChecked] = useState({
    showBaseReport: true,
    showLunchBreak: true,
  });

  const partitionUUID = useSelector(getDeliveryPartition);

  const updateActors = async (uuid, checked, type) => {
    try {
      const publicConfig = {
        entity_uuid: uuid,
        entity_type: 'user',
        params: {
          [type]: checked,
        },
        parent: partitionUUID,
      };
      await dispatch(updateDeliveryUser(publicConfig));
      antNotification('success', 'Персональные настройки пользователя были успешно обновлены');
    } catch (err) {
      // console.log('errr', err);
    }
  };

  const onChangeShowReportsConfirmation = (checked, type) => {
    updateActors(actorUUID, checked, type);
    setIsChecked((prev) => ({ ...prev, [type]: checked }));
  };

  useEffect(() => {
    setIsChecked({
      showBaseReport: defaultShowBaseReport,
      showLunchBreak: defaultShowLunchBreak,
    });
  }, [defaultShowBaseReport, defaultShowLunchBreak]);

  return (
    <ReportsSwitchers
      disabled={disabled}
      onChangeShowReportsConfirmation={onChangeShowReportsConfirmation}
      showBaseReport={isChecked.showBaseReport}
      showLunchBreak={isChecked.showLunchBreak}
    />
  );
}

SwitchShowReportsToActor.propTypes = {
  actorUUID: PropTypes.string,
  defaultShowBaseReport: PropTypes.bool,
  defaultShowDetailedReport: PropTypes.bool,
  defaultShowLunchBreak: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SwitchShowReportsToActor;
