import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import useAPI from '../../api/useAPI';
import { getProfileInfo } from '../../redux/profile/selectors';
import {
  getMonthlyStorageInfo, getMonthlyStorageUUID, getUserRootInfo, getVacationStorageUUID,
} from '../../redux/storages/selectors';
import { getArrByType, getHours } from '../../pages/user/dayOff/utils';
import { enumerateDaysBetweenDates } from '../../utils/commonUtils';

export default function CheckLastMonthReport() {
  const {
    updateMonthlyReport,
    getAgreedVacations,
    getOrCreateMonthlyReport,
  } = useAPI();

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const monthlyReportInfo = useSelector(getMonthlyStorageInfo);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const myProfileData = useSelector(getProfileInfo);
  const userInfo = useSelector(getUserRootInfo);

  const lastMonth = dayjs().subtract(1, 'month').format('YYYY-MM');

  const startMonth = dayjs(lastMonth).startOf('month').format('YYYY-MM-DD');
  const endMonth = dayjs(lastMonth).endOf('month').format('YYYY-MM-DD');

  const actorUuid = myProfileData?.uuid;

  const getLastMonthReport = async () => {
    const configRequest = {
      actor: actorUuid,
      params: {
        actorUuid,
        date: lastMonth,
      },
      with_files: true,
    };

    return getOrCreateMonthlyReport(monthlyStorageUUID, configRequest);
  };

  const getVacations = async () => {
    const configRequest = {
      params: {
        actorUuid,
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(lastMonth).format('YYYY-MM-DD'),
          dayjs(lastMonth).format('YYYY-MM-DD'),
        ),
      },
    };

    return getAgreedVacations(
      vacationStorageUUID,
      configRequest,
      [
        'GET_VACATIONS_FOR_AUTOREPORT_REQUEST',
        'GET_VACATIONS_FOR_AUTOREPORT_SUCCESS',
        'GET_VACATIONS_FOR_AUTOREPORT_FAILURE',
      ],
    );
  };

  const initFunc = async () => {
    const result = await Promise.allSettled([
      getVacations(),
      getLastMonthReport(),
    ]);

    let currentData = [];
    let report = null;

    if (result[0].status === 'fulfilled') {
      currentData = [...currentData, ...get(result, '[0].value.data', [])];
    }

    if (result[1].status === 'fulfilled') {
      report = get(result, '[1].value', null);
      const userDays = get(result, '[1].value.params.days', []);
      currentData = [...currentData, ...userDays];
    }

    if (report?.params?.status === 'auto' || report?.params?.status === 'submitted') {
      return;
    }

    const data = {
      office_work: 0,
      remote_work: 0,
      work_on_day_off: 0,
      totalWorks: 0,
      sickday: 0,
      holidays: 0,
      vacation: 0,
      projectDayOffs: 0,
      dayoff: 0,
      localHoliday: 0,
      totalDayOff: 0,
    };

    const allDaysWithType = [...getArrByType(
      currentData,
      enumerateDaysBetweenDates(startMonth, endMonth),
    )]
      .map(([key, value]) => ({ ...value }))
      .filter((el) => !dayjs(el?.date).isAfter(dayjs()));

    data.office_work = getHours(allDaysWithType.filter((item) => item.type === 'office_work'));
    data.remote_work = getHours(allDaysWithType.filter((item) => item.type === 'remote_work'));
    data.work_on_day_off = getHours(allDaysWithType.filter((item) => item.type === 'work_on_day_off'));

    data.fullTotalWorks = getHours(allDaysWithType);
    data.totalWorks = Math.ceil(getHours(allDaysWithType));

    data.sickday = allDaysWithType.filter((item) => item.type === 'sickday').length || 0;
    data.holidays = allDaysWithType.filter((item) => item.type === 'holidays').length || 0;
    data.vacation = allDaysWithType.filter((item) => item.type === 'vacation').length || 0;
    data.projectDayOffs = allDaysWithType.filter((item) => item.type === 'projectDayOff').length || 0;
    data.dayoff = allDaysWithType.filter((item) => item.type === 'dayoff').length || 0;
    data.localHoliday = allDaysWithType.filter((item) => item.type === 'localHoliday').length || 0;

    data.totalDayOff = data.sickday + data.holidays + data.vacation
    + data.projectDayOffs + data.dayoff + data.localHoliday;

    await updateMonthlyReport({
      entity_type: 'monthlyReport',
      entity_uuid: report?.uuid,
      params: {
        status: 'auto',
        statistics: {
          ...data,
          actorUuid,
          created: dayjs().toString(),
        },
      },
      logs: [],
    });
  };

  useEffect(() => {
    if (userInfo?.actor === actorUuid
      && monthlyStorageUUID
      && monthlyReportInfo?.actor === actorUuid
      && vacationStorageUUID) {
      initFunc();
    }
  }, [userInfo?.actor, actorUuid, monthlyStorageUUID, vacationStorageUUID]);

  return null;
}
