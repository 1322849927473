import { Col, Row } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';
import { isEmpty } from 'lodash';
import { capitalizeFirstChar } from '../../../../utils';
import { getBanGroupUUID } from '../../../../redux/groups/selectors';
import TagsSettingList from '../../../../components/tags/tagsFilter/TagsSettingList';

const defaultSelectedTags = [{
  key: 'internal',
  value: 'internal',
  label: capitalizeFirstChar('internal'),
  className: 'internal-tag',
  ruleOfTag: 'included',
}, {
  key: 'banned',
  value: 'banned',
  label: capitalizeFirstChar('banned'),
  className: 'banned-tag',
  ruleOfTag: 'excluded',
}];

function DayUserReportUserTableHeader({
  setConfig,
}) {
  const { t } = useTranslation();

  const banGroupUUID = useSelector(getBanGroupUUID);

  const allTags = useMemo(() => [
    {
      tags: [
        {
          key: 'all',
          label: capitalizeFirstChar(t('wms.filters.all', 'all')),
        },
      ],
      isReset: true,
    },
    {
      tags: [
        {
          key: 'banned',
          value: 'banned',
          label: 'Banned',
          className: 'banned-tag',
          ruleOfTag: 'off',
        },
      ],
      isRadio: true,
    },
    {
      tags: [
        {
          key: 'internal',
          value: 'internal',
          label: 'Internal',
          className: 'internal-tag',
          ruleOfTag: 'off',
        },
      ],
      isRadio: true,
    },
  ], [t]);

  const onSelectTags = (tags) => {
    if (isEmpty(tags)) {
      setConfig((prev) => ({
        ...prev,
        uinfo: {},
      }));
    } else if (!isEmpty(tags)) {
      const config = {};

      tags.forEach((el) => {
        if (el?.key === 'banned' && el?.ruleOfTag === 'excluded') config.groups__not = [banGroupUUID];
        if (el?.key === 'banned' && el?.ruleOfTag === 'included') config.groups = [banGroupUUID];
        if (el?.key === 'internal' && el?.ruleOfTag === 'included') config.internal_user = true;
        if (el?.key === 'internal' && el?.ruleOfTag === 'excluded') config.internal_user = false;
      });
      setConfig((prev) => ({
        ...prev,
        uinfo: config,
      }));
    }
  };

  return (
    <Row className="d-flex align-items-center justify-content-between pb-3">
      <Col>
        <TagsSettingList
          allTags={allTags}
          defaultSelectedTags={defaultSelectedTags}
          size="small"
          onSelectTags={onSelectTags}
        />
      </Col>
    </Row>
  );
}

export default memo(DayUserReportUserTableHeader);

DayUserReportUserTableHeader.propTypes = {
  getActorsList: func,
  setConfig: func,
};
