import { get } from 'lodash';
import { createSelector } from 'reselect';

export const getPublicUsersEntities = (state) => get(state, 'publicUserEntity.actors', []);

export const getFetchingPublicUsersEntities = (state) => get(state, 'publicUserEntity.isFetching', false);

export const transformPublicUsersEntities = createSelector(
  getPublicUsersEntities,
  (actors) => {
    const firstName = (actor) => get(actor, 'uinfo.first_name', 'name');
    const lastName = (actor) => get(actor, 'uinfo.last_name', 'last_name');
    const getInitial = (string) => string.charAt(0).toUpperCase();

    return actors.map((item) => ({
      entityType: get(item, 'actor_type'),
      actorUuid: get(item, 'actor'),
      publicUuid: get(item, 'uuid'),
      label: `${firstName(item)} ${lastName(item)}`,
      initials: `${getInitial(firstName(item))}${getInitial(lastName(item))}`,
    }));
  },
);
