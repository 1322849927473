import { mdiMinus, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { get } from 'lodash';
import {
  array, bool, func, object, string,
} from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { iconSize, prefixSize } from './utilsFilter';
import {capitalizeFirstChar} from "../../../utils";

export default function ExcludeOrInclude({
  selectedTags,
  currentTag,
  size,
  labelButton,
  addOrUpdateStatus,
  toDeselect,
}) {
  const { t } = useTranslation();

  const TagFromSelected = selectedTags.find(
    ({ key, value }) => key === currentTag.key && value === currentTag.value,
  );
  const statusFromSelected = get(TagFromSelected, 'ruleOfTag', 'off');

  const getNewStatus = (current, sender) => {
    switch (sender) {
      case 1:
        return current === 'included' ? 'off' : 'included';
      case 3:
        return current === 'excluded' ? 'off' : 'excluded';
      default:
        return current === 'off' ? 'included' : 'off';
    }
  };

  const handleChange = (sender) => {
    const status = getNewStatus(statusFromSelected, sender);

    if (status === 'off') {
      return toDeselect(TagFromSelected);
    }
    const isUpdate = !!TagFromSelected;

    return addOrUpdateStatus(currentTag, status, isUpdate);
  };

  return (
    <div className={`areas_filter-excluded ${prefixSize[size]} ${statusFromSelected}`}>
      <div
        onClick={() => handleChange(1)}
        role="button"
        tabIndex={0}
      >
        <Icon path={mdiPlus} size={iconSize[size]} />
      </div>
      <div
        onClick={() => handleChange(2)}
        role="button"
        tabIndex={0}
      >
        <span className="wrapper-text-tag">{capitalizeFirstChar(t(labelButton))}</span>
      </div>
      <div
        onClick={() => handleChange(3)}
        role="button"
        tabIndex={0}
      >
        <Icon path={mdiMinus} size={iconSize[size]} />
      </div>
    </div>
  );
}

ExcludeOrInclude.propTypes = {
  selectedTags: array,
  currentTag: object,
  size: string,
  labelButton: string,
  addOrUpdateStatus: func,
  toDeselect: func,
};
