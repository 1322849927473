import {
  mdiDownload,
  mdiEyeOutline,
  mdiFileOutline,
} from '@mdi/js';
import { PlusOutlined } from '@ant-design/icons';
import Icon from '@mdi/react';
import {
  Col,
  Image,
  Modal,
  Spin,
  Tooltip,
  Upload,
} from 'antd';
import { get } from 'lodash';
import { array, func } from 'prop-types';
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { getElement } from 'jspreadsheet-ce';

function FileIcon({ item }) {
  const viewName = useCallback((filename, name, fileSize) => {
    const fullName = `${filename || name} (${fileSize})`;
    return (
      <Tooltip placement="bottom" title={fullName}>
        <span
          className="text-upload-file"
          style={{
            fontSize: '14px',
            marginTop: -24,
          }}
        >
          {fullName.length > 7 ? `${fullName.slice(0, 6)}...` : fullName}
        </span>
      </Tooltip>
    );
  }, []);

  const {
    content_type: contentType,
    filename = '',
    type,
    name = 'file',
    file,
    uuid,
  } = item || {};
  const i = uuid;

  return (
    <Col className="position-relative d-flex flex-column align-items-center" key={`${uuid}${i}`}>
      {/* {iconDeleteFile(i)} */}

      {(contentType && contentType.indexOf('image') !== -1) || (type && type.indexOf('image') !== -1) ? (
        <div className="wrapper-file-icon">
          <Image
            width={37}
            height={37}
            src={type ? URL.createObjectURL(item) : `data:${contentType};base64, ${file}`}
          />
        </div>
      ) : (
        <Col
          className="d-flex align-items-center fileAttached"
        >
          <Icon
            className="fileIcon"
            color="#bfbfbf"
            path={mdiFileOutline}
            size={2}
          />
        </Col>
      )}
      {viewName(filename, name)}
    </Col>
  );
}

export default function AntUploaderFiles({
  filesList,
  setFiles,
  hideTitle,
}) {
  const [previewFile, setPreviewFile] = useState(null);

  let hasModalErrorSize = false;

  const previewUploadIcon = (file) => {
    if ((get(file, 'content_type') && file.content_type.indexOf('image') !== -1)
    || (get(file, 'type') && file.type.indexOf('image') !== -1)) {
      return (
        <Icon
          path={mdiEyeOutline}
          color="white"
          size={0.75}
          style={{
            marginTop: -3,
          }}
        />
      );
    }
  };

  const propsUpload = useMemo(() => ({
    onRemove: (file) => {
      const newList = [...filesList.filter((el) => el?.uid !== file?.uid)];
      setFiles(newList);
    },
    beforeUpload: (file, files) => {
      const sizeFiles = filesList.reduce((acc, el) => el?.size || el?.content_length || 0 + acc, 0)
      // + file.size;
      + files.reduce((acc, el) => el.size + acc, 0);
      if (sizeFiles >= 15728640) {
        if (!hasModalErrorSize) {
          hasModalErrorSize = true;
          Modal.error({
            title: 'Внимание!',
            id: 'modal_error_files_size',
            content: 'Файл/файлы превышают максимально допустимый размер! Максимальный размер составляет 15 МБ!',
            afterClose: () => { hasModalErrorSize = false; },
          });
        }
      } else {
        files.forEach((el) => {
          el.url = URL.createObjectURL(el);
          el.status = 'done';
        });
        setFiles([...filesList, ...files]);
      }
      return false;
    },
    onPreview: (file) => {
      setPreviewFile(file);
    },
    fileList: filesList,
    multiple: true,
    listType: 'picture-card',
    showUploadList: {
      previewIcon: previewUploadIcon,
    },
    iconRender: (item) => <FileIcon item={item} />,
  }), [filesList]);

  useEffect(() => () => setFiles([]), []);

  return (
    <Spin spinning={false}>
      <div className="w-100 position-relative">
        {!hideTitle
        && (
        <div className="d-flex align-items-center w-100">
          Файлы
        </div>
        )}
        <Upload
          {...propsUpload}
        >
          <div>
            <PlusOutlined style={{ color: '#1890ff' }} />
            <div
              style={{
                marginTop: 8,
                color: '#1890ff',
              }}
            >
              Upload
            </div>
          </div>
        </Upload>
      </div>

      {((get(previewFile, 'content_type') && previewFile.content_type.indexOf('image') !== -1)
         || (get(previewFile, 'type') && previewFile.type.indexOf('image') !== -1))
          && (
            <Image
              width={1}
              src={get(previewFile, 'type')
                ? URL.createObjectURL(previewFile)
                : `data:${previewFile.content_type};base64, ${previewFile.file}`}
              preview={{
                visible: true,
                onVisibleChange: (visible) => {
                  if (!visible) {
                    setPreviewFile(null);
                  }
                },
              }}
            />
          )}
    </Spin>
  );
}

AntUploaderFiles.propTypes = {
  filesList: array,
  setFiles: func,
};
