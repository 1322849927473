import React, { useState } from 'react';
import {
  Flex, Input, Tag, Tooltip,
} from 'antd';

import {
  AutoSizer, List,
} from 'react-virtualized';
import { mdiChevronLeft, mdiCrown, mdiCrownOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { array, bool, func } from 'prop-types';
import cn from 'classnames';

export default function DepartmentUsers({
  uuids = [],
  users = [],
  onUserChange,
  leader,
  deputy,
}) {
  const rowRenderer = ({ index, style, isScrolling }) => {
    if (users[index] === undefined && isScrolling) {
      return (
        <div key={index} style={style}>
          Scrolling...
        </div>
      );
    }

    const {
      value, label, role, actor,
    } = users[index];

    const isLeader = leader === actor;
    const isDeputy = deputy === actor;

    const onChangeRole = (e) => {
      onUserChange(e.target.value, 'role', actor);
    };

    return (
      <Flex
        key={actor}
        className={cn('py-3 mr-2 w-full', {
          // 'bg-blue-50': leader === actor,
        })}
        style={{
          ...style,
          width: 397,
          borderBottom: '1px solid #D3D3D3',
        }}
      >
        <Flex
          vertical
        >
          <Flex
            onClick={() => onUserChange(actor, 'delete', actor)}
            className="text-red-600 bg-white h-full hover:text-white hover:bg-red-600 rounded-full !p-0 mr-3 ml-3 cursor-pointer"
            style={{
              border: '1px solid red',
              height: 22,
              width: 22,
            }}
          >
            <Icon path={mdiChevronLeft} size={1} />
          </Flex>
          {/* {leader !== actor && (
            <Tooltip
              title="Назначить руководителя"
              color="rgb(250, 204, 21)"
            >
              <Flex
                onClick={() => onUserChange(actor, 'leader', actor)}
                className="mt-1 border border-yellow-400 text-yellow-400 h-full hover:text-white hover:bg-yellow-400 rounded-full !p-0 mr-4 ml-2 cursor-pointer"
                style={{
                  height: 22,
                  width: 22,
                }}
              >
                <Icon path={mdiCrownOutline} size={1} />
              </Flex>
            </Tooltip>
          )} */}
        </Flex>
        <Flex
          vertical
        >
          <Flex
            style={{
              maxWidth: 380,
            }}
          >
            {label.length > 24 ? `${label.split(24)}...` : label}
          </Flex>
          <Tooltip
            color="white"
            title={(
              <Flex
                vertical
                justify="space-between"
              >
                {!isLeader && (
                  <Tag
                    color="red"
                    className="cursor-pointer mb-1"
                    onClick={() => onUserChange(actor, 'leader', actor)}
                  >
                    Назначить руководителем
                  </Tag>
                )}
                {!isDeputy && (
                  <Tag
                    color="orange"
                    className="cursor-pointer mb-1"
                    onClick={() => onUserChange(actor, 'deputy', actor)}
                  >
                    Назначить заместителем
                  </Tag>
                )}
                {(isDeputy || isLeader) && (
                  <Tag
                    color="blue"
                    className="cursor-pointer"
                    onClick={() => onUserChange(actor, 'employee', actor)}
                  >
                    Назначить сотрудником
                  </Tag>
                )}
              </Flex>
            )}
            className="text-stone-500 cursor-pointer"
          >
            <span
              className="mr-1"
            >
              Роль:
            </span>
            {!isDeputy && !isLeader && (
            <Tag
              className="w-max"
              color="blue"
            >
              Сотрудник
            </Tag>
            )}
            {isLeader && (
            <Tag
              className="w-max"
              color="red"
            >
              Руководитель
            </Tag>
            )}
            {isDeputy && (
            <Tag
              className="w-max"
              color="orange"
            >
              Заместитель
            </Tag>
            )}

          </Tooltip>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      vertical
      className="rounded"
      style={{
        height: 420,
        width: 400,
        border: '1px dashed gray',
      }}
    >
      <AutoSizer
        className="w-100 h-full mb-2 pb-6"
      >
        {({ width, height }) => (
          <List
            height={405}
            rowHeight={70}
            width={width}
            rowCount={users?.length || 0}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </Flex>
  );
}

DepartmentUsers.propTypes = {
  uuids: array,
  users: array,
  leader: bool,
  onUserChange: func,
};
