import {
  mdiEyeOutline,
  mdiFileOutline,
} from '@mdi/js';
import { PlusOutlined } from '@ant-design/icons';
import Icon from '@mdi/react';
import { saveAs } from 'file-saver';
import {
  Col,
  Image,
  Spin,
  Tooltip,
  Upload,
} from 'antd';
import { get } from 'lodash';
import { array, func, object } from 'prop-types';
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';

function FileIcon({ item }) {
  const viewName = useCallback((filename, name, fileSize) => {
    const fullName = `${filename || name} (${fileSize})`;
    return (
      <Tooltip placement="bottom" title={fullName}>
        <span
          className="text-upload-file"
          style={{
            fontSize: '14px',
            marginTop: -24,
          }}
        >
          {fullName.length > 7 ? `${fullName.slice(0, 6)}...` : fullName}
        </span>
      </Tooltip>
    );
  }, []);

  const {
    content_type: contentType,
    filename = '',
    type,
    name = 'file',
    file,
    uuid,
  } = item || {};
  const i = uuid;

  return (
    <Col className="position-relative d-flex flex-column align-items-center" key={`${uuid}${i}`}>
      {/* {iconDeleteFile(i)} */}

      {(contentType && contentType.indexOf('image') !== -1) || (type && type.indexOf('image') !== -1) ? (
        <div className="wrapper-file-icon">
          <Image
            width={37}
            height={37}
            src={type ? URL.createObjectURL(item) : `data:${contentType};base64, ${file}`}
          />
        </div>
      ) : (
        <Col
          className="d-flex align-items-center fileAttached"
        >
          <Icon
            className="fileIcon"
            color="#bfbfbf"
            path={mdiFileOutline}
            size={2}
          />
        </Col>
      )}
      {viewName(filename, name)}
    </Col>
  );
}

export default function AntViewFiles({
  filesList = [],
  wrapperStyle = {},
}) {
  const [previewFile, setPreviewFile] = useState(null);

  const previewUploadIcon = (file) => {
    if ((get(file, 'content_type') && file.content_type.indexOf('image') !== -1)
      || (get(file, 'type') && file.type.indexOf('image') !== -1)) {
      return (
        <Icon
          path={mdiEyeOutline}
          color="white"
          size={0.75}
          style={{
            marginTop: -3,
          }}
        />
      );
    }
  };

  const propsUpload = useMemo(() => ({
    onPreview: (file) => {
      setPreviewFile(file);
    },
    onDownload: (file) => {
      saveAs(`data:${file?.content_type};base64, ${file?.file}`, file?.filename || file?.name);
    },
    fileList: filesList,
    multiple: true,
    listType: 'picture-card',
    showUploadList: {
      previewIcon: previewUploadIcon,
      showRemoveIcon: false,
      showDownloadIcon: true,
    },
    iconRender: (item) => <FileIcon item={item} />,
    className: 'ant-uploader-for-table',
  }), [filesList]);

  return (
    <Spin spinning={false}>
      <div className="w-100 position-relative" style={wrapperStyle}>
        <Upload
          {...propsUpload}
        >
          <div>
            <PlusOutlined style={{ color: '#1890ff' }} />
            <div
              style={{
                marginTop: 8,
                color: '#1890ff',
              }}
            >
              Upload
            </div>
          </div>
        </Upload>
      </div>

      {((get(previewFile, 'content_type') && previewFile.content_type.indexOf('image') !== -1)
           || (get(previewFile, 'type') && previewFile.type.indexOf('image') !== -1))
            && (
              <Image
                width={1}
                src={get(previewFile, 'type')
                  ? URL.createObjectURL(previewFile)
                  : `data:${previewFile.content_type};base64, ${previewFile.file}`}
                preview={{
                  visible: true,
                  onVisibleChange: (visible) => {
                    if (!visible) {
                      setPreviewFile(null);
                    }
                  },
                }}
              />
            )}
    </Spin>
  );
}

AntViewFiles.propTypes = {
  filesList: array,
  wrapperStyle: object,
};
