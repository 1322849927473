import keyMirror from 'keymirror';

const rootEntitiesConstants = keyMirror({
  GET_ROOT_ENTITIES_REQUEST: null,
  GET_ROOT_ENTITIES_SUCCESS: null,
  GET_ROOT_ENTITIES_FAILURE: null,

  GET_ROOT_ENTITIES_SIGNAL_REQUEST: null,
  GET_ROOT_ENTITIES_SIGNAL_SUCCESS: null,
  GET_ROOT_ENTITIES_SIGNAL_FAILURE: null,
});

export default rootEntitiesConstants;
