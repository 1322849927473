import { mdiArrowLeft, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button, Card, Divider, Flex,
} from 'antd';
import cn from 'classnames';
import React from 'react';

export default function DepartmentCard({
  department = {},
  onBackDepartmentCallback,
}) {
  return (
    <Card
      title={(
        <Flex
          className="w-full"
          justify="space-between"
        >
          <Flex
            align="center"
            className="cursor-pointer"
            onClick={onBackDepartmentCallback}
          >
            <Icon path={mdiArrowLeft} size={0.9} />
            <span className="ml-3 text-xl">Отдел</span>
          </Flex>
          <Flex>
            <Button
              size="small"
            //   onClick={onDeleteArtifact}
              className="!text-red-500 border !border-red-500 hover:bg-red-100"
            >
              Удалить
            </Button>
          </Flex>
        </Flex>
)}
      className={cn('w-1/2 h-max ml-6 p-0', {
        'blue-base-card': true,
      })}
    >
      <Flex
        vertical
        className="p-12 ml-4"
      >
        {' '}
        <Flex
          align="center"
        >
          <span
            className="mr-2 text-base"
          >
            Название:
          </span>
          <Flex
            style={{
              width: 22,
              height: 22,
            }}
            align="center"
            justify="center"
            size="small"
            className="mr-3 cursor-pointer rounded-full text-blue-500 hover:!bg-blue-500 hover:!text-white"
          >
            <Icon path={mdiPencil} size={0.8} />
          </Flex>
          <span
            className="text-blue-500"
          >
            {department?.params?.title}
          </span>
        </Flex>
        <Divider className="my-2" />
        <Flex
          vertical
        >
          <Flex>
            <span
              className="mr-2 text-base"
            >
              Сотрудникам
            </span>
            <Flex
              style={{
                width: 22,
                height: 22,
              }}
              align="center"
              justify="center"
              size="small"
              className="mr-3 cursor-pointer rounded-full text-blue-500 hover:!bg-blue-500 hover:!text-white"
            >
              <Icon path={mdiPencil} size={0.8} />
            </Flex>
          </Flex>
          <Flex
            style={{ whiteSpace: 'pre-wrap' }}
            className="text-gray-500"
          >
            {department?.params?.description}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

DepartmentCard.propTypes = {

};
