import React, { useState } from 'react';
import {
  Button, Flex, Input, Modal,
} from 'antd';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { pick } from 'lodash';
import AssigningUsersForDepartments from './users/AssigningUsersForDepartments';
import DepartmentUsers from './users/DepartmentUsers';
import { createDepartment } from '../../../redux/departments/actions/departmentsActions';

const { TextArea } = Input;

export default function AddDepartmentButton({
  reloadDepartments,
  storageUUID,
}) {
  const dispatch = useDispatch();

  const [isCreateModalOpen, setIsModalOpen] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [usersUUIDs, setUsersUUIDs] = useState([]);
  const [users, setUsers] = useState([]);
  const [leader, setLeader] = useState('');
  const [deputy, setDeputy] = useState('');

  const btnCreateDepartment = `rounded bg-white shadow hover:border-gray-400 
  hover:bg-gray-100 text-gray-500 cursor-pointer`;
  const btnGreen = 'border !border-green-500 text-green-500 hover:bg-green-500 hover:!text-white';
  const btnRed = 'border !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white';

  const resetAll = () => {
    setTitle('');
    setDescription('');
    setUsersUUIDs([]);
    setLeader('');
    setDeputy('');
    setUsers([]);
  };

  const onOpenModalCreateDepartment = () => setIsModalOpen(true);
  const onCloseModalCreateDepartment = () => {
    resetAll();
    setIsModalOpen(false);
  };

  const onUserSelect = (value) => {
    setUsers((prev) => [...prev, value]);
    setUsersUUIDs((prev) => [...prev, value.actor]);
  };

  const onCreateDepartment = () => {
    dispatch(createDepartment(storageUUID, {
      params: {
        title,
        description,
        deputy,
        leader,
        users: users.map((user) => pick(user, ['value', 'role', 'label', 'actor'])),
        usersSearch: usersUUIDs,
      },
    }));
    resetAll();
    setIsModalOpen(false);
    reloadDepartments();
  };

  const onUserChange = (value, type, uuid) => {
    switch (type) {
      case 'role': {
        setUsers((prev) => prev.map((user) => {
          if (user.actor === uuid) {
            return {
              ...user,
              role: value,
            };
          }
          return user;
        }));
        break;
      }
      case 'leader': {
        if (value === deputy) {
          setDeputy('');
        }
        setLeader(value);
        break;
      }
      case 'deputy': {
        if (value === leader) {
          setLeader('');
        }
        setDeputy(value);
        break;
      }
      case 'employee': {
        if (value === leader) {
          setLeader('');
        }
        if (value === deputy) {
          setDeputy('');
        }
        break;
      }
      case 'delete': {
        setUsers((prev) => prev.filter((user) => user.actor !== value));
        setUsersUUIDs((prev) => prev.filter((userUUID) => userUUID !== value));
        break;
      }
      default: break;
    }
  };

  return (
    <>
      <Button
        size="small"
        onClick={onOpenModalCreateDepartment}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Создать отдел
      </Button>
      {isCreateModalOpen && (
      <Modal
        open
        footer={null}
        title="Создать новый отдел"
        destroyOnClose
        onCancel={onCloseModalCreateDepartment}
        width={720}
      >
        <Flex
          vertical
          className="text-stone-500"
        >
          Название:
          <Input
            maxLength={100}
            showCount
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Flex>
        <Flex
          vertical
          className="mt-3 text-stone-500"
        >
          Сотрудникам:
          <TextArea
            maxLength={5000}
            row={8}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Flex>
        <Flex
          vertical
          className="mt-3"
        >
          <span
            className="text-stone-500"
          >
            Сотрудники:
          </span>
          <Flex
            justify="space-between"
          >
            <AssigningUsersForDepartments
              selected={usersUUIDs}
              onUserSelect={onUserSelect}
            />
            <DepartmentUsers
              leader={leader}
              deputy={deputy}
              uuids={usersUUIDs}
              users={users}
              onUserChange={onUserChange}
            />
          </Flex>
        </Flex>
        <Flex
          justify="end"
          className="mt-6"
        >
          <Button
            className={btnRed}
            onClick={onCloseModalCreateDepartment}
          >
            Отмена
          </Button>
          <Button
            className={`ml-2 ${btnGreen}`}
            onClick={onCreateDepartment}
          >
            Создать
          </Button>
        </Flex>
      </Modal>
      )}
    </>
  );
}

AddDepartmentButton.propTypes = {
  reloadDepartments: func,
};
