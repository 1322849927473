import { get } from 'lodash';

export const getUuid = (item) => get(item, 'uuid') || get(item, 'actor_uuid') || get(item, 'value');
export const firstName = (actor) => get(actor, 'uinfo.first_name', 'name');
export const lastName = (actor) => get(actor, 'uinfo.last_name', 'last_name');
export const getInitial = (string) => string?.charAt(0).toUpperCase();

export const actorIsAdmin = (actorData) => (actorData?.is_admin || false);

export const actorIsRoot = (actorData) => {
  const { is_root = false, root = false } = actorData || {};

  return (is_root || root);
};

export const actorIsRootOrAdmin = (actorData) => (actorIsAdmin(actorData)
|| actorIsRoot(actorData));
