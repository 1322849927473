import keyMirror from 'keymirror';

const VacationConstants = keyMirror({
  GET_VACATIONS_REQUEST: null,
  GET_VACATIONS_SUCCESS: null,
  GET_VACATIONS_FAILURE: null,

  GET_VACATION_REQUEST: null,
  GET_VACATION_SUCCESS: null,
  GET_VACATION_FAILURE: null,

  GET_REQ_VACATION_REQUEST: null,
  GET_REQ_VACATION_SUCCESS: null,
  GET_REQ_VACATION_FAILURE: null,

  CREATE_VACATION_REQUEST: null,
  CREATE_VACATION_SUCCESS: null,
  CREATE_VACATION_FAILURE: null,
});

export default VacationConstants;
