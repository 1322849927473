import React, { useState } from 'react';
import { array, func, string } from 'prop-types';
import {
  Button, Card, Flex, Table,
  Tooltip,
} from 'antd';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import _, { capitalize, get } from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';
import NewDepartmentComponent from './AddDepartmentButton';
import PageWrapper from '../../../components/PageWrapper';
import AddDepartmentButton from './AddDepartmentButton';

export default function DepartmentsList({
  department,
  departments = [],
  storageUUID,
  reloadDepartments,
  onOpenDepartment,
}) {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const activeDepartment = query.get('department');

  const [hideTableMode, setHideTableMode] = useState(false);

  const columns = [
    {
      key: 'id',
      dataIndex: ['uuid'],
      width: '5%',
      render: (cell, row, index) => '',
    },
    {
      key: 'title',
      title: <Flex className="ml-0">Название</Flex>,
      dataIndex: ['params', 'title'],
      width: '40%',
      render: (cell, row, index) => cell,
    },
    {
      key: 'leader',
      title: 'Руководитель',
      dataIndex: ['params', 'leader'],
      width: '10%',
      render: (cell, row, index) => {
        const user = get(row, ['params', 'users'], []).find((el) => el.actor === cell);

        return (
          <Flex className="w-full" justify="center">
            <Tooltip
              title={user?.label}
            >
              <Flex
                align="center"
                justify="center"
                style={{
                  border: '1px solid #C0C0C0',
                  width: 27,
                  height: 27,
                }}
                className="rounded-full text-gray-500"
              >
                {_.map(_.split(user?.label, ' '), (word) => _.upperFirst(_.head(word))).join('')}
              </Flex>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      key: 'deputy',
      title: 'Заместитель',
      dataIndex: ['params', 'deputy'],
      width: '10%',
      render: (cell, row, index) => {
        const user = get(row, ['params', 'users'], []).find((el) => el.actor === cell);

        return (
          <Flex className="w-full" justify="center">
            <Tooltip
              title={user?.label}
            >
              <Flex
                align="center"
                justify="center"
                style={{
                  border: '1px solid #C0C0C0',
                  width: 27,
                  height: 27,
                }}
                className="rounded-full text-gray-500"
              >
                {_.map(_.split(user?.label, ' '), (word) => _.upperFirst(_.head(word))).join('')}
              </Flex>
            </Tooltip>
          </Flex>
        );
      },
    },
    //  {
    //   key: 'users',
    //   title: 'Сотр.',
    //   dataIndex: ['params', 'users'],
    //   width: '5%',
    //   render: (cell, row, index) => <Flex className="w-full" justify="center">{cell.length}</Flex>,
    // },
    {
      key: 'created',
      title: 'Дата',
      dataIndex: ['created'],
      width: '15%',
      render: (cell, row, index) => dayjs(cell).format('YYYY-MM-DD'),
    }];

  const onRow = (record, rowIndex) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      query.delete('artifact');
      query.set('department', uuid);

      setHideTableMode(true);

      history.push({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeDepartment,
  });

  return (
    <Card
      className="w-full p-0 h-max blue-base-card"
      title={!hideTableMode ? (
        <Flex
          align="center"
        >
          <Flex
            className="text-xl"
          >
            Отделы
          </Flex>
          <AddDepartmentButton
            reloadDepartments={reloadDepartments}
            storageUUID={storageUUID}
          />
        </Flex>
      ) : (
        <Flex
          justify="space-between"
        >
          <span
            className="text-xl"
          >
            {get(department, ['params', 'title'], '')}
          </span>
          <Flex>
            <Button
              onClick={onOpenDepartment}
              size="small"
              className="!text-blue-500 !border-blue-500 hover:!bg-blue-50"
            >
              Открыть отдел
            </Button>
          </Flex>
        </Flex>
      )}
    >
      {(!hideTableMode || !activeDepartment) && (
      <Table
        size="small"
        className="w-full h-full mb-3"
        rowClassName={rowClassName}
        onRow={onRow}
        pagination={false}
        dataSource={departments}
        columns={columns}
      />
      )}
      {activeDepartment && (
      <Flex
        justify="center"
        className="w-full -mt-4 absolute"
      >
        {hideTableMode ? (
          <Button
            size="small"
            onClick={() => setHideTableMode(false)}
          >
            Показать отделы
          </Button>
        ) : (
          <Button
            size="small"
            onClick={() => setHideTableMode(true)}
          >
            Скрыть отделы
          </Button>
        )}
      </Flex>
      )}
    </Card>
  );
}

DepartmentsList.propTypes = {
  storageUUID: string,
  departments: array,
  reloadDepartments: func,
  onOpenDepartment: func,
};
