import React from 'react';

import { capitalize } from 'lodash';

import PageWrapper from '../../components/PageWrapper';
import UnderDevelopment from '../../components/UnderDevelopment';

function SettingsPage() {
  return (
    <PageWrapper title={capitalize('настройки')} fixed>
      <UnderDevelopment />
    </PageWrapper>
  );
}

export default SettingsPage;
