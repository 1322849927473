import _ from 'lodash';
import qs from 'query-string';

import { history } from '../routers/history';

class ParamsURL {
  constructor() {
    this.mainPage = 'admin/profile';
  }

  // is selector
  get(state) {
    return qs.parse(_.get(state, 'router.location.search', ''));
  }

  set(data, path) {
    history.replace({
      path: path || _.get(history, 'location.path', this.mainPage),
      search: data
        ? qs.stringify({
          ...qs.parse(history.location.search),
          ...data,
        })
        : '',
    });

    return qs.parse(history.location.search);
  }

  clear() {
    history.replace({
      path: _.get(history, 'location.path', this.mainPage),
      search: '',
    });
  }
}

const paramsURL = new ParamsURL();

export default paramsURL;
