import publicUsersConstants from './constants';

const initialState = {
  isFetching: false,
  actors: [],
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case publicUsersConstants.GET_PUBLIC_USERS_ENTITIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case publicUsersConstants.GET_PUBLIC_USERS_ENTITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        actors: payload,
      };

    case publicUsersConstants.GET_PUBLIC_USERS_ENTITIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
