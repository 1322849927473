import React, {
  useState,
} from 'react';
import dayjs from 'dayjs';
import {
  array, bool, func, number, object, string,
} from 'prop-types';
import {
  Col, Flex, Popover, Row, Table, Tag, Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiCommentOutline, mdiInformationOutline } from '@mdi/js';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import ExpandedMonthlyReport from './ExpandedMonthlyReport';
import { getBanGroupUUID } from '../../../redux/groups/selectors';

export default function UsersStatisticsCard({
  dataTable = [],
  currentMonth,
  onSearch,
}) {
  const banGroupUUID = useSelector(getBanGroupUUID);

  const [expandedRows, changeExpandedRows] = useState([]);

  const searchInTitle = (
    <Col className="d-flex align-item-end" span={24}>
      <AntDesignSearchBox54origins
        onSearch={onSearch}
        maxWidth={200}
      />
    </Col>
  );

  const columns = [
    {
      title: searchInTitle,
      dataIndex: ['actor', 'uinfo'],
      width: 200,
      sorter: (a, b) => `${a?.actor?.uinfo?.first_name} ${a?.actor?.uinfo?.last_name}`.localeCompare(`${b?.actor?.uinfo?.first_name} ${b?.actor?.uinfo?.last_name}`),
      render: (uinfo) => {
        const isBanned = uinfo?.groups?.includes?.(banGroupUUID);

        const fullName = `${uinfo?.first_name} ${uinfo?.last_name}`;
        const getName = () => {
          if (fullName.length > 22) {
            return (
              <Tooltip
                title={fullName}
              >
                {fullName.slice(0, 20)}
                ...
              </Tooltip>
            );
          }
          return fullName;
        };

        return (
          <Col>
            <Row style={{
              marginBottom: 2,
              width: 200,
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
            >
              {getName()}
            </Row>
            <Row className="d-flex justify-conten-center">
              <Tag
                color="orange"
                className="fontSize10"
                style={{
                  marginRight: 9,
                }}
              >
                {uinfo?.internal_user ? 'Internal' : 'External'}
              </Tag>
              {isBanned ? (
                <Tag
                  color="orange"
                  className="fontSize10 mr-1"
                >
                  BANNED
                </Tag>
              ) : null}
            </Row>
          </Col>
        );
      },
    },
    {
      title: 'Всего залоганных часов',
      dataIndex: ['report', 'allHours'],
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        return get(row, ['report', 'params', 'statistics', 'totalWorks'], 0)
        + get(row, ['report', 'params', 'statistics', 'totalBenches'], 0)
        + get(row, ['report', 'params', 'statistics', 'totalDayOff'], 0);
      },
    },
    {
      title: 'Проектная работа',
      dataIndex: ['report', 'params', 'statistics', 'totalWorks'],
      className: '!bg-green-200',
      sorter: (a, b) => {
        if (!a?.hasReport) {
          return -1;
        }
        if (!b?.hasReport) {
          return 1;
        }
        return a?.report?.params?.statistics?.totalWorks
         - b?.report?.params?.statistics?.totalWorks;
      },
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        return time;
      },
    },
    {
      title: 'Бенчи',
      dataIndex: ['report', 'params', 'statistics', 'totalBenches'],
      className: '!bg-orange-200',
      sorter: (a, b) => {
        if (!a?.hasReport) {
          return -1;
        }
        if (!b?.hasReport) {
          return 1;
        }
        return a?.report?.params?.statistics?.totalBenches
         - b?.report?.params?.statistics?.totalBenches;
      },
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        if (!time) {
          return 0;
        }
        return (
          <Tooltip
            title={(
              <>
                <p>
                  Бенч 1 месяц:
                  {' '}
                  {row?.report?.params?.statistics?.bench_1_month ?? 0}
                </p>
                <p>
                  Бенч больше месяца:
                  {' '}
                  {row?.report?.params?.statistics?.bench_over_1_month ?? 0}
                </p>
                <p>
                  Бенч больше 3-х месяцев:
                  {' '}
                  {row?.report?.params?.statistics?.bench_over_3_month ?? 0}
                </p>
              </>
            )}
            color="#1677ff"
          >
            <Row
              align="center"
            >
              {time ?? 0}
              <Icon style={{ marginLeft: 4 }} path={mdiInformationOutline} size={1} />
            </Row>
          </Tooltip>
        );
      },
    },
    {
      title: 'Нерабочие часы',
      dataIndex: ['report', 'params', 'statistics', 'totalDayOff'],
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        return time;
      },
      className: '!bg-red-200',
      sorter: (a, b) => {
        if (!a?.hasReport) {
          return -1;
        }
        if (!b?.hasReport) {
          return 1;
        }
        return (a?.report?.params?.statistics?.totalDayOff ?? 0)
         - (b?.report?.params?.statistics?.totalDayOff ?? 0);
      },
    },
    {
      title: 'Клиентский пр.',
      dataIndex: ['report', 'params', 'statistics', 'clientWork'],
      sorter: (a, b) => {
        if (!a?.hasReport) {
          return -1;
        }
        if (!b?.hasReport) {
          return 1;
        }
        return (a?.report?.params?.statistics?.clientWork ?? 0)
        - (b?.report?.params?.statistics?.clientWork ?? 0);
      },
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        return time ?? 0;
      },
    },
    {
      title: 'Внутренний пр.',
      dataIndex: ['report', 'params', 'statistics', 'internalWork'],
      sorter: (a, b) => {
        if (!a?.hasReport) {
          return -1;
        }
        if (!b?.hasReport) {
          return 1;
        }
        return (a?.report?.params?.statistics?.internalWork ?? 0)
        - (b?.report?.params?.statistics?.internalWork ?? 0);
      },
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        return time ?? 0;
      },
    },
    {
      title: 'Отгул',
      dataIndex: ['report', 'params', 'statistics', 'dayoff'],
      sorter: (a, b) => {
        if (!a?.hasReport) {
          return -1;
        }
        if (!b?.hasReport) {
          return 1;
        }
        return a?.report?.params?.statistics?.dayoff
        - b?.report?.params?.statistics?.dayoff;
      },
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        const dayoffDays = row?.report?.params?.days?.filter((el) => el?.type === 'dayoff');
        if (dayoffDays?.length) {
          const comment = dayoffDays
            .map((day) => `${dayjs(day?.rangeDates?.[0]).format('DD MMM')}:   ${day?.comment || 'Комментарий не указан!'}`);

          return (
            <Flex
              align="center"
            >
              {time}
              <Tooltip
                placement="left"
                color="white"
                autoAdjustOverflow={false}
                title={comment.map((el) => (
                  <p
                    style={{
                      textWrap: 'balance',
                    }}
                  >
                    {el}
                  </p>
                ))}
                overlayInnerStyle={{
                  color: 'black',
                  width: 'fit-content',
                  maxWidth: 700,
                }}
                style={{
                  marginLeft: 4,
                  cursor: 'pointer',
                  color: '#1677ff',
                }}
              >
                <Icon path={mdiInformationOutline} size={1} />
              </Tooltip>
            </Flex>
          );
        }
        return time;
      },
    },
    {
      title: 'Больничный',
      dataIndex: ['report', 'params', 'statistics', 'sickday'],
      sorter: (a, b) => {
        if (!a?.hasReport) {
          return -1;
        }
        if (!b?.hasReport) {
          return 1;
        }
        return a?.report?.params?.statistics?.sickday
        - b?.report?.params?.statistics?.sickday;
      },
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        return time;
      },
    },
    {
      title: 'Отпуск',
      dataIndex: ['report', 'params', 'statistics', 'vacation'],
      render: (time, row) => {
        if (!row?.hasReport) {
          return '-';
        }
        return time;
      },
      sorter: (a, b) => {
        if (!a?.hasReport) {
          return -1;
        }
        if (!b?.hasReport) {
          return 1;
        }
        return a?.report?.params?.statistics?.vacation - b?.report?.params?.statistics?.vacation;
      },
    },
    {
      title: 'Отправлен',
      dataIndex: ['report', 'params', 'statistics', 'created'],
      render: (date, row) => {
        if (!date || row?.report?.params?.status === 'not_sent' || !row?.report?.params?.status) {
          return (
            <Tag
              color="red"
              className="fontSize10 mr-1"
            >
              Не отправлен
            </Tag>
          );
        }
        if (row?.report?.params?.status === 'auto') {
          return (
            <Tag
              color="green"
              className="fontSize10 mr-1"
            >
              Автоматический отчет
            </Tag>
          );
        }
        return dayjs(date).format('DD MMM YY, HH:mm');
      },
      sorter: (a, b) => {
        const isAuto = (el) => el?.report?.params?.status === 'auto';
        const isSubmitted = (el) => el?.report?.params?.status === 'submitted';

        if (!a?.hasReport && b?.hasReport) return -1;
        if (a?.hasReport && !b?.hasReport) return 1;

        if (a?.hasReport && b?.hasReport) {
          if (isAuto(a) && isSubmitted(b)) return -1;
          if (isSubmitted(a) && isAuto(b)) return 1;
        }

        return 0;
      },
    },
  ];

  const expandableProps = {
    expandedRowRender: (row, index, indent, expanded) => {
      if (!expanded) {
        return null;
      }
      return (
        <ExpandedMonthlyReport
          report={row?.report}
          actor={row?.actor}
          month={currentMonth}
        />
      );
    },
    expandedRowKeys: expandedRows,
    rowExpandable: (record) => record?.hasReport,
    expandIcon: ({ expanded, ...props }) => (props?.record?.hasReport ? (
      <Col
        onClick={
        () => changeExpandedRows((prev) => (prev?.[0] === props?.record?.actor?.uuid ? [] : [props?.record?.actor?.uuid]))
        }
        style={{ cursor: 'pointer' }}
      >
        <Icon
          path={mdiChevronDown}
          size={1}
          rotate={expanded ? 180 : 0}
        />
      </Col>
    ) : null),
    expandRowByClick: true,
  };

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record?.actor || {};
      changeExpandedRows((prev) => (prev?.[0] === uuid ? [] : [uuid]));
    },
  });

  return (
    <Table
      rowKey={(row) => row?.actor?.uuid || row}
      columns={columns}
      dataSource={dataTable}
      onRow={onRow}
      size="small"
      expandable={expandableProps}
      pagination={false}
    />
  );
}

UsersStatisticsCard.propTypes = {
  dataTable: array,
  selectedTags: array,
  typeReport: string,
  currentMonth: string,
  setConfig: func,
  config: object,
  total: number,
  withPagination: bool,
  onSearch: func,
};
