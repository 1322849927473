import keyMirror from 'keymirror';

const UserDayOffConstants = keyMirror({
  GET_YEAR_DAY_OFF_REQUEST: null,
  GET_YEAR_DAY_OFF_SUCCESS: null,
  GET_YEAR_DAY_OFF_FAILURE: null,

  GET_USER_DAY_OFF_REQUEST: null,
  GET_USER_DAY_OFF_SUCCESS: null,
  GET_USER_DAY_OFF_FAILURE: null,

  CREATE_USER_DAY_OFF_REQUEST: null,
  CREATE_USER_DAY_OFF_SUCCESS: null,
  CREATE_USER_DAY_OFF_FAILURE: null,

  UPDATE_USER_DAY_OFF_REQUEST: null,
  UPDATE_USER_DAY_OFF_SUCCESS: null,
  UPDATE_USER_DAY_OFF_FAILURE: null,

  DELETE_USER_DAY_OFF_REQUEST: null,
  DELETE_USER_DAY_OFF_SUCCESS: null,
  DELETE_USER_DAY_OFF_FAILURE: null,
});

export default UserDayOffConstants;
