import React from 'react';
import {
  Space,
} from 'antd';
import { capitalize } from 'lodash';

import { element, string } from 'prop-types';
import PageWrapper from '../../components/PageWrapper';
import RequestVacationsCard from './vacationsRequests/RequestVacationsCard';

export default function VacationPage({ customTitle, actor }) {
  return (
    <PageWrapper
      title={customTitle || capitalize('Отпуска & каникулы')}
    >
      <Space direction="vertical" size="middle">
        <RequestVacationsCard customActor={actor} />
      </Space>
    </PageWrapper>
  );
}
VacationPage.propTypes = {
  customTitle: element,
  actor: string,
};
