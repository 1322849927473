import React from 'react';
import {
  Alert, Card,
  Col, Row, Space, Typography,
} from 'antd';
import { capitalize } from 'lodash';

import { element, string } from 'prop-types';
import DayOffPage from './dayOff/DayOffPage';
import PageWrapper from '../../components/PageWrapper';

const { Paragraph } = Typography;

export default function ReportsPage({ customTitle, actor }) {
  return (
    <PageWrapper
      title={customTitle || capitalize('Отчеты')}
    >
      <Space direction="vertical" size="middle">
        <Card
          title="Месячная статистика"
        >
          <Alert
            style={{ marginTop: 4, paddingInline: '16px', paddingBlock: '16px' }}
            message="Общие правила по отправке отчетов"
            type="info"
            showIcon
            description={(
              <div>
                <Paragraph style={{ marginBottom: 2 }}>
                  1. Отправка отчета допускается только за прошедшие месяцы. Досрочная отправка отчетов крайне нежелательна.
                </Paragraph>
                <Paragraph style={{ marginBottom: 2 }}>
                  2. Статистика вычисляется на основании отправленых с WMS record'ов и данных с "Месячная статистика".
                </Paragraph>
              </div>
                  )}
          />
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col span={24}>
              <DayOffPage customActor={actor} />
            </Col>
          </Row>
        </Card>
      </Space>
    </PageWrapper>
  );
}
ReportsPage.propTypes = {
  customTitle: element,
  actor: string,
};
