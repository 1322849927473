import React from 'react';
import { Route, Switch } from 'react-router-dom';

import useDepartmentsStorage from '../../../redux/departments/hooks/useDepartmentsStorage';
import useDepartments from '../../../redux/departments/hooks/useDepartments';

import DepartmentRoot from './DepartmentRoot';

import './department.scss';

export default function DepartmentPage() {
  const {
    storageUUID,
    storageFetching,
  } = useDepartmentsStorage();

  const {
    departments,
    reloadDepartments,
  } = useDepartments({
    storageUUID,
    storageFetching,
  });

  return (
    <Switch>
      <Route
        path="/departments"
        render={() => (
          <DepartmentRoot
            departments={departments}
            reloadDepartments={reloadDepartments}
            storageUUID={storageUUID}
          />
        )}
      />
    </Switch>
  );
}
