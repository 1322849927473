import GlobalUpdateConstants from './constants';

const initState = {
  pageKey: '',
  dataKey: '',
};

export default (state = initState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GlobalUpdateConstants.SET_RESET_PAGE:
      return {
        ...state,
        pageKey: payload,
      };
    case GlobalUpdateConstants.SET_GET_DATA:
      return {
        ...state,
        dataKey: payload,
      };

    default:
      return state;
  }
};
