import keyMirror from 'keymirror';

const MasqueradingConstants = keyMirror({
  GET_MASQUERADING_MONTHLY_STORAGE_REQUEST: null,
  GET_MASQUERADING_MONTHLY_STORAGE_SUCCESS: null,
  GET_MASQUERADING_MONTHLY_STORAGE_FAILURE: null,

  GET_MASQUERADING_VACATION_STORAGE_REQUEST: null,
  GET_MASQUERADING_VACATION_STORAGE_SUCCESS: null,
  GET_MASQUERADING_VACATION_STORAGE_FAILURE: null,
});

export default MasqueradingConstants;
