import dayjs from 'dayjs';

export const formatDate = (date) => dayjs(date).format('DD-MM-YYYY');
export const isDateAfterToday = (dateTimestamp) => {
  const today = new Date();
  const date = new Date(dateTimestamp);
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  return today < date;
};

export const isDateBeforeToday = (dateTimestamp) => {
  const today = new Date();
  const date = new Date(dateTimestamp);
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  return today > date;
};

export const daysRemaining = (startDate, endDate) => {
  const beginDate = dayjs(startDate);
  const finishDate = dayjs(endDate);

  return finishDate.diff(beginDate, 'days');
};

export const diffDays = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  return end.diff(start, 'days') + 1;
};

export const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000;

export function getBusinessDays(endDate, startDate) {
  const lastDay = dayjs(endDate);
  const firstDay = dayjs(startDate);

  let calcBusinessDays = 1 + (lastDay.diff(firstDay, 'days') * 5
      - (firstDay.day() - lastDay.day()) * 2) / 7;

  if (lastDay.day() == 6) {
    calcBusinessDays--;
  }
  if (firstDay.day() == 0) {
    calcBusinessDays--;
  }

  return calcBusinessDays;
}

export function getLastWorkingDayOfMonth(month) {
  let lastDayOfMonth = dayjs().month(month).endOf('month');

  while (lastDayOfMonth.day() === 0 || lastDayOfMonth.day() === 6) {
    lastDayOfMonth = lastDayOfMonth.subtract(1, 'day');
  }

  return lastDayOfMonth.format('YYYY-MM-DD');
}

export function isBetweenDates(date1, date2, targetDate) {
  const startDate = dayjs(date1);
  const endDate = dayjs(date2);
  const targetDayjs = dayjs(targetDate);

  return targetDayjs.isBetween(startDate, endDate, null, '[]');
}
