import dayjs from 'dayjs';

export const hideColumnsVacations = {
  type: true,
};

export const defaultDates = {
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().add(40, 'day').format('YYYY-MM-DD'),
};
