const LABEL_AND_STATUSES_FOR_VACATION = {
  created: 'Черновик',
  pending: 'На рассмотрении',
  approved: 'Утвержден',
  rejected: 'Отклонен',
  'pre-agreed': 'Предварительно согласован',
};

export const VACATION_STATUSES = [{
  value: 'created',
  label: 'Черновик',
}, {
  value: 'pending',
  label: 'На рассмотрении',
}, {
  value: 'approved',
  label: 'Утвержден',
}, {
  value: 'rejected',
  label: 'Отклонен',
}, {
  value: 'pre-agreed',
  label: 'Предварительно согласован',
}];

export const getLabelByStatus = (status) => LABEL_AND_STATUSES_FOR_VACATION?.[status] ?? '';

export const VACATION_TYPES = ['отпуск', 'каникулы'];

export const VACATION_ENTITY_TYPES = {
  отпуск: 'vacation',
  каникулы: 'holidays',
  // больничный: 'sickday',
};

export const hideColumnsVacationRequest = {
  title: true,
  type: true,
};

export const defaultRequestOptions = {
  limit: 10,
  offset: 0,
  order: 'desc',
  order_by: 'created',
};

export const requestOptionsByModified = {
  limit: 10,
  offset: 0,
  order: 'desc',
  order_by: 'modified',
};

export const maxCountOfVacationDays = 18;
export const minCountOfDaysBeforeVacation = 18;
