import React, { useMemo } from 'react';

import { theme } from 'antd';

import { Footer } from 'antd/es/layout/layout';
import { getGitVersion } from '../../54origins/utils54origins';
import { capitalizeFirstChar } from '../../utils';
import { APP_LABEL } from '../../api/app_config';

function AppFooter() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const style = useMemo(() => ({
    textAlign: 'center',
    background: colorBgContainer,
  }), [colorBgContainer]);

  return (
    <Footer
      style={style}
    >
      <span>
        Copyright
        {' '}
        {capitalizeFirstChar(APP_LABEL)}
      </span>
      <span className="px-1">&#169;</span>
      <span>{new Date().getFullYear()}</span>

      <span className="ml-3">{process.env.NODE_ENV === 'production' && `version: ${getGitVersion()}`}</span>
    </Footer>
  );
}

export default AppFooter;
