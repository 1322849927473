import { Col, Row, Tooltip } from 'antd';
import { get } from 'lodash';
import React, {
  useState,
} from 'react';
import PropTypes, { bool, func, object } from 'prop-types';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import cn from 'classnames';
import InputTimeWork from './InputTimeWork';
import {
  arrTimeFromStartAndFinishModal, arrTimeLunchBreak, isValidKeys, minutesInDay,
  convertArrayOfHHhmmmToSum,
  convertHHhmmmToMinutes,
} from './utils';
import { getMinutesFromStr } from '../../../pages/user/dayOff/utils';

function BaseReportWorkTimeManagmentForm({
  disabled,
  formState,
  setFormState,
  showLunchBreak,
}) {
  const {
    startWork: startWorkLocal,
    // clientWork: clientWorkLocal,
    // internalWork: internalWorkLocal,
    finishWork: finishWorkLocal,
    hoursWorked: hoursWorkedLocal,
    lunchBreak: lunchBreakLocal,
  } = get(formState, 'timeState');

  const [isValidTime, setIsValidTime] = useState({
    isValidTotalHoursWorked: true,
    isValidLunchBreakTime: true,
    isValidStartWorkTime: true,
    isValidFinishWorkTime: true,
    // isValidClientWorkTime: true,
    // isValidInternalWorkTime: true,
  });

  const moreThan24 = (finishWork) => convertHHhmmmToMinutes(finishWork) >= minutesInDay;

  const lessThanLunchBreak = (lunchBreak, workedTime = null) => {
    const timeLunchBreak = lunchBreak || '00h00m';
    const timeHoursWorked = workedTime || hoursWorkedLocal;

    return (
      convertHHhmmmToMinutes(timeHoursWorked) < convertHHhmmmToMinutes(timeLunchBreak));
  };

  const saveData = (key, newFinishWork, newData) => {
    const { newTime, isValid } = newData;

    const isValidNewData = {
      ...isValidTime,
      [isValidKeys[key]]: isValid,
    };

    setIsValidTime(isValidNewData);

    const isDisabledSubmit = Object.values(isValidNewData).includes(false)
    || moreThan24(newFinishWork)
    || lessThanLunchBreak(
      key === 'lunchBreak' ? newTime : lunchBreakLocal,
      key === 'hoursWorked' ? newTime : hoursWorkedLocal,
    );

    setFormState((prev) => ({
      ...prev,
      wasChanges: true,
      disabledSubmit: isDisabledSubmit,
      timeState: {
        ...prev.timeState,
        finishWork: newFinishWork,
        [key]: newTime,
      },
    }));
  };

  const onChangeTotalHoursWorked = (newData) => {
    const newFinishWork = convertArrayOfHHhmmmToSum([
      startWorkLocal,
      lunchBreakLocal,
      newData?.newTime,
    ]);

    saveData('hoursWorked', newFinishWork, newData);
  };

  // const onChangeClientHoursWorked = (newData) => {
  //   const newFinishWork = convertArrayOfHHhmmmToSum([
  //     startWorkLocal,
  //     lunchBreakLocal,
  //     internalWorkLocal,
  //     newData?.newTime,
  //   ]);

  //   saveData('hoursWorked', newFinishWork, {
  //     newTime: convertArrayOfHHhmmmToSum([
  //       internalWorkLocal,
  //       newData?.newTime,
  //     ]),
  //     isValid: true,
  //   });
  //   saveData('clientWork', newFinishWork, newData);
  // };

  // const onChangeInternalHoursWorked = (newData) => {
  //   const newFinishWork = convertArrayOfHHhmmmToSum([
  //     startWorkLocal,
  //     lunchBreakLocal,
  //     clientWorkLocal,
  //     newData?.newTime,
  //   ]);

  //   saveData('hoursWorked', newFinishWork, {
  //     newTime: convertArrayOfHHhmmmToSum([
  //       clientWorkLocal,
  //       newData?.newTime,
  //     ]),
  //     isValid: true,
  //   });
  //   saveData('internalWork', newFinishWork, newData);
  // };

  const onChangeLunchBreakTime = (newData) => {
    const newFinishWork = convertArrayOfHHhmmmToSum([
      startWorkLocal,
      hoursWorkedLocal,
      newData?.newTime,
    ]);

    saveData('lunchBreak', newFinishWork, newData);
  };

  const onChangeStartWorkTime = (newData) => {
    const newFinishWork = convertArrayOfHHhmmmToSum([
      lunchBreakLocal,
      hoursWorkedLocal,
      newData?.newTime,
    ]);

    saveData('startWork', newFinishWork, newData);
  };

  const listOfTimeInputs = [
    {
      title: 'Рабочее время:',
      isTimeValid: isValidTime.isValidTotalHoursWorked,
      defaultValue: hoursWorkedLocal,
      // disabled: true,
      // defaultValue: convertArrayOfHHhmmmToSum([
      //   internalWorkLocal,
      //   clientWorkLocal,
      // ]),
      additionalTimeControls: arrTimeFromStartAndFinishModal,
      customClassName: '',
      onChange: onChangeTotalHoursWorked,
      showLessThanLunchBreak: lessThanLunchBreak(lunchBreakLocal),
    },
    // {
    //   title: 'Клиентский проект:',
    //   isTimeValid: isValidTime.isValidClientWorkTime,
    //   defaultValue: clientWorkLocal,
    //   additionalTimeControls: arrTimeFromStartAndFinishModal,
    //   customClassName: '',
    //   onChange: onChangeClientHoursWorked,
    // },
    // {
    //   title: 'Внутренний проект:',
    //   isTimeValid: isValidTime.isValidInternalWorkTime,
    //   defaultValue: internalWorkLocal,
    //   additionalTimeControls: arrTimeFromStartAndFinishModal,
    //   customClassName: '',
    //   onChange: onChangeInternalHoursWorked,
    // },
    {
      title: 'Начало работы:',
      isTimeValid: isValidTime.isValidStartWorkTime,
      defaultValue: startWorkLocal,
      additionalTimeControls: arrTimeFromStartAndFinishModal,
      extraClassName: 'time-orange-color',
      customClassName: '',
      onChange: onChangeStartWorkTime,
    },
    {
      title: 'Обеденный перерыв:',
      hide: !showLunchBreak,
      isTimeValid: isValidTime.isValidLunchBreakTime,
      defaultValue: lunchBreakLocal,
      extraClassName: 'time-green-color',
      additionalTimeControls: arrTimeLunchBreak,
      onChange: onChangeLunchBreakTime,
    },
    {
      title: 'Конец работы:',
      isTimeValid: isValidTime.isValidFinishWorkTime,
      disabled: true,
      defaultValue: finishWorkLocal,
      additionalTimeControls: [],
      extraClassName: 'time-orange-color',
      customClassName: '',
      showMoreThan24: moreThan24(finishWorkLocal),
    },
  ].filter((elem) => !elem.hide);

  return (
    <Col span={24} className="w-100 flex-column">
      {listOfTimeInputs.map((elem) => (
        <Row
          className={elem.customClassName}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Col span={9}>
            <span className="text-secondary">
              {elem.title}
            </span>
          </Col>
          <Col span={15}>
            <InputTimeWork
              enableAddBtn={elem?.enableAddBtn}
              useCircleTime
              disableControlls
              isValidFromOutside={elem.isTimeValid}
              className={cn({
                [`${elem.extraClassName || ''}`]: !elem.showMoreThan24 && !elem?.showLessThanLunchBreak,
                redText: elem?.showMoreThan24 || elem?.showLessThanLunchBreak,
                blueText: !disabled && !elem.disabled,
              })}
              addTimeClass={!disabled ? 'startWorkAddBtns' : 'startWorkAddBtnsDisabled'}
              bordered={false}
              small
              disableAddTimeBtnClick
              disableEdit={disabled || elem.disabled}
              saveNewTimeCallback={elem.onChange}
              defaultValueInput={elem.defaultValue}
              arrTime={elem.additionalTimeControls}
            />
            {elem?.showMoreThan24
              && (
              <Tooltip title="Рабочий день не должен превышать 24 часов">
                <Icon color="red" className="cursorPointer" path={mdiHelpCircleOutline} size={0.9} />
              </Tooltip>
              )}
            {elem?.showLessThanLunchBreak
              && (
              <Tooltip title="Рабочее время не может быть меньше обеденного перерыва">
                <Icon color="red" className="cursorPointer" path={mdiHelpCircleOutline} size={0.9} />
              </Tooltip>
              )}
          </Col>
        </Row>
      ))}
    </Col>
  );
}

BaseReportWorkTimeManagmentForm.propTypes = {
  disabled: bool,
  showLunchBreak: bool,
  formState: object,
  setFormState: func,
};

export default BaseReportWorkTimeManagmentForm;
