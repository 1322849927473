import React from 'react';

import {
  Avatar, Card, Col, Form, Row, Space, Spin, Typography,
} from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import getLocale from '../../../redux/locale/selectors';
import { getProfileInfo } from '../../../redux/profile/selectors';

import PageWrapper from '../../../components/PageWrapper';
import ProfileForm from './forms/ProfileForm';
import { capitalizeFirstChar } from '../../../utils';

const { Title, Text } = Typography;
function ProfilePage() {
  const { t } = useTranslation();
  const locale = useSelector(getLocale);

  const [profileForm] = Form.useForm();

  const profileInfo = useSelector(getProfileInfo);

  const getData = (key, defaultValue = '') => _.get(profileInfo, key, defaultValue);

  const profileData = {
    uuid: getData('uuid'),
    first_name: getData('uinfo.first_name'),
    last_name: getData('uinfo.last_name'),
    email: getData('uinfo.email'),
    created: dayjs(getData('created'), 'YYYY-MM-DD HH:mm:ss')
      .locale(locale)
      .format('LLL'),
    full_name: `${getData('uinfo.first_name')} ${getData(
      'uinfo.last_name',
    )}`,
  };

  //   const getDescriptionText = (text) => (text ? (
  //     <span className="text-primary">{text}</span>
  //   ) : (
  //     <span className="text-secondary">
  //       {capitalizeFirstChar(t('auth.placeholders.no_description', 'no description'))}
  //     </span>
  //   ));

  return (
    <PageWrapper
      title="Профиль"
      fixed
    >
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Spin spinning={false}>
            <Card style={{ marginBottom: 16 }}>
              <Space size="large">
                <Avatar shape="square" size="large" />
                <div>
                  <Title
                    level={5}
                    style={{
										  margin: 0,
                    }}
                  >
                    {capitalizeFirstChar(
										  profileData.first_name,
                    )}
                    {' '}
                    {capitalizeFirstChar(
										  profileData.last_name,
                    )}
                  </Title>
                  <div>
                    <Text type="secondary">
                      {profileData.uuid}
                    </Text>
                  </div>
                  <div>
                    <Text type="secondary">
                      {profileData.email}
                    </Text>
                  </div>
                </div>
              </Space>
            </Card>

            <Card
              title={capitalizeFirstChar(
							  t('app.headers.user_info', 'user info'),
              )}
            >
              <ProfileForm form={profileForm} />

              {/* <Descriptions bordered column={1}> */}
              {/*    <Descriptions.Item */}
              {/*        label={capitalizeFirstChar( */}
              {/*            t( */}
              {/*                'app.headers.first_name', */}
              {/*                'first name' */}
              {/*            ) */}
              {/*        )} */}
              {/*        labelStyle={{ width: '33%' }} */}
              {/*    > */}
              {/*        {getDescriptionText(profileData.first_name)} */}
              {/*    </Descriptions.Item> */}
              {/*    <Descriptions.Item */}
              {/*        label={capitalizeFirstChar( */}
              {/*            t('app.headers.last_name', 'last name') */}
              {/*        )} */}
              {/*        labelStyle={{ width: '33%' }} */}
              {/*    > */}
              {/*        {getDescriptionText(profileData.last_name)} */}
              {/*    </Descriptions.Item> */}
              {/*    <Descriptions.Item */}
              {/*        label={capitalizeFirstChar( */}
              {/*            t('app.headers.email', 'e-mail') */}
              {/*        )} */}
              {/*        labelStyle={{ width: '33%' }} */}
              {/*    > */}
              {/*        {getDescriptionText(profileData.email)} */}
              {/*    </Descriptions.Item> */}
              {/*    <Descriptions.Item */}
              {/*        label={capitalizeFirstChar( */}
              {/*            t( */}
              {/*                'app.headers.account_created', */}
              {/*                'account created' */}
              {/*            ) */}
              {/*        )} */}
              {/*        labelStyle={{ width: '33%' }} */}
              {/*    > */}
              {/*        {getDescriptionText(profileData.created)} */}
              {/*    </Descriptions.Item> */}
              {/* </Descriptions> */}
            </Card>
          </Spin>
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default ProfilePage;
