import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import StartWorkRoot from './StartWorkRoot';

import {
  getMonthlyStorageInfo,
  getMonthlyStorageUUID,
  getUserRootFetching,
  getUserRootInfo,
} from '../../../redux/storages/selectors';
import { getProfileInfo } from '../../../redux/profile/selectors';
import MonthlyReportConstants from '../../../redux/monthlyReport/constants';
import useAPI from '../../../api/useAPI';
import { dateFormat2, dateFormat23 } from '../../../54origins/dateFormats54origins';

function CheckStartWorkRecordFocus() {
  const isFetchingUser = useSelector(getUserRootFetching);
  const deliveryProfile = useSelector(getUserRootInfo);
  const myProfileData = useSelector(getProfileInfo);

  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const monthlyReportInfo = useSelector(getMonthlyStorageInfo);

  const {
    actor,
    params: {
      showBaseReport = false,
    } = {},
  } = deliveryProfile || {};

  const actorUuid = myProfileData?.uuid;
  const month = dateFormat23();

  const { getOrCreateMonthlyReport } = useAPI();

  const initFunc = async () => {
    const configRequest = {
      actor: actorUuid,
      params: {
        actorUuid,
        date: dayjs().format('YYYY-MM'),
      },
    };

    const constants = [
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_REQUEST,
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_SUCCESS,
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_FAILURE,
    ];

    await getOrCreateMonthlyReport(monthlyStorageUUID, configRequest, constants);
  };

  const getDailyAndSetFirstEnterFromFocus = () => {
    // console.log('FOCUS getDailyAndSetFirstEnterFromFocus');
    const dateOfShowingStartWorkModal = localStorage.getItem('dateOfShowingStartWorkModal') || '';

    if (dateFormat2() !== dateOfShowingStartWorkModal) {
      initFunc();
    }
  };

  useEffect(() => {
    if (actorUuid && actorUuid === monthlyReportInfo?.actor && monthlyStorageUUID && month) {
      initFunc();

      window.addEventListener('focus', getDailyAndSetFirstEnterFromFocus);
    }
    return () => window.removeEventListener('focus', getDailyAndSetFirstEnterFromFocus);
  }, [actorUuid, monthlyStorageUUID, month]);

  return (
    !isFetchingUser && showBaseReport && actorUuid === actor ? <StartWorkRoot /> : null
  );
}

export default CheckStartWorkRecordFocus;
