import React, { useEffect, useState } from 'react';

import {
  Alert, Button, Form, Input, Segmented, Select, Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import antNotification from '../../../../utils/antNotification';
import { seniorityList, technologies } from '../helpers/constants';

import useAPI from '../../../../api/useAPI';

import {
  getHumanResources,
  getOwnHumanResource,
} from '../../../../redux/humanResource/selectors';
import { getProfileInfo } from '../../../../redux/profile/selectors';

const { Text, Title } = Typography;

const { TextArea } = Input;

const initProfileValues = {
  specialization_1: '',
  specialization_2: '',
  expertise: [],
  seniority: '',
  education: '',
  english: '',
  birthdate: '',
  preferred_working_hours: '',
  is_init: true,
};

export default function ProfileForm({ form }) {

  const history = useHistory();

  const { uuid: currentActorUUID } = useSelector(getProfileInfo);
  const { isFetching: isFetchingOwnHumanResource } =		useSelector(getHumanResources);
  const ownHumanResourceData = useSelector(getOwnHumanResource);

  const isEditMode =		!isFetchingOwnHumanResource
		&& Boolean(ownHumanResourceData.uuid);
  const [profileValues, setProfileValues] = useState(initProfileValues);

  const [hasAdditionalSpec, setAdditionalSpec] = useState(false);

  const onFinish = (values) => {
    // if (isEditMode) {
    //   requestUpdateHumanResource(ownHumanResourceData.uuid, values).then(
    //     () => {
    //       requestGetHumanResource({
    //         actor: currentActorUUID,
    //       }).then(() => antNotification.success(
    //         'Профиль успешно обновлен.',
    //       ));
    //     },
    //   );
    // } else {
    //   requestCreateHumanResource(values).then(() => requestGetHumanResource({
    //     actor: currentActorUUID,
    //   }));
    // }
  };

  const options = [{ label: 'ReactJS', value: 'reactjs' }];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFill = () => {
    form.setFieldsValue({});
  };

  useEffect(() => {
    if (isEditMode) {
      form.setFieldsValue(ownHumanResourceData.params);
    }
  }, [isEditMode]);

  return (
    <Form
      preserve={false}
      name="basic"
      form={form}
      labelCol={{
			  span: 6,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={profileValues}
      disabled={Boolean(isFetchingOwnHumanResource)}
    >
      <Form.Item
        wrapperCol={{
				  offset: 6,
        }}
      >
        <Alert
          message="Do you have an additional specialization?"
          action={(
            <Button
              size="small"
              type="primary"
              ghost
              onClick={() => setAdditionalSpec(!hasAdditionalSpec)}
            >
              {!hasAdditionalSpec ? 'Yes, add' : 'Cancel'}
            </Button>
    )}
          type="info"
          showIcon
        />
      </Form.Item>

      <Form.Item
        label={<Text>Specialization</Text>}
        name="specialization_1"
        rules={[
				  {
				    required: true,
				    message: 'Please select specialization',
				  },
        ]}
      >
        <Segmented
          disabled={isFetchingOwnHumanResource}
          options={technologies}
          value={profileValues.specialization_1}
          onChange={(value) => form.setFieldsValue('specialization_1', value)}
        />
      </Form.Item>

      {hasAdditionalSpec && (
      <Form.Item
        label={<Text>Specialization 2</Text>}
        name="specialization_2"
      >
        <Segmented
          disabled={Boolean(isFetchingOwnHumanResource)}
          options={technologies}
          value={profileValues.specialization_2}
          onChange={(value) => form.setFieldsValue('specialization_2', value)}
        />
      </Form.Item>
      )}

      <Form.Item
        label={<Text>Seniority</Text>}
        name="seniority"
        rules={[
				  {
				    required: true,
				    message: 'Please select your seniority',
				  },
        ]}
      >
        <Segmented options={seniorityList} />
      </Form.Item>

      <Form.Item
        label={<Text>Expertise</Text>}
        name="expertise"
        rules={[
				  {
				    required: true,
				    message: 'Please select skills',
				  },
        ]}
        wrapperCol={{
				  span: 14,
        }}
      >
        <Select
          mode="multiple"
          allowClear
          style={{
					  width: '100%',
          }}
          placeholder="Please select skills"
          options={options}
        />
      </Form.Item>

      <Form.Item
        label={<Text>Education</Text>}
        name="education"
        wrapperCol={{
				  span: 14,
        }}
      >
        <TextArea
          showCount
          maxLength={100}
          placeholder="Enter name of educational institution, profile specialty, start and end of studies"
        />
      </Form.Item>

      <Form.Item
        label={<Text>English level</Text>}
        name="english"
        wrapperCol={{
				  span: 8,
        }}
      >
        <Input placeholder="Enter english level" />
      </Form.Item>

      <Form.Item
        label={<Text>Birthdate</Text>}
        name="birthdate"
        wrapperCol={{
				  span: 8,
        }}
      >
        <Input placeholder="Enter birthdate" />
      </Form.Item>

      <Form.Item
        label={<Text>Preferred working hours</Text>}
        name="preferred_working_hours"
        wrapperCol={{
				  span: 8,
        }}
      >
        <Input placeholder="Enter preferred working hours" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
				  offset: 6,
				  span: 16,
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={Boolean(isFetchingOwnHumanResource)}
        >
          {isEditMode ? 'Save' : 'Submit'}
        </Button>
      </Form.Item>
    </Form>
  );
}
