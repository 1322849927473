import { Col } from 'antd';
import React, { memo } from 'react';
import { func, string } from 'prop-types';

import DaySwitch from './DaySwitch';

function DayUserReportDaySwitcher({ currentDay, setCurrentDay }) {
  return (
    <Col span={24} style={{ marginBottom: 10 }}>
      <DaySwitch
        defaultDay={currentDay}
        disableModal
        customClassName="wrapper-switcher-period"
        changeCurrentDayCallback={setCurrentDay}
      />
    </Col>
  );
}

export default memo(DayUserReportDaySwitcher);

DayUserReportDaySwitcher.propTypes = {
  currentDay: string,
  setCurrentDay: func,
};
