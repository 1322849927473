import React from 'react';

import { capitalize } from 'lodash';

import PageWrapper from '../../components/PageWrapper';
import UnderDevelopment from '../../components/UnderDevelopment';

function HelpPage() {
  return (
    <PageWrapper title={capitalize('help')} fixed>
      <UnderDevelopment />
    </PageWrapper>
  );
}

export default HelpPage;
