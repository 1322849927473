import _ from 'lodash';

export const getFetchingHumanResources = (state) => _.get(state, 'humanResources.isFetching', false);

export const getHumanResourcesLoaded = (state) => _.get(state, 'humanResources.isLoaded', false);

export const getHumanResources = (state) => _.get(state, 'humanResources.list', false);
export const getOwnHumanResource = (state) => _.get(state, 'humanResources.current', { uuid: '' });

export const getOwnHumanResourceUUID = (state) => _.get(state, 'humanResources.current.uuid', '');
